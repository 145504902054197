import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Cell } from 'styled-css-grid';
import SectionHeader from 'SectionHeader';
import Dropdown from 'Dropdown';
import Footer from 'Footer';
import style from './Delivery.module.scss';
import classnames from 'classnames';
import _ from 'lodash';
import history from '../History';
import { BASE_PATH } from '../Config';
import { useMediaQuery } from 'react-responsive';
import StorePickup from '../images/Tile/Core/BagIcon.svg';
import ThemeBoxImg from '../images/Tile/Core/ThemeBox.svg';
import { fetchStyleProfile, updateResponse, setAsPickup } from '../store/Actions/QuestionAction';
import { updateCustomerData, fetchStoreLocations, fetchHolidays } from '../store/Actions/CustomerAction';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { isDayBlockedForPickup, isDayBlockedForShipment } from './../utils/dayPicker';
import * as tracking from './../utils/gt-tracking';

const choices = [
  {
    id: 'subscription_30_day',
    label: 'Can’t get enough! (Every month)',
    value: 'subscription_30_day',
  },
  {
    id: 'subscription_60_day',
    label: 'Keep ‘em comin (Every other month)',
    value: 'subscription_60_day',
  },
  {
    id: 'subscription_90_day',
    label: 'Seasonal hauls (Quarterly)',
    value: 'subscription_90_day',
  },
  {
    id: 'subscription_on_demand',
    label: 'My people will call your people (On demand)',
    value: 'subscription_on_demand',
  },
];

function DeliveryMethod() {
  const dispatch = useDispatch();
  const [chosenFlow, setFlow] = useState('shipped');
  const [selectedSubscription, setSelectedSubscription] = useState('subscription_30_day');
  const [storeLocation, setStoreLocation] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [selectedDate, setSelectedDate] = useState(null);
  const [showShoeOptions, setShowShoeOptions] = useState(null);

  const holidays = useSelector((state) => _.get(state, 'holidays', {}));

  useEffect(() => {
    dispatch(fetchStyleProfile());
    dispatch(fetchStoreLocations());
    dispatch(fetchHolidays());

    if (chosenFlow === 'pickup') {
      setButtonDisabled(!isPickupValid());
    } else {
      setButtonDisabled(!isShippingValid());
    }
  }, [dispatch, selectedDate, chosenFlow, storeLocation, selectedSubscription]);

  const storeLocations = useSelector((state) =>
    _.chain(state.questions.data.fields)
      .filter((f) => f.properties && f.properties.fields)
      .map((f) => f.properties.fields)
      .flatten()
      .filter((f) => f.ref === 'match_with_stylist_2')
      .map((f) => f.properties.choices)
      .flatten()
      .filter((choice) =>
        choice.label !== 'No Preference' &&
        choice.label !== 'IL, Evanston - Sherman Plaza' &&
        choice.label !== 'MN, Eden Prairie - Windsor Plaza' &&
        choice.label !== 'MN, St. Louis Park - The Shops at West End' &&
        choice.label !== 'MN, Woodbury - Woodbury Lakes' &&
        choice.label !== 'OH, Mason - Deerfield Towne Center' &&
        choice.label !== 'MO, Kansas City - Zona Rosa' &&
        choice.label !== 'OH, Beavercreek - The Greene' &&
        choice.label !== 'OH, Columbus - Easton Town Center' &&
        choice.label !== 'MI, Ann Arbor - Arbor Hills' &&
        choice.label !== 'CO, Lakewood - Belmar' &&
        choice.label !== 'MO, Chesterfield - Chesterfield Mall' &&
        choice.label !== 'CT, South Windsor - The Promenade Shops at Evergreen Walk' &&
        choice.label !== 'IL, Chicago - Lincoln Park' &&
        choice.label !== 'WI, Wauwatosa - Mayfair Mall' &&
        choice.label !== 'KS, Overland Park - Oak Park Mall' &&
        choice.label !== 'WA, Lynnwood - Alderwood Mall' &&
        choice.label !== 'OH, Beachwood - Beachwood Place' &&
        choice.label !== 'MI, Novi - Twelve Oaks' &&
        choice.label !== 'AR, Little Rock - Chenal' &&
        choice.label !== 'TX, Plano - Willow Bend Center' &&
        choice.label !== 'IL, Northbrook - Northbrook Court' &&
        choice.label !== 'UT, Salt Lake City - City Creek Center' &&
        choice.label !== 'MN, Bloomington - Mall of America' &&
        choice.label !== 'MI, Lansing - Eastwood Towne Center' &&
        choice.label !== 'NJ, Bridgewater - Bridgewater Commons' &&
        choice.label !== 'CT, Norwalk - The SoNo Collection' &&
        choice.label !== 'AZ, Scottsdale - Kierland Commons' &&
        choice.label !== 'NJ, East Rutherford - American Dream' &&
        choice.label !== 'TX, Fort Worth - Shops at Clearfork' &&
        choice.label !== 'WI, Brookfield - The Corners of Brookfield'
      )
      .value()
  );

  const isPickupValid = () => {
    const valid = Boolean(storeLocation && storeLocation !== '' && selectedDate);
    return valid;
  };

  const isShippingValid = () => {
    const valid = Boolean(selectedDate && selectedSubscription);
    return valid;
  };

  const pickFlow = (flow) => {
    setFlow(flow);
    if (flow === 'pickup') {
      setSelectedSubscription('subscription_on_demand');
      dispatch(updateResponse('subscription_type', 'On Demand'));
      setStoreLocation('');
      setButtonDisabled(true);
    } else {
      setSelectedSubscription('subscription_30_day');
      dispatch(updateResponse('subscription_type', 'Every Month'));
      setButtonDisabled(!isShippingValid());
    }
  };

  const handleStep = () => {
    if (chosenFlow === 'pickup') {
      dispatch(updateResponse('subscription_type', 'On Demand'));
      if (showShoeOptions === true) {
        dispatch(setAsPickup(`${BASE_PATH}/quiz/14`));
      } else {
        dispatch(setAsPickup(`${BASE_PATH}/payment/billing`));
        tracking.push({
          eventCategory: 'onboarding',
          eventAction: 'provided fit information',
        });
      }
    } else {
      history.push(`${BASE_PATH}/quiz/confirmation`);
      tracking.push({
        eventCategory: 'onboarding',
        eventAction: 'provided fit information',
      });
    }
  };

  const handleStoreChange = (value, ref) => {
    setStoreLocation(value);
    dispatch(updateResponse(ref, value));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(updateCustomerData({ signup_quiz_estimated_customer_delivery_date: date.toDate() }));
    dispatch(updateResponse('ship_by_date', date.toDate()));
  };

  const handleSubscriptionSelect = (subscription) => {
    let subscriptionValue = subscription;
    switch (subscription) {
      case 'subscription_30_day':
        subscriptionValue = 'Every Month';
        break;
      case 'subscription_60_day':
        subscriptionValue = 'Every Other Month';
        break;
      case 'subscription_90_day':
        subscriptionValue = 'Every Quarter';
        break;
      case 'subscription_on_demand':
        subscriptionValue = 'On Demand';
        break;
      default:
        subscriptionValue = subscription;
    }
    if (subscription === 'subscription_on_demand') {
      subscriptionValue = 'On Demand';
    }
    setSelectedSubscription(subscription);
    dispatch(updateResponse('subscription_type', subscriptionValue));
  };

  const handleShoeOptionsSelect = (value) => {
    setShowShoeOptions(value);
    dispatch(updateResponse('show_shoe_options', value));
    if (chosenFlow === 'pickup') {
      setButtonDisabled(!(storeLocation && selectedDate && selectedSubscription));
    }
  };

  return (
    <div className={style.Quiz}>
      <div className="group group--slim mt2 mb2">
        <SectionHeader
          title="How would you like to get your Trendsend?"
          className="newHeader"
          style={{ fontSize: isMobile ? '20px' : '24px', marginBottom: '-0.5rem' }}
        />
        <Grid columns={12} className={classnames(style.deliveryGrid)}>
          <Cell width={12}>
            <h3 className={style.questionTitle}>Delivery or Pickup?</h3>
          </Cell>
          <Cell
            width={6}
            className={classnames(style.flowOptionContainer, isMobile ? 'mt' : 'mt4 mb4')}
            onClick={() => pickFlow('shipped')}
          >
            <div className={classnames(style.flowOption, chosenFlow === 'shipped' && style.selectedFlow)}>
              <img src={ThemeBoxImg} className={style.Icon} alt="Shipped To Me" />
            </div>
            <h4>Delivery</h4>
          </Cell>
          <Cell
            width={6}
            className={classnames(style.flowOptionContainer, isMobile ? 'mt' : 'mt4')}
            onClick={() => pickFlow('pickup')}
          >
            <div className={classnames(style.flowOption, chosenFlow === 'pickup' && style.selectedFlow)}>
              <img src={StorePickup} className={style.Icon} alt="Store Pickup" />
            </div>
            <h4>Pick up at an Evereve location</h4>
          </Cell>
        </Grid>

        <Fragment>
          {chosenFlow !== 'pickup' && (
            <Grid columns={12} className={classnames(style.deliveryGrid)}>
              <Cell width={12}>
                <h3 className={style.questionTitle}>Frequency</h3>
              </Cell>
              <Cell width={12}>
                <div className={style.subscriptionOptions}>
                  {choices.map((choice) => (
                    <button
                      key={choice.id}
                      className={classnames(style.subscriptionOption, selectedSubscription === choice.value && style.selectedOption)}
                      onClick={() => handleSubscriptionSelect(choice.value)}
                    >
                      {choice.label}
                    </button>
                  ))}
                </div>
              </Cell>
            </Grid>
          )}

          {/* Store Selection for Both Delivery and Pickup */}
          <Grid columns={12} className={classnames(style.deliveryGrid)}>
            <Cell width={12}>
              <h3 className={style.questionTitle}>Preferred Store</h3>
            </Cell>
            <Cell width={12}>
              <select
                onChange={(e) => handleStoreChange(e.target.value, 'preferred_store')}
                value={storeLocation}
                className={style.storeSelect}
              >
                <option value="">Select Store</option>
                {storeLocations.map((store, index) => (
                  <option key={index} value={store.label}>{store.label}</option>
                ))}
              </select>
            </Cell>
          </Grid>

          {/* Shoe Options Question for Pickup Only */}
          {chosenFlow === 'pickup' && (
            <Grid columns={12} className={classnames(style.deliveryGrid)}>
              <Cell width={12}>
                <h3 className={style.questionTitle}>Let's finish the look with some shoes. Want to see some options when you pick up in store?</h3>
              </Cell>
              <Cell width={12}>
                <div className={style.subscriptionOptions}>
                  <button
                    className={classnames(style.subscriptionOption, showShoeOptions === true && style.selectedOption)}
                    onClick={() => handleShoeOptionsSelect(true)}
                  >
                    Let's!
                  </button>
                  <button
                    className={classnames(style.subscriptionOption, showShoeOptions === false && style.selectedOption)}
                    onClick={() => handleShoeOptionsSelect(false)}
                  >
                    Maybe next time
                  </button>
                </div>
              </Cell>
            </Grid>
          )}

          <Grid columns={12} className={classnames(style.deliveryGrid)}>
            <Cell width={12}>
              <h3 className={style.questionTitle}>{'First delivery/pick up date'}</h3>
            </Cell>
            <Cell width={12}>
              <DayPickerSingleDateController
                onDateChange={handleDateChange}
                isDayBlocked={(d) => chosenFlow === 'pickup' ? isDayBlockedForPickup(d, holidays) : isDayBlockedForShipment(d, holidays)}
                date={selectedDate ? moment(selectedDate) : null}
                numberOfMonths={1}
                daySize={40}
                focused={true}
                hideKeyboardShortcutsPanel={true}
              />
            </Cell>
          </Grid>
        </Fragment>

        <Footer
          bgColor={[`#F9423A`, `#FCA09C`]}
          icon={`rightArrow`}
          disableButton={buttonDisabled}
          footerStep={handleStep}
          buttonCopy={`Continue`}
          hasArrow={true}
        />
      </div>
    </div>
  );
}

export default DeliveryMethod;

import api from "../../api";
import {fetchStyleProfile, updateResponse} from "./QuestionAction";
import {loadCustomerData} from "./CustomerAction";
import history from "./../../History";
import { serverErrors, UNEXPECTED_ERROR, serverErrorsFromActiveRecordErrors } from "./../../utils/errors";
import { clearCampaignVals } from './../../utils/cookie';
import * as tracking from './../../utils/gt-tracking';
import { toast } from 'react-toastify';
import { BASE_PATH } from './../../Config';
import _ from 'lodash';

export const LOGIN_ERROR        = 'LOGIN_ERROR';
export const LOGIN_SUBMITTED    = 'LOGIN_SUBMITTED';
export const LOGIN_SUCCESS      = 'LOGIN_SUCCESS';
export const SIGN_OUT_REQUESTED = 'SIGN_OUT_REQUESTED';
export const SIGN_OUT_SUCCESS   = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR     = 'SIGN_OUT_ERROR';
export const REGISTRATION_REQUESTED = 'REGISTRATION_REQUESTED';
export const REGISTRATION_RECEIVED = 'REGISTRATION_RECEIVED';
export const REGISTRATION_RECEIVED_ERROR = 'REGISTRATION_RECEIVED_ERROR';

export function loginSubmitted(data, redirectTo = 'dashboard', submitType) {
   return function(dispatch, getState) {
     dispatch({type: LOGIN_SUBMITTED, data: data});
     api.createUserSession(data)
       .then(userResp => {

        if (submitType === 'register') {
         tracking.push({eventCategory: 'onboarding', eventAction: 'signup', eventLabel: 'completed'});
        } else if (submitType === 'login') {
          tracking.push({ eventCategory: 'login', eventAction: 'form', eventLabel: 'submit' });
        }

         dispatch({type: LOGIN_SUCCESS, session: userResp.config});
         dispatch(loadCustomerData())

         localStorage.removeItem('styleProfile');
         if (!redirectTo && localStorage.getItem('redirectTo')) {
           redirectTo = localStorage.getItem('redirectTo');
           localStorage.removeItem('redirectTo');
         }
         if (_.get(getState(), 'themeboxes.selectedThemebox')) {
           history.push(`${BASE_PATH}/customize/order/order`);
         } else {
           history.push(`${BASE_PATH}/${redirectTo}`);
         }
         if (localStorage.getItem('payment')) {
           history.push(`${BASE_PATH}/change/payment`);
           localStorage.removeItem('payment');
         }
       }, errorResp => {
         if (window.grecaptcha) { window.grecaptcha.reset() }
         dispatch({type: LOGIN_ERROR, errors: serverErrors(errorResp)});
       });
   };
}


export function signOut() {
  return function(dispatch) {
    dispatch({type: SIGN_OUT_REQUESTED});
    history.push(`${BASE_PATH}/login`);
    api.destroyUserSession()
      .then(resp => {
        localStorage.removeItem('styleProfile');
        dispatch({type: SIGN_OUT_SUCCESS});

      }, errorResp => {
        dispatch({type: SIGN_OUT_ERROR, errors: serverErrors(errorResp)});
      });
  };
}

export function registerCustomer(customerData, inStore = false) {
  return function(dispatch, getState) {
    dispatch({type: REGISTRATION_REQUESTED, customerData});

    if (customerData.registered_in_store) {
      let randomPassword = _(10).times(() => { return _.random(0,10)}).join('');
      customerData.password = randomPassword
      customerData.password_confirmation = randomPassword
    }
    const redirectLocation = inStore ? 'trendsend-store-signup-location' : 'quiz/1';
    api.registerCustomer(customerData)
      .then(userResp => {
        clearCampaignVals();
        dispatch({type: REGISTRATION_RECEIVED, customer: userResp.data});
        
        dispatch(loginSubmitted({
          email: customerData.email,
          password: customerData.password,
        }, redirectLocation, 'register'));
        dispatch(loadCustomerData())
        history.push(`${BASE_PATH}/quiz/1`);
      }, errorResp => {
        if (window.grecaptcha) { window.grecaptcha.reset() }
        dispatch({type: REGISTRATION_RECEIVED_ERROR, errors: serverErrors(errorResp)});
      });
  }
}

export const CREATE_NEW_PASSWORD_REQUESTED = 'CREATE_NEW_PASSWORD_REQUESTED';
export const CREATE_NEW_PASSWORD_RECEIVED  = 'CREATE_NEW_PASSWORD_RECEIVED';
export const CREATE_NEW_PASSWORD_ERROR     = 'CREATE_NEW_PASSWORD_ERROR';

export function createNewPassword(email) {
  return function(dispatch) {
    dispatch({type: CREATE_NEW_PASSWORD_REQUESTED});
    toast.success('A password reset link has been sent to the email provided');
    api.createNewPassword(email)
      .then(resp => {
        dispatch({type: CREATE_NEW_PASSWORD_RECEIVED});

      }, errorResp => {
        // Note: We don't want users to know if they entered a valid customer email address so we always act like the form was successfully submitted.
        dispatch({type: CREATE_NEW_PASSWORD_RECEIVED});
      });
  }
}


export const UPDATE_PASSWORD_REQUESTED      = 'UPDATE_PASSWORD_REQUESTED';
export const UPDATE_PASSWORD_RECEIVED       = 'UPDATE_PASSWORD_RECEIVED';
export const UPDATE_PASSWORD_RECEIVED_ERROR = 'UPDATE_PASSWORD_RECEIVED_ERROR';

export function updatePassword(password, passwordConfirmation) {
  return function(dispatch) {
    dispatch({type: UPDATE_PASSWORD_REQUESTED});

    const urlParams = _(window.location.search.slice(1).split('&'))
      .map(item => item ? item.split('=') : null)
      .compact()
      .fromPairs()
      .value();
    const customerEmail = decodeURIComponent(urlParams.email);
    const resetPasswordData = {
      password: password,
      password_confirmation: passwordConfirmation,
      reset_password_token: urlParams.reset_password_token,
    };

    api.updatePassword(resetPasswordData)
      .then(resp => {
        // Immediately log user in after resetting password
        api.createUserSession({email: customerEmail, password})
          .then(userResp => {
            dispatch({type: LOGIN_SUCCESS, userSession: userResp.data});
            window.location.replace(`${BASE_PATH}/dashboard`);
          }, errorResp => {
            dispatch({type: LOGIN_ERROR, error: errorResp.data});
          });
      }, errorResp => {
        dispatch({
          type: UPDATE_PASSWORD_RECEIVED_ERROR,
          errors: serverErrors(errorResp)
        });
      });
  }
}
import {
  FETCHING_ORDERS_SUCCESS,
  FETCHING_ORDERS_ERROR,
  FETCHING_ORDERS,
  UPDATE_CURRENT_ORDER,
  INVOICE_REQUESTED,
  INVOICE_RECEIVED,
  INVOICE_RECEIVED_ERROR,
  UPDATE_CURRENT_ORDER_LINE_ITEMS,
  UPDATE_CURRENT_ORDER_LINE_ITEMS_SUCCESS,
  AVAILABLE_EXCHANGE_SIZES_REQUESTED,
  AVAILABLE_EXCHANGE_SIZES_RECEIVED,
  ADD_GIFT_CARD_REQUESTED,
  ADD_GIFT_CARD_RECEIVED_ERROR,
  REMOVE_GIFT_CARD_REQUESTED,
  REMOVE_GIFT_CARD_RECEIVED_ERROR,
  ADD_COUPON_REQUESTED,
  ADD_COUPON_RECEIVED_ERROR,
  REMOVE_COUPON_REQUESTED,
  REMOVE_COUPON_RECEIVED_ERROR,
  CHECKOUT_REQUESTED,
  UPDATE_ORDER,
  EXTEND_CHECKOUT_ERROR,
  BOX_REQUESTED,
  BOX_REQUESTED_SUCCESS,
  BOX_REQUESTED_ERROR,
  FETCHING_ALL_ORDERS,
  FETCHING_ALL_ORDERS_SUCCESS,
  FETCHING_ALL_ORDERS_ERROR,
} from "./../Actions/OrderAction";
import prepareInvoice from './../../utils/invoice-util';
import _ from 'lodash';

const completedOrderStatuses = ['cancelled', 'completed', 'fraud'];

const initState = {
  exchange_addresses: [],
  isLoading: false,
  fetchingInvoice: false,
  orders: {},
  allOrders: [],
  currentPage: 0,
  reachedEnd: false,
  currentOrder: {},
  currentOrderLineItems: {},
  currentInvoice: {},
  errors: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case INVOICE_RECEIVED_ERROR:
      return { ...state, isLoading: false, fetchingInvoice: false, errors: action.errors };
    case ADD_GIFT_CARD_RECEIVED_ERROR:
    case REMOVE_GIFT_CARD_RECEIVED_ERROR:
    case ADD_COUPON_RECEIVED_ERROR:
    case REMOVE_COUPON_RECEIVED_ERROR:
    case BOX_REQUESTED_ERROR:
    case FETCHING_ORDERS_ERROR:
      return { ...state, isLoading: false, errors: action.errors };
    case INVOICE_REQUESTED:
      return { ...state, isLoading: true, fetchingInvoice: true, errors: [] };
    case AVAILABLE_EXCHANGE_SIZES_REQUESTED:
      return { ...state, exchangeSizes: [], isLoading: true, errors: [] };
    case CHECKOUT_REQUESTED:
    case BOX_REQUESTED:
    case ADD_GIFT_CARD_REQUESTED:
    case REMOVE_GIFT_CARD_REQUESTED:
    case ADD_COUPON_REQUESTED:
    case REMOVE_COUPON_REQUESTED:
    case UPDATE_ORDER:
    case UPDATE_CURRENT_ORDER_LINE_ITEMS:
    case FETCHING_ORDERS:
      return { ...state, isLoading: true, errors: [] };
    case FETCHING_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.orders,
        currentOrder: prepareCurrentOrder(_.find(action.orders, o => !_.includes(completedOrderStatuses, o.status))),
        isLoading: false,
        errors: []
      };
    case BOX_REQUESTED_SUCCESS:
    case UPDATE_CURRENT_ORDER_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        currentOrder: action.order,
        isLoading: false,
        errors: []
      }
    case UPDATE_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          ...action.data
        }
      }
    case INVOICE_RECEIVED:
    
      const order = _.get(state, 'currentOrder', {});
      return {
        ...state,
        fetchingInvoice: false,
        isLoading: false,
        currentInvoice: prepareInvoice(action.invoice, {order})
      }
    case AVAILABLE_EXCHANGE_SIZES_RECEIVED:
      return {
        ...state,
        exchangeSizes: action.exchangeSizes || [],
        isLoading: false,
        errors: []
      };
    case EXTEND_CHECKOUT_ERROR:
      return {
        ...state,
        errors: [{extend_checkout: 'Could not extend checkout. Please try again or contact customer support.'}]
      }
    case FETCHING_ALL_ORDERS:
      return { 
        ...state, 
        isLoading: true, 
        errors: [] 
      };
    case FETCHING_ALL_ORDERS_SUCCESS:
      const newOrders = action.page > state.currentPage 
        ? _.uniqBy([...state.allOrders, ...action.orders], 'id')
        : action.orders;
      
      return {
        ...state,
        allOrders: newOrders,
        currentPage: action.page,
        reachedEnd: action.reachedEnd,
        isLoading: false,
        errors: []
      };
    case FETCHING_ALL_ORDERS_ERROR:
      return { 
        ...state, 
        isLoading: false, 
        errors: action.errors 
      };
    default:
      return state;
  }
}


function prepareCurrentOrder(order) {
  if (!order) return {};

  if (order.outfits) {
    order.outfits = _.filter(order.outfits, (o) => !_.isEmpty(o.line_items));
  }

  return order;
}
import React from 'react';
import Button from 'Button';
import style from './Footer.module.scss';
import classnames from 'classnames';

function Footer({
  footerStep,
  footerStepBack,
  disableButton,
  buttonType,
  buttonCopy,
  bgColor,
  footerCount,
  footerCountPrefix,
  hideButton,
  largeHeight,
  buttonSmall,
  isDashboard,
  hasBackground,
  hasCancelButton,
  hasBackButton,
  hideBackButton,
  redirectPath,
  hasArrow,
  isQuiz,
  requiredQuestion,
  previousPath,
  sticky,
  createAccountButton,
  paymentButton,
  hideBackButtonCount = false
}) {
  const footerColor = {
    '--footer-background-main': bgColor ? bgColor[0] : `#FA86C8`,
    '--footer-background-shadow': bgColor ? bgColor[1] : `#FDC3E4`,
  };

  return (
    <>
      {isDashboard === true ? (
        <footer
          className={classnames(style.DashboardFooter)}
          style={footerColor}
        >
          <div className={style.UpperFooter}>
            <div className={classnames('group', [style.Container])}>
              <div className={classnames([style.Column])}>
                <h5>Have a Question?</h5>
                <ul>
                  <li>
                    <a href="https://www.trendsend.com/faq">
                      Frequent Questions
                    </a>
                  </li>
                  <li>
                    <div className={style.ContactInfo}>Contact Us</div>
                    <div className={style.ContactInfo}>
                      <a href="mailto: trendsend@evereve.com">
                        trendsend@evereve.com
                      </a>
                    </div>
                    <div className={style.ContactInfo}>
                      <a href="tel">1 (877) 290-6262</a>
                    </div>
                    <div className={style.ContactInfo}>
                      Mon - Fri, 9am-5pm (CST)
                    </div>
                  </li>
                </ul>
              </div>
              <div className={classnames([style.Column])}>
                <h5>Evereve</h5>
                <ul>
                  <li>
                    <a target="_blank" href="https://evereve.com">
                      Shop Evereve
                    </a>
                  </li>
                  <li>
                    <a href="https://evereve.com/our-story">Our Story</a>
                  </li>
                  <li>
                    <a href="https://evereve.com/rewards">
                      Referrals + Rewards
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={classnames([style.LowerFooter])}>
              <div className={classnames('group', [style.Container])}>
                <div className={classnames([style.LeftSide])}>
                  <span>Trendsend by Evereve. All rights reserved.</span>
                </div>
                <div className={classnames([style.RightSide])}>
                  <span>
                    <a href="https://evereve.com/terms-of-service">
                      Terms Of Use
                    </a>
                  </span>
                  <span>
                    <a href="https://evereve.com/privacy-policy">
                      Privacy Policy
                    </a>
                  </span>
                  <span>
                    <a href="https://evereve.com/accessibility">
                      Accessibility Statement
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </footer>
      ) : (
        <footer
          className={
            (sticky && style.Sticky,
            hasBackground && hasBackground === true
              ? classnames([style.Footer], {
                  [style.FooterLarge]: largeHeight,
                })
              : classnames(`mt4`, sticky && style.Sticky))
          }
          style={footerColor}
        >
          {!hideButton && (
            <div
              className={classnames(
                isQuiz ? 'fdcr' : '',
                'full-width mt3',
                requiredQuestion && 'fdc'
              )}
            >
              <Button
                handleClick={footerStep}
                handleClickBack={footerStepBack}
                variant="Tertiary"
                isDisabled={disableButton}
                icon={!buttonCopy && `rightArrow`}
                iconColor="#FFFFFF"
                iconWidth={32}
                iconHeight={32}
                content={buttonCopy}
                fullWidth={buttonCopy}
                type={buttonType}
                count={footerCount}
                small={buttonSmall}
                countPrefix={footerCountPrefix}
                hasArrow={hasArrow}
                hasCancelButton={hasCancelButton}
                hasBackButton={hasBackButton}
                hideBackButton={hideBackButton}
                redirectPath={redirectPath}
                requiredQuestion={requiredQuestion}
                previousPath={previousPath}
                isCreateAccountButton={createAccountButton}
                isPaymentButton={paymentButton}
                hideBackButtonCount={hideBackButtonCount}
              />
            </div>
          )}
        </footer>
      )}
    </>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import style from "./FileUpload.module.scss";
import classnames from "classnames";
import { Cell } from 'styled-css-grid';
import { useDispatch, useSelector } from "react-redux";
import { updateResponse } from "../store/Actions/QuestionAction";
import axios from 'axios';
import api from "../api";

function FileUpload({ getValue, questionRef, initialValue, rotationDegree }) {
  const dispatch = useDispatch();
  const questions = useSelector(state => state.questions);
  const [images, setImages] = useState(initialValue || []);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    if (initialValue && Array.isArray(initialValue)) {
      setImageUrls(initialValue);
    }
  }, [initialValue]);

  function uploadFile(e) {
    const files = Array.from(e.target.files);
    const uploadPromises = files.map(file => 
      api.createImageUploadUrl(questions.id, file.name)
        .then(resp => {
          return axios.put(resp.data.upload_url, file, {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).then(() => resp.data.download_url);
        })
    );

    Promise.all(uploadPromises)
      .then(newUrls => {
        const updatedUrls = [...imageUrls, ...newUrls].slice(0, 5); // Limit to 5 images
        setImageUrls(updatedUrls);
        setImages([...images, ...files].slice(0, 5));
        getValue(updatedUrls, questionRef);
        dispatch(updateResponse(questionRef, { visual_content_upload_photos: updatedUrls }));
      })
      .catch(error => {
        console.error('Error uploading images:', error);
      });
  }

  function removeImage(index) {
    const updatedImages = images.filter((_, i) => i !== index);
    const updatedUrls = imageUrls.filter((_, i) => i !== index);
    setImages(updatedImages);
    setImageUrls(updatedUrls);
    getValue(updatedUrls, questionRef);
    dispatch(updateResponse(questionRef, { visual_content_upload_photos: updatedUrls }));
  }

  return (
    <Cell width={12}>
      <div className={style.FileUploadContainer}>
        <div className={style.ImagePreviewContainer}>
          {imageUrls.map((url, index) => (
            <div key={index} className={style.ImageWrapper}>
              <img
                src={url}
                alt={`Uploaded ${index + 1}`}
                className={style.ImagePreview}
              />
              <button className={style.RemoveButton} onClick={() => removeImage(index)}>
                &times;
              </button>
            </div>
          ))}
        </div>
        <div className={style.FileUploadLabelContainer}>
          <label className={classnames(style.UploadButton, imageUrls.length >= 5 && style.UploadButtonDisabled)}>
            <input
              className={style.FileUploadInput}
              type="file"
              onChange={uploadFile}
              accept="image/*"
              multiple
              disabled={imageUrls.length >= 5}
            />
            <div className={style.UploadButtonContent}>
              <div className={style.UploadIcon}>
                <div className={style.ArrowUpIcon}>
                  <div className="stem"></div>
                </div>
              </div>
              <div className={style.UploadText}>ADD PHOTO(S)</div>
              <div className={style.FileTypes}>Accepted file types: jpg, png</div>
            </div>
          </label>
        </div>
      </div>
    </Cell>
  );
}

export default FileUpload;

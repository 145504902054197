import React from 'react';
import style from './ProgressBar.module.scss';

const ProgressBar = ({ currentStep, currentQuestion, totalQuestions, stepMap = {} }) => {

  const sections = Object.keys(stepMap);
  const totalSteps = sections.length;

  if (totalSteps === 0) {
    return null; // If no steps are defined, don't render the progress bar
  }

  const getProgressInSection = (sectionIndex) => {
    const totalQuestionsInSection = Math.ceil(totalQuestions / totalSteps);
    const sectionStartQuestion = sectionIndex * totalQuestionsInSection;
    const sectionEndQuestion = sectionStartQuestion + totalQuestionsInSection;
  
    // If we're on the first question of the section, fill the bar a little
    if (currentQuestion === sectionStartQuestion) return 10; // 10% fill for the first question of the section
  
    // Determine how many questions have been answered in the current section
    if (currentQuestion >= sectionEndQuestion) return 100; // Section complete
    if (currentQuestion < sectionStartQuestion) return 0; // Section not started
  
    const questionsAnsweredInSection = currentQuestion - sectionStartQuestion;
    return ((questionsAnsweredInSection + 1) / totalQuestionsInSection) * 100;
  };

  const formatSectionName = (section) => {
    return section.toUpperCase().replace('_', ' & ').replace('AND_', '')
  };

  return (
    <div className={style.ProgressBarWrapper}>
      {sections.map((section, index) => {
        
        return (
          <div key={section} className={style.ProgressBarSegment}>
            <div
              className={style.ProgressBarFill}
              style={{ width: `${getProgressInSection(index)}%` }}
            />

            <div>{formatSectionName(section)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;

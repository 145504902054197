import moment from 'moment';

export function isDayBlockedForPickup(d, holidays, now = moment()) {
  const isPastDay = d.diff(now, 'days') < 0;
  const isTodayAfter3pm = now.hours() >= 15 && d.isSame(now, 'day');
  const isPickupHoliday = holidays.pickup_holidays && holidays.pickup_holidays.includes(d.format('YYYY-MM-DD'));
  const isAfter6Months = moment().add(5, 'months').diff(d, 'months') < 0;

  return isPastDay || isTodayAfter3pm || isPickupHoliday || isAfter6Months;
}

export function isDayBlockedForShipment(d, holidays, now = moment()) {
  const isFriday = now.day() == 5;
  const nextMon = d.isSame(now.clone().add(1, 'weeks').day(1), 'day');
  const nextTues = d.isSame(now.clone().add(1, 'weeks').day(2), 'day');
  const nextWed = d.isSame(now.clone().add(1, 'weeks').day(3), 'day');

  return (
    (isFriday && (nextMon || nextTues || nextWed)) ||
    getStyleDate(d, holidays).diff(now, 'days') < 0 ||
    d.day() == 0 ||
    (holidays.custom_ship_disallowed_dates && holidays.custom_ship_disallowed_dates.includes(d.format("YYYY-MM-DD"))) ||
    now.add(6, 'months').diff(d, 'months') < 0
  );
}

const getStyleDate = (d, holidays) => {
  let newDate = d.clone().add(-3, 'days');

  for(let i = 0; i < 10; i++) {
    if (newDate.day() == 0 || newDate.day() == 6 || holidays.company_holidays.includes(newDate.format("YYYY-MM-DD"))) {
      newDate.add(-1, 'days');
    } else {
      break;
    }
  }

  return(newDate);
}

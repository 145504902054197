// cardExpirationUtils.js
export const hasCardExpired = (expirationDate) => {
  // current date
  const now = new Date();
  // Remove the time from the current date so it doesn't interfere with the comparison
  now.setHours(0, 0, 0, 0);

  // convert expirationDate to Date object
  const cc_expiration = new Date(expirationDate);

  return cc_expiration < now;
};

export const isExpiringWithinNext14Days = (expirationDate) => {
  // current date
  const now = new Date();

  // date 14 days from now
  const daysFromNow = new Date();
  daysFromNow.setDate(now.getDate() + 14);

  // convert expirationDate to Date object
  const cc_expiration = new Date(expirationDate);

  return cc_expiration <= daysFromNow;
};

export function isWithin14Days(comparisonDate, ccExpiration) {
  const fourteenDaysInMillis = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
  const shipDate = new Date(comparisonDate);
  const expirationDate = new Date(ccExpiration);

  // Calculate the time difference in milliseconds
  const timeDifference = expirationDate.getTime() - shipDate.getTime();

  return timeDifference <= fourteenDaysInMillis;
}

export const isExpiringWithinNext45Days = (expirationDate) => {
  // current date
  const now = new Date();

  // date 45 days from now
  const daysFromNow = new Date();
  daysFromNow.setDate(now.getDate() + 45);

  // convert expirationDate to Date object
  const cc_expiration = new Date(expirationDate);

  return cc_expiration <= daysFromNow;
};


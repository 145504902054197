import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Cell } from 'styled-css-grid';
import style from './ConfirmShippingAddress.Module.scss';
import { BASE_PATH } from '../Config';
import _ from 'lodash';
import SubHeader from '../CustomizeOrder/SubHeader';

function ConfirmShippingAddress({
  path,
  isAbandonedAccount,
  shippingAddress,
  isAlignedLeftStyles,
  isCompactStyles,
  isConfirmation,
  exchangeAddress,
}) {
  
  return (
    <>
      <Grid
        className={
          isAlignedLeftStyles && !isCompactStyles
            ? 'mb'
            : isAlignedLeftStyles && isCompactStyles
            ? style.isFlex
            : ''
        }
      >
        {!isAlignedLeftStyles && (
          <Cell width={12}>
            <SubHeader
              header={
                isAbandonedAccount
                  ? `Shipping Address`
                  : `Confirm Shipping Address`
              }
              subheader='Your Trendsend will be shipped to your address listed below. You may change your default shipping address before confirming your order.'
              TextAlignCenter
            />
          </Cell>
        )}
        {!_.isEmpty(shippingAddress) && (
          <Cell width={12}>
            <div className={!isAlignedLeftStyles && 'ShippingAddress'}>
              {isAlignedLeftStyles && _.isEmpty(exchangeAddress) ? (
                <div>
                  {shippingAddress.shipping_line_1}{' '}
                  {isCompactStyles && (
                    <span>
                      {', '}
                      {shippingAddress.shipping_postal_code}
                    </span>
                  )}{' '}
                </div>
              ) : !_.isEmpty(exchangeAddress) ? (
                <div>
                  {exchangeAddress.exchange_line_1}{' '}
                  {isCompactStyles && (
                    <span>
                      {', '}
                      {exchangeAddress.exchange_postal_code}
                    </span>
                  )}{' '}
                </div>
              ) : (
                isCompactStyles &&
                !_.isEmpty(exchangeAddress)(
                  <span>
                    {exchangeAddress.exchange_line_1}
                    {`,`}{' '}
                  </span>
                )
              )}
              {!isAlignedLeftStyles && !isCompactStyles && _.isEmpty(exchangeAddress) && (
                <>
                  <span>{shippingAddress.shipping_line_1}{', '}</span>
                  <span>
                    {shippingAddress.shipping_city &&
                      shippingAddress.shipping_city}{' '}
                  </span>
                  <span>{shippingAddress.shipping_state} </span>
                  <span>{shippingAddress.shipping_postal_code}</span>
                </>
              )}
              {!isAlignedLeftStyles && !isCompactStyles && !_.isEmpty(exchangeAddress) && (
                <>
                  <span>
                    {exchangeAddress.exchange_city &&
                      exchangeAddress.exchange_city}{' '}
                  </span>
                  <span>{exchangeAddress.exchange_state} </span>
                  <span>{exchangeAddress.exchange_postal_code}</span>
                </>
              )}
            </div>
          </Cell>
        )}
        <Cell width={12} className={isAlignedLeftStyles ? 'mb' : 'jsc mt'}>
          <NavLink
            className={
              isAlignedLeftStyles ? 'tdn c-tertiary' : 'editField Border'
            }
            to={{
              pathname: `${BASE_PATH}/account/management`,
              navProps: {
                prevPath: isAlignedLeftStyles ? `/checkout/confirmation` : path.substring(path.lastIndexOf('/members/') + 8),
                isConfirmation: isConfirmation,
              },
            }}
          >
            {isAlignedLeftStyles
              ? `EDIT`
              : isAbandonedAccount
              ? `Add Shipping Address`
              : `Change Shipping Address`}
          </NavLink>
        </Cell>
      </Grid>
    </>
  );
}

export default ConfirmShippingAddress;

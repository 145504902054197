import React from "react";
import MaskedInput from 'react-text-mask';
import { maskDeterminer } from './utils';

const MaskedInputField = ({ name, type, placeholder, initialValue, field, mask, inputClasses, handleChange }) => (
  <MaskedInput
    mask={maskDeterminer(placeholder, mask)}
    className={inputClasses}
    name={name}
    type={type}
    placeholder={placeholder}
    value={initialValue == null ? '' : initialValue}
    guide={false}
    {...field}
    onChange={handleChange}
  />
);

export default MaskedInputField;

import React, { useRef } from "react";
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from "formik";
import { useField } from 'formik';
import style from "./Modal.module.scss";
import FormInput from "../FormInput";
import { Cell } from 'styled-css-grid';
import Button from "../Button";

const MultiFactorAuthModal = ({
  handleClose,
  show,
  handleMfaRequest,
  handleSubmitWithMfaCode,
  customer,
  shippingAddress
}) => {
  const mfaRequestSent = useSelector(state => state.customer.mfaRequestSent);
  const mfaSubmitError = useSelector(state => state.customer.mfaSubmitError);
  const formikRef = useRef();

  const initialValues = {
    method: "sms",
    code: ""
  };

  const handleSubmit = (values, { resetForm }) => {
    const { method, mfa_code } = values;
    if (mfa_code) {
      handleSubmitWithMfaCode(mfa_code, method);
      resetForm({
        values: {
          ...values,
          mfa_code: '',
        },
      });
    } else {
      handleMfaRequest(method);
    }
  };
  

  const RadioButton = ({ label, ...props }) => {
    const [field] = useField(props);

    return (
      <div className="mb" style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <div>
          <label style={{ marginRight: '1rem', marginBottom: '.5rem' }}>{label}</label>
          <input type="radio" {...field} {...props} className={style.CustomRadioButton} />
        </div>
        <div>
          <p>{props.contactMethod}</p>
        </div>
      </div>
    );
  };

  const showHideClassName = show ? `${style.Modal} ${style.ModalShow}` : style.Modal;

  const handleInnerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={showHideClassName} onClick={handleClose}>
      <div className={style.ModalBackdrop}>
        <section className={style.ModalInner} onClick={handleInnerClick}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <Cell width={12} className="mb" style={{ textAlign: 'center', fontSize: '20px' }}>
                  <h1>To ensure account security, please select a method below to verify your updates.</h1>
                </Cell>
                <Cell width={12} className="mb2">
                  <RadioButton name="method" value="sms" label="SMS" contactMethod={shippingAddress.shipping_phone} />
                  <RadioButton name="method" value="email" label="Email" contactMethod={customer.email} />
                </Cell>
                {mfaRequestSent && (
                  <Cell width={12} className="mb">
                    <p style={{ textAlign: 'center', fontSize: '18px' }}>Code Sent!</p>
                  </Cell>
                )}
                {mfaSubmitError && (
                  <Cell width={12} className="mb">
                    <p style={{ textAlign: 'center', fontSize: '18px', color: '#DA0229' }}>Oops! The code entered is incorrect. Please check and try again.</p>
                  </Cell>
                )}
                <Cell width={12} className="mb2">
                  <h2>Please enter the code here.</h2>
                  <Field
                    name='mfa_code'
                    type='text'
                    label='MFA Code'
                    placeholder='Code'
                    component={FormInput} />
                </Cell>
                <Button
                  content={(values.mfa_code || '').length >= 6 ? "Submit" : "Request Code"}
                  type="submit"
                  variant="Primary"
                  fullWidth
                />
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default MultiFactorAuthModal;

import React, { Component, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
// components
import AngleHeader from "AngleHeader";
import Header from "../Dashboard/Header.jsx";
import ActiveOrderTile from "./ActiveOrderTile";
import OrderBox from "./OrderBox";
import NoOrderHistory from "./NoOrderHistory";
// import AngleBG from "../Dashboard/AngleBG";
import NavBar from "NavBar";
// images 
import _ from "lodash";
import moment from "moment";
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from "../images/icon_account.svg";
import logOut from "../images/icon_logout.svg";
// actions
import { fetchOrders } from "./../store/Actions/OrderAction";

const color = {
    color: '#003b5c'
};

function OrderHistory() {

    const dispatch = useDispatch();
    const orderHistory = useSelector(reduxState => _.chain(reduxState)
        .get('orders.orders', [])
        .filter(o => _.includes(['cancelled', 'completed'], o.status))
        .orderBy(o => moment(o.expected_delivery_date), ['desc'])
        .value());
    const isLoading = useSelector(reduxState => _.get(reduxState, 'orders.isLoading'));

    useEffect(() => {
        dispatch(fetchOrders());
      }, [dispatch]);
    
    return (
      <div>
        <NavBar
          text='#003b5c'
          logo={EvereveTrendsendStackedLogoNavy}
          profile={profile}
          logOut={logOut}
        />
        <div className='profileContainer'>
          <div className='group mt'>
            <Header header='Your Orders' color={color} />
            <div className='orderHistoryContainer'>
              <div width={12}>
                {orderHistory.length === 0 ? (
                  <NoOrderHistory />
                ) : (
                  <OrderBox orders={orderHistory} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default OrderHistory;
import React, { useEffect, useState } from "react";
import { Grid, Cell } from "styled-css-grid";
import SwipeableCard from "SwipeableCard";
import ProgressBar from "ProgressBar";
import SectionHeader from "SectionHeader";
import Footer from "Footer";
import style from "../Quiz/Quiz.module.scss";
import _ from "lodash";
import { fetchStyleProfile, updateResponse, updateStyleProfile, SHUFFLE_COMPLETE } from "../store/Actions/QuestionAction";
import { useDispatch, useSelector } from "react-redux";
import history from "../History";
import { BASE_PATH } from "./../Config";
import * as tracking from './../utils/gt-tracking';
import { useMediaQuery } from 'react-responsive';


function StyleQuiz({ title, nextStep, currentStep, width, currentQuestion, totalQuestions }) {

  const dispatch = useDispatch();
  const getFields = useSelector(state => state.questions.data.shuffle);
  const getResponses = useSelector(state => state.questions.responses) || {};
  const authenticated = useSelector(state => state.auth.authenticated) || false;
  const fields = !_.isEmpty(getFields) && getFields;
  const cards = [];
  const [shuffleIndex, setShuffleIndex] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  // Add mounted ref to prevent updates after unmount
  const mounted = React.useRef(true);

  for (var field in fields) {
    cards.push({ id: field, image: fields[field] })
  }
  

  let handleStep = () => {
    tracking.push({ eventCategory: 'onboarding', eventAction: 'style shuffle', eventLabel: 'completed' });
    dispatch({type: SHUFFLE_COMPLETE});
    history.push(`${BASE_PATH}/quiz/12`);
  }

  let getValue = (id, val) => {
    if (!mounted.current) return;
    
    setShuffleIndex(shuffleIndex + 1);
    dispatch(authenticated ? updateStyleProfile({ [id]: val }) : updateResponse(id, val));
    
    // Add small delay before navigation to allow animation to complete
    if (shuffleIndex + 1 === cards.length) {
      setTimeout(() => {
        if (mounted.current) {
          dispatch({type: SHUFFLE_COMPLETE});
          history.push(`${BASE_PATH}/quiz/12`);
        }
      }, 300);
    }
  }

  let handleStepBack = () => {
    history.push(`${BASE_PATH}/quiz/10`);
  }

  useEffect(() => {
    dispatch(fetchStyleProfile());
    // Cleanup function
    return () => {
      mounted.current = false;
    };
  }, [dispatch]);

  return (
    <div className={style.Quiz}>
      <div className='group group--slim mt2 mb2'>
        <div className='pl pr'>
          <ProgressBar
            color={'#003b5c'}
            currentStep={currentStep}
            width={width}
            currentQuestion={currentQuestion}
            totalQuestions={totalQuestions}
          />
        </div>
        <SectionHeader
          title="Let’s Get to Know Your Style"
          className='newHeader'
        />
        <p className='mb2' style={{marginTop: '-1.5rem'}}>Would you rock this outfit?</p>
        <Grid columns={12}>
          <Cell width={12}>
            <SwipeableCard
              getValue={getValue}
              cards={cards}
              reshuffle={false}
            />

            <Footer
              bgColor={[`#F9423A`, `#FCA09C`]}
              disableButton={shuffleIndex < 10}
              footerStep={handleStep}
              footerStepBack={handleStepBack}
              buttonCopy={`Next`}
              hasBackButton={true}
              hideBackButton={false}
            />
          </Cell>
        </Grid>
      </div>
    </div>
  );
}

export default StyleQuiz;

import React, { useRef, useEffect, useState } from "react";
import style from "./TextArea.module.scss";
import { Cell } from "styled-css-grid";

const TextArea = ({ label, text, getValue, questionRef, initialValue, placeholder, value, onChange }) => {
  const textareaRef = useRef(null);
  const [content, setContent] = useState(initialValue || '');

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight, 24)}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [content]);

  const handleChange = (event) => {
    setContent(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Cell width={12} className={style.TextArea}>
      <label className={style.TextAreaLabel}>
        <textarea
          ref={textareaRef}
          className={style.TextAreaInput}
          onBlur={({ target: { value: text } }) => getValue(text, questionRef)}
          onChange={handleChange}
          placeholder={placeholder || `Tell us more...`}
          value={content}
          rows={1}
        />
      </label>
    </Cell>
  );
};

export default TextArea;

import AngleFitImage from "images/perfect-fit.jpg";
import introImage from "../images/callout/intro_lifestyle_desktop.webp";
import shippingImage from "../images/callout/shipping_lifestyle_desktop.webp";
import confirmationImage from "../images/callout/confirmation_lifestyle_desktop.webp";
import confirmationImageMobile from "../images/callout/confirmation_lifestyle_mobile.webp";
import { BASE_PATH } from "./../Config.js";

const createButton = (title, path) => ({
  title,
  path,
});

export const stylist = {
  title: "Thanks for letting us know what makes you tick.",
  description: [
    "Now, let's make sure we get the <span class='c-primary'>perfect fit</span>."
  ],
  image: AngleFitImage,
  layout: "50-50",
  button: createButton("Sounds Good!", `${BASE_PATH}/quiz/15`),
};

export const shipping = {
  title: "We’re almost ready to style your first Trendsend!",
  description: [
    "A $20 styling fee is charged when we style you and <strong>applied to the items you purchase</strong>. The styling fee on your <strong>first box</strong> will be only $1.",
    "Shipping is <strong>free</strong> both ways.",
    "You’ll have <strong>5 days</strong> to try everything on before sending back returns. You can also return items to any EVEREVE store.",
    "Keep 4 or more items and <strong>save 10%</strong> on your entire purchase."
  ],
  image: shippingImage,
  layout: "50-50",
  button: createButton("ENTER PAYMENT DETAILS", `${BASE_PATH}/payment/billing`),
  hasBackButton: true,
};

export const confirmation = {
  title: [
    "Thank you!",
    "A box of put-together-just-for-you outfits is on its way."
  ],
  description: [
    "Look for a confirmation email in your inbox."
  ],
  image: confirmationImage,
  imageMobile: confirmationImageMobile,
  layout: "full-screen",
  button: createButton("GO TO DASHBOARD", `${BASE_PATH}/dashboard`),
  hasBackButton: false,
};

export const welcome = {
  title: "LET’S GET STARTED",
  description: [
    "Building a closet you love is hard. We’re here to help. One put-together-just-for-you outfit at a time. To start building that dream closet, let’s get to know you and your style a little more. It will only take a few minutes and will give your stylist a better idea of where to start."
  ],
  image: introImage,
  layout: "50-50",
  button: createButton("LET'S GO", `${BASE_PATH}/register`),
  hasBackButton: false,
};

import React, { Fragment, useEffect } from 'react';
import { Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Confirmation from 'Confirmation';
import Payment from "Payment";
import Quiz from "Quiz";
import Callout from "Callout";
import PaymentInfo from "PaymentInfo";
import History from "../History";
import Dashboard from "Dashboard";
import ReferFriend from "ReferFriend";
import StylistNote from "StylistNote";
import StylistNotes from "StylistNotes";
import Review from "Review";
import DeliveryMethod from "DeliveryMethod";
import Longform from "Longform";
import StyleProfile from "StyleProfile";
import OrderHistory from "OrderHistory";
import ForgotPassword from "ForgotPassword";
import ResetPassword from "ResetPassword";
import OrderHistoryExpanded from "OrderHistoryExpanded";
import ManageSubscription from "ManageSubscription";
import CustomizeOrder from "CustomizeOrder";
import CustomizeDRTGOrder from "CustomizeDRTGOrder";
import AccountManagement from "AccountManagement";
import InStore from 'InStore';
import PurchaseHistory from "PurchaseHistory";
import ThemeBox from "ThemeBox";
import OrderThemebox from "ThemeBox/OrderThemebox";
import ChangePayment from "ChangePayment";
import Login from "Login";
import CatalogQRLandingPage from 'CatalogQRLandingPage';
import { stylist, shipping, confirmation, welcome } from "./ConfirmationScreens";
import SchedulePickup from 'SchedulePickup';
import Auth from "Auth";
import { loadCustomerData } from "./../store/Actions/CustomerAction";
import { signOut } from "./../store/Actions/AuthAction";
import store from "./../store";
import _ from "lodash";
import Loading from "Loading";
import ScrollToTop from 'react-router-scroll-top';
import { BASE_PATH } from './../Config';
import CheckoutSuccess from "CheckoutSuccess";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.scss";
require("dotenv").config();

window.store = store;
window.signout = () => { store.dispatch(signOut()) };

function App() {

  const anythingLoading = useSelector(state => _.some(state, 'isLoading'));
  const location = useLocation();
  const estimatedShippingDate = useSelector(state => _.get(state, 'orders.currentOrder.expected_delivery_date'));
  const pickupDate = useSelector(state => _.get(state, 'orders.currentOrder.ship_by_date'));
  const isDressingRoomToGoCustomer = useSelector(state => _.get(state, 'customer.data.dressing_room_to_go_signup'));
  const isThemeboxSignUp = useSelector(state => _.chain(state).get('customer.data.themebox_signup').value());
  
  const dispatch = useDispatch();
  useEffect(() => {
    const authRoutes = [
      '/login',
      '/register',
      '/forgot_password',
      '/reset_password',
      '/register-themebox',
      '/trendsend-store-signup'
    ];
    
    const isAuthRoute = authRoutes.some(route => 
      location.pathname.includes(route)
    );

    if (!isAuthRoute) {
      dispatch(loadCustomerData());
    }
  }, [dispatch, location.pathname]);

  return (
    <Fragment>
      <ToastContainer 
        position={toast.POSITION.TOP_CENTER}
        draggable
        closeOnClick
        newestOnTop
        hideProgressBar
        style={{textAlign: 'center'}}
      />
      <ScrollToTop />
      <main>
        {anythingLoading ? <Loading overlay="true" /> : ''}
        <Switch>
          <Route path={`${BASE_PATH}/welcome`} render={() => <Callout {...welcome} />} />
          <Route path={`${BASE_PATH}/account/confirmation`} render={() => <Callout {...confirmation} />} />
          <Route path={`${BASE_PATH}/quiz/confirmation`} render={() => <Callout {...shipping} />} />
          <Route path={`${BASE_PATH}/q/stylist`} render={() => <Callout {...stylist} />} />
          <Route path={`${BASE_PATH}/register`} component={Auth} />
          <Route path={`${BASE_PATH}/login`} component={Login} />
          <Route path={`${BASE_PATH}/register-themebox`} component={Auth} />
          <Route path={`${BASE_PATH}/trendsend-store-signup`} component={Auth} />
          <Route path={`${BASE_PATH}/order-theme-box`} component={OrderThemebox} />
          <Route path={`${BASE_PATH}/schedule-pickup`} component={SchedulePickup} />
          <Route path={`${BASE_PATH}/schedule-pickup-in-store`} component={SchedulePickup} />
          <Route path={`${BASE_PATH}/trendsend-store-signup-location`} component={InStore} />
          <Route path={`${BASE_PATH}/catalog-qr`} component={CatalogQRLandingPage} />
          <Route path={`${BASE_PATH}/forgot_password`} component={ForgotPassword} />
          <Route path={`${BASE_PATH}/reset_password`} component={ResetPassword} />
          <Route path={`${BASE_PATH}/quiz/delivery`} component={DeliveryMethod} />
          <Route path={`${BASE_PATH}/quiz/style`}>
            <Redirect to={`${BASE_PATH}/register`} />
          </Route>
          <Route path={`${BASE_PATH}/quiz/:id`} component={Quiz} />
          <Route exact path={`${BASE_PATH}/`} component={Dashboard} />
          <Route path={`${BASE_PATH}/dashboard`} component={Dashboard} />
          <Route path={`${BASE_PATH}/edit-profile/:section`} component={Longform} />
          <Route path={`${BASE_PATH}/orderhistory`} component={OrderHistory} />
          <Route path={`${BASE_PATH}/orderhistory/:id`} component={OrderHistoryExpanded} />
          <Route path={`${BASE_PATH}/styleprofile`} component={StyleProfile} />
          <Route path={`${BASE_PATH}/manage/subscription`} component={ManageSubscription} />
          <Route path={`${BASE_PATH}/customize/order/:step/pickup`} component={CustomizeDRTGOrder} />
          <Route path={`${BASE_PATH}/customize/order/:step`} component={CustomizeOrder} />
          <Route path={`${BASE_PATH}/account/management`} component={AccountManagement} />
          <Route path={`${BASE_PATH}/account/purchase-history`} component={PurchaseHistory} />
          <Route path={`${BASE_PATH}/change/payment`} component={ChangePayment} />
          <Route path={`${BASE_PATH}/refer/friend`} component={ReferFriend} />
          <Route path={`${BASE_PATH}/theme/box`} component={ThemeBox} />
          <Route path={`${BASE_PATH}/payment/info`} component={PaymentInfo} />
          <Route path={`${BASE_PATH}/payment/:step`} component={Payment} />
          <Route path={`${BASE_PATH}/checkout/stylist-note`} component={StylistNote} />
          <Route path={`${BASE_PATH}/account/stylist-notes`} component={StylistNotes} />
          <Route path={`${BASE_PATH}/checkout/review`} component={Review} />
          <Route path={`${BASE_PATH}/checkout/confirmation`} component={Confirmation} />
          <Route path={`${BASE_PATH}/checkout/success`} component={CheckoutSuccess} />
          <Route path={`${BASE_PATH}/checkout/success-drtg`} component={CheckoutSuccess} />
          <Route component={Dashboard} />
        </Switch>
      </main>
    </Fragment>
  );
}

export default function WrappedApp() {
  return (
    <Router history={History}>
      <App />
    </Router>
  );
}




import {
  FETCHING_STYLE_SHUFFLE,
  FETCHING_STYLE_PROFILE,
  FETCHING_STYLE_PROFILE_SUCCESS,
  FETCHING_STYLE_SHUFFLE_SUCCESS,
  FETCHING_STYLE_PROFILE_ERROR,
  UPDATING_STYLE_PROFILE,
  UPDATING_STYLE_PROFILE_SUCCESS,
  UPDATING_STYLE_PROFILE_ERROR,
  SHUFFLE_COMPLETE,
  SET_AS_PICKUP,
  SET_AS_PICKUP_SUCCESS,
  SET_BLOG_INTEREST,
} from "./../Actions/QuestionAction";
import _ from 'lodash';

let styleProfile = JSON.parse(localStorage.getItem('styleProfile'));

const initState = {
  isLoading: false,
  id: null,
  data: { fields: [], shuffle: [], reshuffle: [] },
  responses: {},
  shuffleComplete: false,
  blogInterest: null,
  errors: []
}

export default function (state = initState, action) {
  switch (action.type) {
    case SHUFFLE_COMPLETE:
      return {
        ...state,
        shuffleComplete: true
      };
    case FETCHING_STYLE_PROFILE_SUCCESS:
      return cacheState({
        ...state,
        id: action.id,
        name: action.name,
        data: {
          fields: action.data.questions.questions
        },
        responses: _.mergeWith(action.data.responses, state.responses, overwriteArrays),
        stats: action.data.stats,
        isLoading: false,
        errors: []
      });
    case FETCHING_STYLE_SHUFFLE_SUCCESS:
      return cacheState({
        ...state,
        data: {
          ...state.data,
          shuffle: action.data.questions.shuffle,
          reshuffle: action.data.questions.reshuffle
        },
        isLoading: false,
        errors: []
      });
    case FETCHING_STYLE_PROFILE:
      return {
        ...state,
        isLoading: _.isEmpty(state.data.fields),
        errors: []
      };
    case FETCHING_STYLE_SHUFFLE:
      return {
        ...state,
        isLoading: _.isEmpty(state.data.shuffle),
        errors: []
      };
    case FETCHING_STYLE_PROFILE_ERROR:
    case UPDATING_STYLE_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case UPDATING_STYLE_PROFILE_SUCCESS:
       
       if (localStorage.getItem('drtgSignup')) {
         localStorage.setItem('drtgSignup', JSON.stringify(_.mergeWith(state.responses, action.responses, overwriteArrays)));
       }
      return {
        ...state,
        responses: _.mergeWith(state.responses, action.responses, overwriteArrays),
        stats: action.stats || state.stats,
        isLoading: false,
        errors: []
      };
    case SET_AS_PICKUP:
      return {
        ...state,
        isLoading: true
      };
    case SET_AS_PICKUP_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case SET_BLOG_INTEREST:
      return {
        ...state,
        blogInterest: action.payload,
      };
    default:
      return state;
  }
}

function cacheState(state) {
  // localStorage.setItem('styleProfile', JSON.stringify(state));
  return state;
}

function overwriteArrays(objValue, srcValue) {
  if (_.isArray(objValue) && _.isArray(srcValue)) {
    return objValue = srcValue ;
  }
}

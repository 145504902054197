import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./StyleProfile.Module.scss";
import _ from "lodash";
import history from "../History";
import {
  fetchStyleProfile,
  updateStyleProfile,
} from "../store/Actions/QuestionAction";
import { Grid, Cell } from "styled-css-grid";
// components
import Header from "../Dashboard/Header.jsx";
import StyleProfileOption from "./StyleProfileOption";
import NavBar from "NavBar";
import ReshuffleSwipeableCard from "ReshuffleSwipeableCard";
// images
import PerfectFitImg from "../images/Tile/StyleProfile/StyleProfile-Fit.svg";
import StyleImg from "../images/Tile/StyleProfile/StyleProfile-Style.svg";
import PreferencesImg from "../images/Tile/StyleProfile/StyleProfile-Preferences.svg";
import LifeStyleImg from "../images/Tile/StyleProfile/StyleProfile-Lifestyle.svg";
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from "../images/icon_account.svg";
import logOut from "../images/icon_logout.svg";
import { BASE_PATH } from "./../Config";

let newPerfectFit = false;
let newLifeStyle = false;
let newStyle = false;
let newPrice = false;
let isReshuffleComplete;

const color = {
  color: "#003b5c",
};

function StyleProfile({ match }) {
  const dispatch = useDispatch();

  function handleAbout() {
    history.push(`${BASE_PATH}/edit-profile/about`);
  }

  function handleSizeFit() {
    history.push(`${BASE_PATH}/edit-profile/size_fit`);
  }

  function handleStyle() {
    history.push(`${BASE_PATH}/edit-profile/style`);
  }

  // function handlePricePreference() {
  //   history.push(`${BASE_PATH}/edit-profile/shipping`);
  // }

  let stats = useSelector((state) => _.get(state, "questions.stats", {}));
  const [shuffleIndex, setShuffleIndex] = useState(0);

  // reshuffle
  const getResponses = useSelector((state) =>
    _.get(state, "questions.responses", {})
  );
  const cards = useSelector((state) =>
    _.chain(state.questions.data.reshuffle)
      .map((val, key) => {
        return { id: key, image: val };
      })
      .filter((q) => !getResponses[q.id])
      .value()
  );

  if (_.isUndefined(isReshuffleComplete) && !_.isEmpty(cards)) {
    isReshuffleComplete = _.size(cards) < 15;
  }

  if (isReshuffleComplete === false && shuffleIndex >= 15) {
    isReshuffleComplete = true;
  }

  let getValue = (id, val) => {
    setShuffleIndex(shuffleIndex + 1);
    dispatch(updateStyleProfile({ [id]: val }));
  };

  useEffect(() => {
    dispatch(fetchStyleProfile());
  }, [dispatch]);
  
  let displayNone = "";

  if (isReshuffleComplete) {
    displayNone = "reshuffle-cards";
  }
  // end reshuffle

  return (
    <div>
      <NavBar
        text='white'
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
      />
      <div className='profileContainer mb2'>
        <div className='group mt'>
          <Header
            header='Style Profile'
            subheader='Your style profile is your stylist’s guide to creating the perfect Trendsend for you.'
            color={color}
            TextAlignCenter
            FontSizeLarge
          />
          <div className='optionsContainer'>
            {!isReshuffleComplete && (
              <Grid columns={12} className='shuffle-container'>
                <Cell width={12} className='shuffle-incomplete'>
                  <h2>This Just In</h2>
                </Cell>
              </Grid>
            )}
            <Grid columns={12} className='shuffle-container'>
              <Cell width={12} className={displayNone}>
                <ReshuffleSwipeableCard
                  getValue={getValue}
                  cards={cards}
                  reshuffle={true}
                  isReshuffleComplete={isReshuffleComplete}
                  preferencesImg={PreferencesImg}
                  styleProfilePath
                />
              </Cell>
              {isReshuffleComplete && (
                <Cell width={12} className='shuffle-complete'>
                  <p>Thanks for letting your stylist know what you love.</p>
                  <img src={PreferencesImg} alt='' />
                  <p>
                    That's it for today.
                    <br />
                    Come back tomorrow for more!
                  </p>
                </Cell>
              )}
            </Grid>

            <StyleProfileOption
              image={StyleImg}
              title='About Me'
              percentage={
                _.get(stats, 'about.completion_percentage', 0) * 100
              }
              newContent={newPerfectFit}
              handle={handleAbout}
            />

            <StyleProfileOption
              image={PerfectFitImg}
              title='Size & Fit'
              percentage={
                _.get(stats, 'size_fit.completion_percentage', 0) * 100
              }
              newContent={newLifeStyle}
              handle={handleSizeFit}
            />

            <StyleProfileOption
              image={LifeStyleImg}
              title='Style'
              percentage={_.get(stats, 'style.completion_percentage', 0) * 100}
              newContent={newStyle}
              handle={handleStyle}
            />

            {/* <StyleProfileOption
              image={PreferencesImg}
              title='Additional Details'
              percentage={_.get(stats, 'shipping.completion_percentage', 0) * 100}
              newContent={newPrice}
              handle={handlePricePreference}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StyleProfile;

export const formatPhoneNumber = (value) => {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return value;
};

export const formatCreditCardNumber = (value) => {
  return ('' + value).replace(/\D/g, '');
};

export const maskDeterminer = (placeholder, mask) => {
  const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  const cvvMask = Array(6).fill(/\d/);
  const creditCardMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  const expirationMask = [/\d/, /\d/, '/', /\d/, /\d/];

  if (placeholder.includes('Zip')) {
    return (rawValue) => {
      const numericValue = rawValue.replace(/\D/g, '');

      if (numericValue.length <= 5) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/];
      }

      return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    };
  }

  if (placeholder.includes('Phone')) return phoneMask;
  if (placeholder.includes('CVV')) return cvvMask;
  if (placeholder.includes('Credit')) return creditCardMask;
  if (placeholder.includes('MM/YY')) return expirationMask;
  
  return mask.split('').map((char) => (char === '9' ? /\d/ : char));
};

export const formatInputValue = (name, value) => {
  if (name.includes('phone')) {
    return formatPhoneNumber(value);
  } else if (name.includes('credit_card_number')) {
    return formatCreditCardNumber(value);
  }

  return value;
};

import * as yup from "yup";

const validStates = [
  'AA', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
  'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
  'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA',
  'WV', 'WI', 'WY'
];

export const schema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  shipping_line_1: yup.string(),
  shipping_line_2: yup.string(),
  shipping_city: yup.string(),
  shipping_state: yup.string().oneOf(validStates, 'Invalid state').required('State is required'),
  shipping_postal_code: yup.string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Please enter a valid zip code. Must be 5 or 9 digits.')
    .label('ZIP Code'),
  shipping_phone: yup.string()
  .matches(/^\D*([0-9]{3})\D*([0-9]{3})\D*([0-9]{4})\D*$/, 'Please enter a valid Cell Phone Number')
  .required("Required"),
  email: yup.string().email('Please enter a valid email address').required("Required"),
  current_password: yup.string().label('Current Password').min(8, `Min. 8 characters.`),
  new_password: yup.string().label('New Password').min(8, `Min. 8 characters.`).matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])/, "Password must contain at least one uppercase letter, one number, and one special character."),
});

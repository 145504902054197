import React, { useState, useRef, useEffect, Fragment } from "react";
import Button from "Button";
import classnames from "classnames";
import { Grid, Cell } from "styled-css-grid";
import style from "../SwipeableCard/SwipeableCard.module.scss";
import history from "../History";
import { BASE_PATH, API_BASE } from "./../Config";

function ReshuffleSwipeableCard({
  cards,
  getValue,
  reshuffle,
  isReshuffleComplete,
  styleProfilePath,
}) {
  const [cta, setCta] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current card index
  const [loadedImages, setLoadedImages] = useState({}); // Keep track of which images are loaded
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const id = entry.target.getAttribute("data-id");
          setLoadedImages((prevLoaded) => ({
            ...prevLoaded,
            [id]: true,
          }));
        }
      });
    });
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll("[data-lazy]");
    elements.forEach((element) => observerRef.current.observe(element));

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [currentIndex, cards]);

  function directionClick(id, image, dir) {
    const getDivs = document.getElementsByClassName([style.SwipeableCardItem]);
    const getFooterDivs = document.getElementsByClassName([
      style.SwipeableCardFooter,
    ]);
    let current = getDivs[getDivs.length - 1];
    let currentFooter = getFooterDivs[getFooterDivs.length - 1];

    if (getDivs.length === 1 && !reshuffle) {
      history.push(`${BASE_PATH}/register`);
      setCta(false);
    }

    setTimeout(() => {
      current.remove();
      currentFooter.remove();
    }, 350);

    if (dir === `left`) {
      current.classList.add(style.SwipeableCardItemLeft);
      getValue(id, { liked: false, image });
    } else {
      current.classList.add(style.SwipeableCardItemRight);
      getValue(id, { liked: true, image });
    }

    // Load the next card after the current card is swiped away
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }

  return (
    <div className={style.SwipeableCard}>
      <ul className={style.SwipeableCardList}>
        {cards.slice(currentIndex, currentIndex + 2).map(({ id, image }, index) => {
          let src = reshuffle ? `${API_BASE}/product_images/${id}` : image;
          return (
            <Fragment key={id}>
              <li
                className={classnames([style.SwipeableCardItem], {
                  [style.SwipeableCardItemHide]: false,
                })}
                data-lazy
                data-id={id}
                style={{ backgroundImage: `url(${src})` }}
              >
                {/* Lazy load the image if it is in view */}
                {loadedImages[id] && (
                  <img
                    src={src}
                    alt={id}
                    loading="lazy"
                  />
                )}
              </li>
            </Fragment>
          );
        })}
      </ul>

      {!isReshuffleComplete && styleProfilePath && (
        <Grid columns={12}>
          <Cell width={12} className="shuffle-incomplete">
            <p className="mt2 tac">Is this your style?</p>
          </Cell>
        </Grid>
      )}

      {cards.slice(currentIndex, currentIndex + 2).map(({ id, image }) => {
        return (
          <Fragment key={id}>
            {cta && (
              <div className={classnames(style.SwipeableCardFooter)}>
                <Button
                  variant="Primary"
                  content={`No`}
                  handleClick={() => directionClick(id, image, "left")}
                />
                <Button
                  variant="Primary"
                  content={`Yes`}
                  handleClick={() => directionClick(id, image, "right")}
                />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export default ReshuffleSwipeableCard;

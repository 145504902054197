import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Dropdown/Dropdown.module.scss';
import '../AccountManagement/AccountManagement.Module.scss';
import Footer from 'Footer';
import SectionHeader from 'SectionHeader';
import FormInput from 'FormInput';
import { Grid, Cell } from 'styled-css-grid';
import { Formik, Form, Field } from 'formik';
import Header from '../Dashboard/Header.jsx';
import { schema } from '../AccountManagement/schema';
import {
  RESET_CUSTOMER_DATA,
  fetchStoreLocations,
  updateCustomerDataOnly
} from './../store/Actions/CustomerAction';
import _ from 'lodash';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';
import { BASE_PATH } from './../Config';

const color = {
  color: '#003b5c',
};

function InStore() {
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.customer.data);

  const storeLocations = useSelector((state) => state.customer.storeLocations);

  const isSubmitting = useSelector((state) => state.customer.isLoading);

  function submitForm(values) {
    dispatch(updateCustomerDataOnly(values, `${BASE_PATH}/quiz/1`));
  }

  useEffect(() => {
    dispatch(fetchStoreLocations());
    dispatch({ type: RESET_CUSTOMER_DATA });
  }, [dispatch]);

  const sortedLocations = _.orderBy(storeLocations, ['address_state'], ['asc']);

  if (_.isEmpty(customer) || _.isEmpty(customer)) return '';

  return (
    <div>
      <NavBar
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
      />
      <div className='group group--slim mt2 mb2'>
        <Header
          header='Select Store'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />
        <Formik
          isInitialValid={true}
          validationSchema={schema}
          initialValues={{ ...customer }}
          onSubmit={submitForm}
        >
          {({ isValid }) => (
            <Form className='accountManagementForm'>
              <SectionHeader title='Customer Name' description='' />
              <Grid>
                <Cell width={12}>
                  <div>{customer['first_name']} {customer['last_name']}</div>
                </Cell>
              </Grid>
              <SectionHeader title='EVEREVE Store' />
              <Grid>
                <Cell width={12}>
                  <Field
                    className={styles.Dropdown}
                    name='assigned_location_id'
                    component='select'
                    placeholder='EVEREVE Store'
                  >
                    <option value="">Select a store...</option>
                    {sortedLocations.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.address_state}, {item.address_city} - {item.name}
                        </option>
                      );
                    })}
                  </Field>
                </Cell>
                <Cell width={12} className='mb2'>
                  <Field
                    name='preferred_stylist'
                    type='text'
                    label='Preferred Stylist'
                    placeholder='Preferred Stylist'
                    component={FormInput}
                  />
                </Cell>
              </Grid>
              <Footer
                bgColor={[`#CE0058`, `#E67FAB`]}
                buttonType='submit'
                buttonCopy={`Save Changes`}
                disabled={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default InStore;
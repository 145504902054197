import React, { useState } from "react";
import PasswordOff from "images/password-off.svg";
import PasswordOn from "images/password-on.svg";
import style from "./FormInput.module.scss";

const PasswordInputField = ({ name, placeholder, initialValue, field, inputClasses, handleChange, onSubmit, questionRef }) => {
  const [passwordField, setPasswordField] = useState(false);
  const togglePassword = () => setPasswordField(!passwordField);

  return (
    <>
      <input
        className={inputClasses}
        onBlur={handleChange}
        onKeyDown={(e) => e.keyCode === 13 && onSubmit && onSubmit(e.target.value, questionRef)}
        name={name}
        type={passwordField ? `text` : `password`}
        placeholder={placeholder}
        defaultValue={initialValue == null ? '' : initialValue}
        {...field}
      />
      <div onClick={togglePassword} className={style.FormInputPasswordToggle}>
        {passwordField ? <img src={PasswordOn} alt="Hide Password" /> : <img src={PasswordOff} alt="Hide Password" />}
      </div>
      <div className={style.FormInputAdditionalCopy}>
        Password must contain at least one uppercase letter, one number, and one special character.
      </div>
    </>
  );
};

export default PasswordInputField;

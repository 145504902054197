import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from 'Footer';
import { NavLink } from 'react-router-dom';
import Header from 'Dashboard/Header';
import history from '../History';
import ReviewItem from 'ReviewItem';
import ConfirmShippingAddress from './../ConfirmShippingAddress';
import _ from 'lodash';
import FormInput from 'FormInput';
import Button from 'Button';
import cardImage from 'images/card.svg';
import {
  fetchOrCreateInvoice,
  addGiftCard,
  addCoupon,
  removeGiftCard,
  removeCoupon,
  checkout,
  fetchOrders,
  submitGAClientId
} from '../store/Actions/OrderAction';
import { fetchBillingInfo } from './../store/Actions/CustomerAction';
import { BASE_PATH } from './../Config';
import * as format from './../utils/formatters';

function Confirmation() {
  const [couponCode, setCouponCode] = useState('');
  const [giftCardCode, setGiftCardCode] = useState('');
  const dispatch = useDispatch();
  const order = useSelector(state => _.get(state, 'orders.currentOrder', {}));
  const orders = useSelector(state => _.get(state, 'orders.orders', []));
  const fetchingInvoice = useSelector(state => _.get(state, 'orders.fetchingInvoice'));
  const isLoading = useSelector(state => _.get(state, 'orders.isLoading'));
  const isLoadingCustomer = useSelector(state => _.get(state, 'customer.isLoading'));
  const invoice = useSelector(state => _.get(state, 'orders.currentInvoice'), {});
  const checkoutItems = useSelector(state => _.get(state, `orders.currentInvoice.invoice_line_items`, []));
  const returnedItems = useSelector((state) =>
    _.get(state, `orders.currentOrder.line_items`, [])
  );
  const ccNumber = useSelector(state => _.chain(state).get('customer.billingInfo.card_info.credit_card_number', '').toString().value().slice(-4));
  const giftCard = _.get(invoice, 'gift_card');
  const coupon = _.get(invoice, 'coupon');
  const error = useSelector(state => _.get(state, 'orders.errors.0', {}));

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  const exchangeAddressArray = []

  localStorage.getItem('exchangeAddress') && exchangeAddressArray.push(JSON.parse(localStorage.getItem('exchangeAddress')))

  const exchangeAddress = useSelector((state) =>
  _.chain(exchangeAddressArray)
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`exchange_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  const hasExchangeAddress = !_.isEmpty(exchangeAddress);
  const hasShippingAddress = !_.isEmpty(shippingAddress);

  const celerantId = useSelector((state) =>
    _.get(state, 'customer.data.celerant_id', {})
  );

  const isAbandonedAccount = _.isEmpty(celerantId);

  const orderHasItems = _.filter(checkoutItems, l => l.claimed_kept || l.exchange_size).length > 0;

  const outfits = [
    {
      title: `Keeping`,
      items: _.filter(checkoutItems, (l) => l.claimed_kept && !l.exchange_size),
    },
    {
      title: `SHIPPING TO YOU`,
      items: _.filter(checkoutItems, (l) => l.claimed_kept && l.exchange_size),
    },
    {
      title: `Returning`,
      items: _.filter(returnedItems, l => l.claimed_kept === false || l.claimed_kept && l.exchange_size)
    }
  ]

  const hasShippingItems = _.find(outfits, {title: `SHIPPING TO YOU`}).items.length > 0

  function getCouponCode(val) {
    setCouponCode(val);
  }

  function getGiftCard(val) {
    setGiftCardCode(val);
  }

  useEffect(() => {
    dispatch(submitGAClientId());
    dispatch(fetchBillingInfo());
    dispatch(fetchOrders());
    order.id && dispatch(fetchOrCreateInvoice(order.id));
  }, [order.id]);

  useEffect(() => {
    if (order.coupon) {
      setCouponCode(order.coupon);
    }
  }, [order]);

  useEffect(() => {
    if (invoice && invoice.coupon) {
      setCouponCode('');
    }
  }, [invoice]);

  return (
    <div className='group group--slim' style={{ paddingBottom: `30vh` }}>
      <Header
        header='Confirm Purchase'
        subheader='Please review your purchase before submitting.'
        TextAlignCenter
      />

      <div className='mb2'>
        {outfits.map((outfit) => {
          return (
            <div key={outfit.title}>
              {outfit.items.length > 0 && (
                <h2 className='pt ff-body mb ttu fw-medium'>{outfit.title}</h2>
              )}

              {outfit.title === `SHIPPING TO YOU` && (
                <ConfirmShippingAddress
                  path={'/checkout/confirmation'}
                  isAbandonedAccount={isAbandonedAccount}
                  shippingAddress={shippingAddress}
                  exchangeAddress={exchangeAddress}
                  hasShippingAddress
                  isAlignedLeftStyles
                  isConfirmation
                />
              )}
              {outfit.items.map((item) => {
                return (
                  <ReviewItem
                    discount={invoice.fourItems}
                    // showSize={
                    //   (outfit.title === `returning` && item.exchange_size)
                    // }
                    hr={outfit.items.length > 1}
                    noIcon
                    noOptions
                    key={item.description}
                    lineItem={item}
                    isShippingItem={outfit.title === `SHIPPING TO YOU`}
                  />
                );
              })}
            </div>
          );
        })}

        <h2 className='pt2 ff-body ttu fw-medium'>Discount / Gift Card</h2>
        <div className='mb2'>
          {coupon &&
            <Fragment>
              <div className='mt2'>
                <p>
                  <span className='fw-medium'>Discount code: </span> {coupon.code}
                </p>
                <p>
                  <span className='fw-medium'>Discount amount: </span>
                  {coupon.amount_off_or_percentage_off_flag === 'AMOUNT_OFF'
                    ? `$${coupon.dollar_off_amount_or_percentage_off_amount}`
                    : `${coupon.dollar_off_amount_or_percentage_off_amount}%`}
                </p>
                <a
                  className='tdu gray-link'
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(removeCoupon(invoice.id));
                  }}
                >
                  Remove
                </a>
              </div>
            </Fragment>
          }
          <Fragment>
            <FormInput
              form={{}}
              onSubmit={() => dispatch(addCoupon(invoice.id, couponCode))}
              getValue={getCouponCode}
              label='Discount Code'
              placeholder='Enter discount code'
              initialValue={couponCode}
            />
            {error.code === 'PROMO_CODE_NOT_RECOGNIZED' ? (
              <p className='c-error'>{error.message}</p>
            ) : (
              ''
            )}
            <div className='mt2'>
              <Button
                content='Apply Code'
                disabled={isLoading}
                onClick={() => dispatch(addCoupon(invoice.id, couponCode))}
                fullWidth
                variant='Primary'
              />
            </div>
          </Fragment>
        </div>

        <div className='mb'>
          {giftCard ? (
            <Fragment>
              <div className='mt2'>
                <p>
                  <span className='fw-medium'>Gift card: </span>{' '}
                  {giftCard.SMART_CARD_NUM}
                </p>
                <p>
                  <span className='fw-medium'>Balance: </span>{' '}
                  {giftCard.AVAILABLE}
                </p>
                <a
                  className='gray-link'
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(removeGiftCard(invoice.id));
                  }}
                >
                  Remove
                </a>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <FormInput
                form={{}}
                onSubmit={() => dispatch(addGiftCard(invoice.id, giftCardCode))}
                getValue={getGiftCard}
                label='Gift Card'
                placeholder='Enter gift card number'
              />
              {error.code === 'not_found' ? (
                <p className='c-error'>{error.message}</p>
              ) : (
                ''
              )}
              <div className='mt2'>
                <Button
                  content='Apply Gift Card'
                  disabled={isLoading}
                  onClick={() =>
                    dispatch(addGiftCard(invoice.id, giftCardCode))
                  }
                  fullWidth
                  variant='Primary'
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <h2 className='pt2 ff-body ttu fw-medium c-gray'>Price Breakdown</h2>

      <ul>
        <li className='df jfs mb'>
          Items <span>{format.dollars(invoice.original_subtotal)}</span>
        </li>
        {invoice.fourItems && (
          <li className='df jfs c-gray mb'>
            Keep Four Items{' '}
            <span>
              -{format.dollars(parseFloat(invoice.original_subtotal) * 0.1)}
            </span>
          </li>
        )}
        {parseFloat(invoice.discount_amount) > 0 && (
          <li className='df jfs c-gray mb'>
            Discount <span>-{format.dollars(invoice.discount_amount)}</span>
          </li>
        )}
        <hr className='mb mt0' />
        <li className='df jfs ttu fw-medium mb'>
          Total Before Tax <span>{format.dollars(invoice.totalBeforeTax)}</span>
        </li>
        <br />
        <br />
        <li className='df jfs mb'>
          Tax <span>{format.dollars(invoice.tax)}</span>
        </li>

        <li className='df jfs mb'>
          Total <span>{format.dollars(invoice.totalWithTax)}</span>
        </li>

        {parseFloat(invoice.credit_amount) > 0 && orderHasItems && (
          <li className='df jfs c-gray mb'>
            Styling Fee Credit{' '}
            <span>-{format.dollars(invoice.credit_amount)}</span>
          </li>
        )}

        <li className='df jfs c-gray mb'>
          Gift Card{' '}
          <span>-{format.dollars(invoice.totalGiftCardAmountUsed)}</span>
        </li>

        <hr className='mb mt0' />

        <li className='df jfs ttu fw-medium mb'>
          Order Total <span>{format.dollars(invoice.totalDue)}</span>
        </li>
      </ul>

      <br />

      <div className='mb2'>
        {hasShippingItems && (
          <>
            <h2 className='ff-body ttu fw-medium'>Shiping Address</h2>
            <ConfirmShippingAddress
              path={history.location.pathname}
              isAbandonedAccount={isAbandonedAccount}
              shippingAddress={shippingAddress}
              exchangeAddress={exchangeAddress}
              hasShippingAddress
              isAlignedLeftStyles
              isConfirmation
              isCompactStyles
            />
          </>
        )}
      </div>

      <h2 className='ff-body ttu fw-medium c-gray df jfs'>
        Payment Method
        <NavLink
          className='tdu c-gray gray-link'
          to={`${BASE_PATH}/change/payment?redirectTo=${BASE_PATH}/checkout/confirmation`}
        >
          Edit
        </NavLink>
      </h2>
      <div className='df jfs'>
        <span>
          <img
            className='dib vam mr-'
            width='25px'
            src={cardImage}
            alt='Card'
          />
          •••• •••• •••• {ccNumber}
        </span>
      </div>

      <Footer
        bgColor={[`#CE0058`, `#E67FAB`]}
        buttonType='submit'
        buttonCopy='Complete Purchase'
        hasBackButton={true}
        hideBackButton={false}
        footerStepBack={() => history.push(`${BASE_PATH}/checkout/review`)}
        footerCount={''}
        footerCountPrefix='$'
        footerStep={() => {
          dispatch(checkout(invoice.id, order, hasExchangeAddress && exchangeAddress));
        }}
      />
    </div>
  );
}

export default Confirmation;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../History';
import './PurchaseHistory.Module.scss';
import Footer from 'Footer';
import Header from '../Dashboard/Header.jsx';
import ReviewItem from 'ReviewItem';
import moment from 'moment';
import _ from 'lodash';
import NavBar from 'NavBar';
import Button from 'Button';
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';
import { BASE_PATH } from '../Config';
import { fetchAllOrders } from '../store/Actions/OrderAction';

const color = {
  color: '#003b5c',
};

// Define status constants outside the component to avoid recreation on each render
const VALID_ORDER_STATUSES = [
  'completed',
  'delivered_to_trendsend',
  'in_transit_to_trendsend',
  'checked_out_by_admin',
  'post_admin_checkout_processing',
  'post_customer_checkout_processing'
];

function PurchaseHistory() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  const orders = useSelector((state) =>
    _.chain(state)
      .get('orders.allOrders', [])
      .filter(order => VALID_ORDER_STATUSES.includes(order.status))
      .orderBy(['delivery_date'], ['desc'])
      .value()
  );

  const isLoading = useSelector((state) => state.orders.isLoading);
  const reachedEnd = useSelector((state) => state.orders.reachedEnd);

  useEffect(() => {
    dispatch(fetchAllOrders(currentPage));
  }, [dispatch, currentPage]);

  const handleLoadMore = () => {
    if (!isLoading && !reachedEnd) {
      setCurrentPage(prev => prev + 1);
    }
  };

  return (
    <div>
      <NavBar
        text='#003b5c'
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
      />
      <div className='group group--slim mt2 mb2'>
        <Header
          header='Purchase History'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <div className='mb2'>
          {orders.map((order, index) => {
            const invoice = _.filter(
              order.invoices,
              (l) => l.invoice_type === 'customer'
            );

            const sortedOrdersByTitle = [
              {
                title: `Kept`,
                items: _.filter(
                  order.line_items,
                  (l) => l.paid_for && !l.exchange_size
                ),
              },
              {
                title: `Exchanged`,
                items: _.filter(
                  order.line_items,
                  (l) => l.paid_for && l.exchange_size
                ),
              },
              {
                title: `Returned`,
                items: _.filter(
                  order.line_items,
                  (l) => l.paid_for === false
                ),
              },
            ];

            const fourItems = _.size(_.filter(
                  order.line_items,
                  (l) => l.paid_for && !l.exchange_size
                )) >= 4;
            
            return (
              <div className='mb2' key={order.id}>
                <div className={index > 0 ? 'pt mb' : 'mb'}>
                  <span>Order #{order.code}</span> |{' '}
                  <span>{moment(order.delivery_date).format('MMMM DD, YYYY')}</span> |{' '}
                  {invoice.length > 0 && <span>${invoice[0].total}</span>}
                </div>
                <div className='PurchaseHistoryHr'></div>
                {sortedOrdersByTitle.map((outfit) => {
                  return (
                    <div className='mb2' key={`${order.id}-${outfit.title}`}>
                      {outfit.items.length > 0 && (
                        <h2 className='pt ff-body mb ttu fw-medium'>
                          {outfit.title}
                        </h2>
                      )}
                      {outfit.items.map((item) => {
                        return (
                          <ReviewItem
                            discount={fourItems}  
                            showSize={order.title === `Exchanging`}
                            noIcon
                            noOptions
                            key={`${order.id}-${item.id}`}
                            lineItem={item}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {orders.length > 0 && !reachedEnd && !isLoading && (
          <div className='text-center mb2'>
            <Button
              variant="Text"
              content={'Show More Orders'}
              fullWidth={'Show More Orders'}
              type="submit"
              onClick={handleLoadMore}
              disabled={isLoading}
            />
          </div>
        )}

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType='submit'
          buttonCopy={`Account Management`}
          footerStep={() => history.push(`${BASE_PATH}/account/management`)}
        />
      </div>
    </div>
  );
}

export default PurchaseHistory;

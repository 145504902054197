import {
  CUSTOMER_DATA_REQUESTED,
  CUSTOMER_DATA_RECEIVED,
  CUSTOMER_DATA_ERROR,
  SUBSCRIPTION_UPDATE_REQUEST,
  SUBSCRIPTION_UPDATE_SUCCESS,
  SUBSCRIPTION_UPDATE_ERROR,
  UPDATE_SHIPPING_ADDRESS_REQUESTED,
  UPDATE_SHIPPING_ADDRESS_RECEIVED,
  UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR,
  CREATE_SHIPPING_ADDRESS_REQUESTED,
  CREATE_SHIPPING_ADDRESS_RECEIVED,
  BILLING_INFO_RECEIVED,
  BILLING_INFO_REQUESTED,
  BILLING_INFO_ERROR,
  UPDATE_BILLING_INFO_REQUEST,
  UPDATE_BILLING_INFO_SUCCESS,
  UPDATE_BILLING_INFO_ERROR,
  ALL_REFERRALS_SUCCESS,
  STORE_LOCATIONS_REQUESTED,
  STORE_LOCATIONS_RECEIVED,
  STORE_LOCATIONS_RECEIVED_ERROR,
  UPDATING_CUSTOMER_DATA,
  UPDATING_CUSTOMER_DATA_SUCCESS,
  UPDATING_CUSTOMER_DATA_ERROR,
  CREATE_PAYMENT_DATA_REQUESTED,
  CREATE_PAYMENT_DATA_RECEIVED,
  CREATE_PAYMENT_DATA_ERROR,
  SET_IN_STORE_SIGNUP,
  SET_DRESSING_ROOM_TO_GO_SIGNUP,
  SET_SKIP_A_BOX_RECEIVED,
  SET_SKIP_A_BOX_RECEIVED_ERROR,
  SET_UNSKIP_A_BOX_RECEIVED,
  SET_UNSKIP_A_BOX_RECEIVED_ERROR,
  MFA_ERROR_DETECTED,
  MFA_REQUESTED,
  MFA_REQUEST_SENT,
  MFA_REQUEST_FAILED,
  MFA_RESUBMISSION_REQUESTED,
  MFA_RESUBMISSION_SUCCESS,
  MFA_RESUBMISSION_FAILURE,
  RESET_MFA_REQUIRED,
  SHIPPO_ERROR_DETECTED,
  RESET_CUSTOMER_DATA,
  BOX_REQUESTED_SUCCESS,
} from "./../Actions/CustomerAction";
import _ from 'lodash';



const initState = {
  data: {},
  referrals: [],
  billingInfo: {},
  storeLocations: [],
  mfaRequestSent: false,
  mfaRequestError: false,
  mfaSubmitError: false,
  mfaRequired: false,
  isLoading: false,
  errors: []
};

export default function (state = initState, action) {
  switch (action.type) {
    case SET_IN_STORE_SIGNUP:
      return { ...state, inStore: true }
    case SET_DRESSING_ROOM_TO_GO_SIGNUP:
      return { ...state, drtgSignup: true }
    case BILLING_INFO_ERROR:
    case SUBSCRIPTION_UPDATE_ERROR:
    case UPDATING_CUSTOMER_DATA_ERROR:
    case STORE_LOCATIONS_RECEIVED_ERROR:
    case CREATE_PAYMENT_DATA_ERROR:
    case UPDATE_BILLING_INFO_ERROR:
    case UPDATE_SHIPPING_ADDRESS_RECEIVED_ERROR:
    case CUSTOMER_DATA_ERROR:
    case SET_SKIP_A_BOX_RECEIVED_ERROR:
    case SET_UNSKIP_A_BOX_RECEIVED_ERROR:
      return { ...state, isLoading: false, errors: action.errors };
    case UPDATE_SHIPPING_ADDRESS_RECEIVED:
    case CREATE_SHIPPING_ADDRESS_RECEIVED:
      return {
        ...state, isLoading: false, data: {
          ...state.data,
          registration_shipping_address_complete: true,
          shipping_addresses: [
            ..._.filter(state.data.shipping_addresses, s => !s.is_primary),
            action.data]
        }, errors: []
      };
    case SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state, isLoading: false, data: {
          ...state.data,
          style_profile_complete: true,
          subscription_type_selected: true
        }, errors: []
      };
    case UPDATING_CUSTOMER_DATA_SUCCESS:
    case CUSTOMER_DATA_RECEIVED:
    case SET_SKIP_A_BOX_RECEIVED:
    case SET_UNSKIP_A_BOX_RECEIVED:
      return {
        ...state, isLoading: false, data: {
          ...state.data,
          ...action.data
        }, errors: []
      };
    case BILLING_INFO_RECEIVED:
      return { ...state, isLoading: false, billingInfo: action.data, errors: [] };
    case SUBSCRIPTION_UPDATE_REQUEST:
    case CREATE_SHIPPING_ADDRESS_REQUESTED:
    case CREATE_PAYMENT_DATA_REQUESTED:
    case UPDATE_SHIPPING_ADDRESS_REQUESTED:
    case BILLING_INFO_REQUESTED:
    case CUSTOMER_DATA_REQUESTED:
    case UPDATE_BILLING_INFO_REQUEST:
    case STORE_LOCATIONS_REQUESTED:
    case UPDATING_CUSTOMER_DATA:
    case BOX_REQUESTED_SUCCESS:
      return { ...state, isLoading: true, errors: [] };
    case UPDATE_BILLING_INFO_SUCCESS:
      return {
        ...state, isLoading: false, errors: [], data: {
          ...state.data,
          billingInfo: action.data
        }
      };
    case CREATE_PAYMENT_DATA_RECEIVED:
      return {
        ...state, isLoading: false, errors: [], data: {
          ...state.data,
          billing_info_complete: true
        }
      };
    case ALL_REFERRALS_SUCCESS:
      return { ...state, isLoading: false, referrals: action.data, errors: [] };
    case STORE_LOCATIONS_RECEIVED:
      return { ...state, isLoading: false, storeLocations: action.storeLocations, recommendedStoreLocation: action.storeLocations[0], errors: [] };
    case MFA_RESUBMISSION_REQUESTED:
    case MFA_REQUESTED:
      return {
        ...state,
        mfaRequired: action.mfaRequired,
        mfaSubmitError: false,
        shippoError: false,
        isLoading: true,
        errors: []
      };
    case MFA_ERROR_DETECTED:
      return {
        ...state,
        isLoading: false,
        mfaRequired: action.mfaRequired,
        shippoError: false,
        errors: []
      };
    case MFA_REQUEST_SENT:
      return {
        ...state,
        isLoading: false,
        mfaRequestSent: action.mfaRequestSent,
        mfaRequired: action.mfaRequired,
        mfaRequestError: action.mfaRequestError,
        shippoError: false,
        errors: []
      };
    case MFA_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        mfaRequestSent: false,
        mfaRequired: action.mfaRequired,
        mfaRequestError: action.mfaRequestError,
        shippoError: false,
        errors: []
      };
    case MFA_RESUBMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mfaRequestSent: false,
        mfaRequired: action.mfaRequired,
        shippoError: false,
        mfaSubmitError: false,
        errors: []
      };
    case MFA_RESUBMISSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        mfaRequestSent: false,
        mfaRequired: action.mfaRequired,
        mfaSubmitError: action.mfaSubmitError,
        errors: []
      };
    case RESET_MFA_REQUIRED:
      return {
        ...state,
        mfaRequestSent: false,
        mfaRequired: false,
        mfaRequestError: false,
        mfaSubmitError: false,
        shippoError: false,
      };
    case SHIPPO_ERROR_DETECTED:
      return {
        ...state,
        isLoading: false,
        shippoError: true,
        mfaRequestSent: false,
        mfaRequired: false,
        mfaRequestError: false,
        mfaSubmitError: false,
        errors: action.errors
      };
    case RESET_CUSTOMER_DATA:
      return {
        ...state,
        data: initState.data,
        billingInfo: initState.billingInfo,
        errors: []
      };
    case 'UPDATE_SHIPPING_DATA_ONLY':
      return {
        ...state,
        data: {
          ...state.data,
          registration_shipping_address_complete: true,
          shipping_addresses: [
            ..._.filter(state.data.shipping_addresses, s => !s.is_primary),
            action.data
          ]
        }
      };
    default:
      return state;
  }
}


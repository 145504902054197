import React from 'react';
import classNames from "classnames";
import style from "./Banner.module.scss";
import { BASE_PATH } from "./../Config";

function Banner({ message, link, hasBasePath, isRed }) {
  return (
    <div className={classNames(style.Banner, isRed && style.Red)}>
      <a href={hasBasePath ? `${BASE_PATH}${link}` : `${link}`}>{message}</a>
    </div>
  )
}

export default Banner;
import React, { useState, useEffect, Fragment } from 'react';
import { getQuestionSelector } from 'QuestionSelector';
import style from './Quiz.module.scss';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import Button from 'Button';
import SectionHeader from 'SectionHeader';
import ProgressBar from 'ProgressBar';
import StyleQuiz from '../StyleQuiz';
import DeliveryMethod from '../DeliveryMethod';
import { Grid, Cell } from 'styled-css-grid';
import history from '../History';
import classnames from 'classnames';
import { BASE_PATH } from './../Config';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { fetchStoreLocations } from '../store/Actions/CustomerAction';
import {
  fetchStyleProfile,
  updateStyleProfile,
  updateResponse,
} from '../store/Actions/QuestionAction';
import Loading from 'Loading';
import * as tracking from './../utils/gt-tracking';

const stepMap = {
  about: 1,
  size_and_fit: 2,
  style: 3,
  shipping: 4,
};

function Quiz({ match }) {
  const dispatch = useDispatch();
  const isDressingRoomToGo = useSelector((state) =>
    _.chain(state).get('customer.data.dressing_room_to_go_signup').value()
  );
  const quizName = useSelector((state) => state.questions.name);

  const version = window.location.search[2];
  const num = +match.params.id;
  let v = quizName && quizName.split('(')[1] ? quizName.split('(')[1][0] : 0;
  if (!version && quizName) {
    history.push(`${BASE_PATH}/quiz/${num}?v${v}`);
  }

  const fields = useSelector((state) => {
    return (
      state.questions &&
      _.filter(
        state.questions.data.fields
      )
    );
  });

  const responses = useSelector((state) => state.questions.responses);
  const isLoading = useSelector((state) => state.questions.isLoading);
  const [button, setButton] = useState(false);
  const [hideBackButton, setHideBackButton] = useState(true);

  useEffect(() => {
    dispatch(fetchStyleProfile());
    dispatch(fetchStoreLocations());

    if (num >= 2) {
      setHideBackButton(false);
    } else {
      setHideBackButton(true);
    }
  }, [dispatch, num]);

  let isValid = () => {
    return !_.some(requiredRefs, (ref) => {
      return _.isEmpty(currentResponses[ref]);
    });
  };

  const step = match.params.id;
  const current =
    (!_.isEmpty(fields) && fields[parseInt(step) - 1]) ||
    fields[parseInt(step) - 2];

  if (!current) return '';

  const getField =
    current.type === `group`
      ? _.map(current.properties.fields, (f) =>
        _.merge(
          f,
          _.pick(current, [
            'section',
            'onboarding_section',
            'exclude_from_onboarding',
          ])
        )
      )
      : current && [current];
  const requiredRefs = _.chain(getField)
    .filter((f) => _.get(f, 'validations.required'))
    .map('ref')
    .value();

  let currentResponses =
    !_.isEmpty(responses) &&
    _.reduce(
      getField,
      (current, field) => {
        return _.merge(current, { [field.ref]: responses[field.ref] });
      },
      {}
    );

  if (isValid(requiredRefs) && !button) {
    setButton(true);
  } else if (!isValid(requiredRefs) && button) {
    setButton(false);
  }

  let val = (value, ref) => {
    setButton(isValid());
    if (_.isString(ref)) {
      setTimeout(() => {
        dispatch(updateResponse(ref, value));
      }, 100);
    }
  };

  let handleStep = () => {
    const currentSection = current && current.onboarding_section;
    const nextNum = num + 1;
    const nextQuestion = fields[nextNum - 1];
    const nextSection = nextQuestion && nextQuestion.onboarding_section;

    if (currentSection && nextSection && currentSection !== nextSection) {
      
      switch (currentSection) {
        case 'about':
          tracking.push({ eventCategory: 'onboarding', eventAction: 'about', eventLabel: 'completed' });
          break;
        case 'size_fit':
          tracking.push({ eventCategory: 'onboarding', eventAction: 'size', eventLabel: 'completed' });
          break;
        case 'style':
          tracking.push({ eventCategory: 'onboarding', eventAction: 'style', eventLabel: 'completed' });
          break;
      }
    }
    
    if (num === fields.length - 2) {
      dispatch(updateStyleProfile(currentResponses));
      return history.push(`${BASE_PATH}/quiz/delivery`);
    }
    if (num < fields.length) {
      setButton(false);
      dispatch(updateStyleProfile(currentResponses));
      if (num === 14) {
        history.push(`${BASE_PATH}/payment/billing`);
        tracking.push({
          eventCategory: 'onboarding',
          eventAction: 'provided fit information',
        });
      } else {
        history.push(`${BASE_PATH}/quiz/${num + 1}?v${v}`);
      }
    } else {
      dispatch(updateStyleProfile(currentResponses));
      if (num === 14) {
        history.push(`${BASE_PATH}/payment/billing`);
        tracking.push({
          eventCategory: 'onboarding',
          eventAction: 'provided fit information',
        });
      } else {
        history.push(`${BASE_PATH}/quiz/confirmation`);
        tracking.push({
          eventCategory: 'onboarding',
          eventAction: 'provided fit information',
        });
      }
    }

    if (num >= 1) {
      setHideBackButton(false);
      return;
    }
  };

  let handleStepBack = () => {
    if (num === 14) {
      history.push(`${BASE_PATH}/quiz/delivery`);
    } else {
      history.push(`${BASE_PATH}/quiz/${num - 1}?v${v}`);
    }
  };

  const getStep = current && current.onboarding_section;
  const actualStep = stepMap && getStep && stepMap[getStep];

  if (current.ref === 'shuffle_title') {
    return (
      <>
        <div className='pl pr pb2'>
          <NavBar
            logo={EvereveTrendsendStackedLogoNavy}
            dashboard
            hasLogOut
            centerLogo
          />
        </div>
        <div className='pl pr pb2'>
          <ProgressBar
            color={`#B5DB9E`}
            currentStep={actualStep || 1}
            currentQuestion={num - 1}
            totalQuestions={fields.length}
            stepMap={stepMap}
          />
        </div>
        <StyleQuiz
          nextStep={num}
          currentStep={getStep}
          width={actualStep}
          currentQuestion={step - 1}
          totalQuestions={fields.length}
        />
      </>
    );
  } else if (current.ref === 'subscription_options_title') {
    return (
      <>
        <div className='pl pr pb2'>
          <NavBar
            logo={EvereveTrendsendStackedLogoNavy}
            dashboard
            hasLogOut
            centerLogo
          />
        </div>
        <div className='pl pr pb2'>
          <ProgressBar
            color={`#B5DB9E`}
            currentStep={actualStep || 1}
            currentQuestion={num - 1}
            totalQuestions={fields.length}
            stepMap={stepMap}
          />
        </div>
        <DeliveryMethod />
      </>
    );
  } else {
    return (
      <>
        <div className='pl pr pb2'>
          <NavBar
            logo={EvereveTrendsendStackedLogoNavy}
            dashboard
            hasLogOut
            centerLogo
          />
        </div>
        {!isDressingRoomToGo && (
          <div className='pl pr pb2'>
            <ProgressBar
              color={`#B5DB9E`}
              currentStep={actualStep || 1}
              currentQuestion={num - 1}
              totalQuestions={fields.length}
              stepMap={stepMap}
            />
          </div>
        )}
        <div className={classnames(style.Quiz)}>

          <div className='group group--slim mt2 mb6'>
            {current && current.type !== `statement` && (
              <Fragment>
                <SectionHeader
                  title={current.title}
                  description={
                    current.properties && current.properties.description
                  }
                  className='newHeader'
                  style={{marginBottom: '-1.8rem'}}
                />
                {current.attachment && current.attachment.href && (
                  <img
                    className='db mla mra mt2 mb2'
                    width='300px'
                    height='auto'
                    src={current.attachment.href}
                    alt={current.title}
                  />
                )}
              </Fragment>
            )}

            {isLoading || _.isEmpty(getField) ? (
              <Loading />
            ) : (
              <Fragment>
                <Grid columns={12} gap='12px'>
                  {getField.map((item) => {
                    if (item.ref === "subscription_type" && !responses['subscription_type']) {
                      dispatch(updateResponse('subscription_type', 'Every Month'));
                    }

                    const isCarousel =
                      current && _.includes(current.title, `$carousel`);
                    const Question = getQuestionSelector(
                      item.ref === "designs_disliked_colors"
                        ? `designs_disliked_colors`
                        : isCarousel
                          ? `picture_choice_carousel`
                          : item.type
                    );

                    return (
                      <Fragment key={item.id}>
                        {current.properties &&
                          current.properties.fields &&
                          item.type !== 'short_text' &&
                          item.type !== 'dropdown' &&
                          item.type !== 'statement' &&
                          item.ref !== 'designs_no_jewelry' &&
                          item.title !== ' ' && item.title !== '...' && (
                            <Cell width={12}>
                              <h3 className={`mt ${item.ref !== 'top_bra_band' && item.ref !== 'top_bra_cup' ? 'mb fs-18' : 'mb0 fs-15'}`}>
                                {item.title.includes("AVOID") ? (
                                  <>
                                    {item.title.split("AVOID")[0]}
                                    <strong style={{fontWeight: 'bold'}}>AVOID</strong>
                                    {item.title.split("AVOID")[1]}
                                  </>
                                ) : (
                                  item.title
                                )}
                              </h3>
                            </Cell>
                          )}

                        <Question
                          label={item.title}
                          choices={item.properties && item.properties.choices}
                          placeholder={
                            item.id === `nYZtNuKKsChw`
                              ? `Tell us more...`
                              : item.properties && item.properties.description
                          }
                          getValue={val}
                          initialValue={responses[item.ref]}
                          kind={item.type}
                          title={
                            item.title.includes("AVOID") ? (
                              <>
                                {item.title.split("AVOID")[0]}
                                <strong style={{fontWeight: 'bold'}}>AVOID</strong>
                                {item.title.split("AVOID")[1]}
                              </>
                            ) : (
                              item.title
                            )
                          }
                          form={{}}
                          multiSelect={
                            item.properties &&
                            item.properties.allow_multiple_selection
                          }
                          questionRef={item.ref}
                          questionId={item.id}
                          questionTitle={item.title}
                          supersized={
                            item.properties && item.properties.supersized
                          }
                          columnWidth={item.id === 'nQuZvHgJqLn7' ? 12 : 6}
                          doubleWidth={item.ref === `favorites_brands` && true}
                          deselectable
                        />
                      </Fragment>
                    );
                  })}
                </Grid>

                <div
                  className={classnames(
                    'mt3 df fdc',
                  )}
                >
                  <Button
                    handleClick={handleStep}
                    handleClickBack={handleStepBack}
                    variant="Tertiary"
                    isDisabled={!button && !_.isEmpty(requiredRefs)}
                    content={`NEXT`}
                    fullWidth
                    hasBackButton
                    hideBackButton={hideBackButton}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Quiz;

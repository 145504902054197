import React from 'react';

const Checkbox = ({ id, checked, onChange, label }) => (
  <div className="checkbox-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
    <div
      style={{
        width: '20px',
        height: '20px',
        border: '2px solid #003B5C',
        borderRadius: '5px',
        backgroundColor: '#F0EEEB',
        marginRight: '8px',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={onChange}
    >
      {checked && (
        <svg
          viewBox="0 0 24 24"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '16px',
            height: '16px',
            fill: '#003B5C',
          }}
        >
          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
        </svg>
      )}
    </div>
    <input
      type="checkbox"
      id={id}
      checked={checked}
      onChange={onChange}
      style={{ display: 'none' }}
    />
    <label htmlFor={id} style={{
      fontFamily: "proxima-nova, sans-serif",
      color: "#003B5C",
      fontSize: "18px",
      cursor: 'pointer',
    }}>{label}</label>
  </div>
);

export default Checkbox;
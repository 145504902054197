import * as yup from "yup";
import valid from 'card-validator'; 

const validStates = [
  'AA', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
  'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
  'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA',
  'WV', 'WI', 'WY'
];

const addressSchema = {
  line_1: yup.string().required("Required"),
  line_2: yup.string().nullable(),
  city: yup.string().required("Required"),
  state: yup.string().oneOf(validStates, 'Invalid state').required('State is required'),
  postal_code: yup.string()
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, 'Please enter a valid zip code. Must be 5 or 9 digits.')
    .max(10, 'ZIP code must not be more than 9 digits and an optional dash.')
    .label('ZIP Code')
    .required("Required"),
  phone: yup.string()
    .matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/, 'Please enter a valid Cell Phone Number')
    .required("Required")
};

const phoneValidation = yup.string()
  .matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/, 'Please enter a valid Cell Phone Number')
  .required("Required");

export const combinedSchema = yup.object().shape({
  shipping_line_1: addressSchema.line_1,
  shipping_line_2: addressSchema.line_2,
  shipping_city: addressSchema.city,
  shipping_state: addressSchema.state,
  shipping_postal_code: addressSchema.postal_code,
  shipping_phone: phoneValidation,
  billing_same_as_shipping: yup.boolean(),
  billing_name: yup.string().required("Required"),
  billing_credit_card_number: yup.string()
    .label('Card Number')
    .min(15, 'Please enter a valid card number')
    .test('test-CC-number',
    'Credit Card number is invalid',
     value => valid.number(value).isValid)
    .required("Required"),
  billing_expiration: yup.string().typeError('Not a valid expiration date. Example: MM/YY')
    .max(5, 'Not a valid expiration date. Example: MM/YY')
    .matches(
      /([0-9]{2})\/([0-9]{2})/,
      'Not a valid expiration date. Example: MM/YY'
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid expiration date.',
      expirationDate => {
        if (!expirationDate) {
          return false
        }

        const today = new Date()
        const monthToday = today.getMonth() + 1
        const yearToday = today
          .getFullYear()
          .toString()
          .substr(-2)

        const [expMonth, expYear] = expirationDate.split('/')

        if (Number(expYear) < Number(yearToday)) {
          return false
        } else if (
          Number(expMonth) < monthToday &&
          Number(expYear) <= Number(yearToday)
        ) {
          return false
        }

        return true
      }
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid Expiration Month',
      expirationDate => {
        if (!expirationDate) {
          return false
        }

        const [expMonth] = expirationDate.split('/')

        if (Number(expMonth) > 12) {
          return false
        }

        return true
      }
    )
    .required('Expiration date is required'),
  billing_cvv: yup.string().required("Required"),
  billing_street_address1: yup.string().when('billing_same_as_shipping', {
    is: false,
    then: yup.string().required("Required"),
    otherwise: yup.string()
  }),
  billing_street_address2: yup.string().nullable(),
  billing_city: yup.string().when('billing_same_as_shipping', {
    is: false,
    then: yup.string().required("Required"),
    otherwise: yup.string()
  }),
  billing_state: yup.string().when('billing_same_as_shipping', {
    is: false,
    then: yup.string().oneOf(validStates, 'Invalid state').required('State is required'),
    otherwise: yup.string()
  }),
  billing_zip_code: yup.string().when('billing_same_as_shipping', {
    is: false,
    then: yup.string()
      .matches(/^[0-9]{5}(-[0-9]{4})?$/, 'Please enter a valid zip code. Must be 5 or 9 digits.')
      .max(10, 'ZIP code must not be more than 9 digits and an optional dash.')
      .label('ZIP Code')
      .required("Required"),
    otherwise: yup.string()
  }),
  billing_phone: phoneValidation
});

export const combinedInitialValues = {
  shipping_line_1: '',
  shipping_line_2: '',
  shipping_city: '',
  shipping_state: '',
  shipping_postal_code: '',
  shipping_phone: '',
  billing_same_as_shipping: false,
  billing_name: '',
  billing_credit_card_number: '',
  billing_expiration: '',
  billing_cvv: '',
  billing_street_address1: '',
  billing_street_address2: '',
  billing_city: '',
  billing_state: '',
  billing_zip_code: '',
  billing_phone: '',
  coupon: 'Hello25'
}
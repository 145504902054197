import React, { useState, Fragment, useEffect } from 'react';
import './../CustomizeOrder/CustomOrder.Module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentOrder,
  boxRequestedWithStyleProfile,
  updateOrder,
} from './../store/Actions/OrderAction';
import {
  fetchStyleProfile,
  updateStyleProfile,
  updateResponse
} from '../store/Actions/QuestionAction';
import {
  editShippingAddress,
  fetchStoreLocations,
  fetchShippingAddress,
  fetchBillingInfo,
  fetchHolidays
} from '../store/Actions/CustomerAction';
import { DayPickerSingleDateController } from 'react-dates';
import history from '../History';
import moment from 'moment';
import _ from 'lodash';
import { getQuestionSelector } from 'QuestionSelector';
import FormInput from 'FormInput';
import ThemeBoxItems from '../ThemeBox/ThemeBoxItems';
import ConfirmPayment from './../ConfirmPayment';
import { hasCardExpired, isExpiringWithinNext14Days, isExpiringWithinNext45Days, isWithin14Days } from '../utils/cardExpirationUtils';

// components
import Header from '../Dashboard/Header';
import SubHeader from './../CustomizeOrder/SubHeader';
import TextArea from '../TextArea';
import NavBar from 'NavBar';
import Footer from 'Footer';
import { Grid, Cell } from 'styled-css-grid';

// images
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';

import { isDayBlockedForPickup } from "./../utils/dayPicker";

const color = {
  color: '#003b5c',
};

function CustomizeDRTGOrder({ match }) {
  const dispatch = useDispatch();
  const isPlaceOrder = match.params.step === `new`;
  const order = useSelector((state) => _.get(state, 'orders.currentOrder', {}));
  const customer = useSelector((state) => _.get(state, 'customer.data', {}));
  const customerPhone = useSelector((state) =>
    _.get(state, 'customer.data.shipping_addresses[0].phone')
  );

  const holidays = useSelector((state) =>
    _.get(state, 'holidays', {})
  );

  const themeboxes = useSelector((state) =>
    _.get(state, 'themeboxes.themeboxes', {})
  );

  const isLoadingCustomer = useSelector((state) =>
    _.get(state, 'customer.isLoading')
  );

  const celerantId = useSelector((state) =>
    _.get(state, 'customer.data.celerant_id', {})
  );

  const isAbandonedAccount = _.isEmpty(celerantId);

  const billingInfo = useSelector((state) =>
    _.get(state, 'customer.billingInfo.card_info', {})
  );

  const hasBillingInfo = !_.isEmpty(billingInfo);

  const responses = useSelector((state) => _.get(state, 'questions.responses', {}));
  const isEmptyResponses = _.isEmpty(responses);

  const [customerCellNumber, setCustomerCellNumber] = useState('');
  const [pickupStoreId, setPickupStoreId] = useState();
  const [button, setButton] = useState(true);
  const [hasHeight, setHasHeight] = useState(false);
  const [hasTopSize, setHasTopSize] = useState(false);
  const [hasBraSize, setHasBraSize] = useState(false);
  const [hasTopFitIssues, setHasTopFitIssues] = useState(false);
  const [hasBottomSize, setHasBottomSize] = useState(false);
  const [hasBottomFitIssues, setHasBottomFitIssues] = useState(false);
  const [hasDenimQuestions, setHasDenimQuestions] = useState(false);
  const [hasDenimRise, setHasDenimRise] = useState(false);
  const [hasDenimDistress, setHasDenimDistress] = useState(false);
  const [hasDenimSpend, setHasDenimSpend] = useState(false);
  const [hasShoeSize, setHasShoeSize] = useState(false);
  const [hasDenimStyle, setHasDenimStyle] = useState(false);
  
  let pickupDate = localStorage.getItem('shipByDate');

  const expDate = customer.cc_expiration || '';
  const shipByDate = new Date(order.ship_by_date);
  const formattedShipByDate = !isNaN(shipByDate) && shipByDate.toISOString().split('T')[0];
  
  const cardExpired = hasCardExpired(expDate);
  const ccExp14Days = isExpiringWithinNext14Days(expDate);
  const ccExp14DaysCompareShipByDate = formattedShipByDate && isWithin14Days(formattedShipByDate, expDate);
  const cardExpiringSoon = isExpiringWithinNext45Days(expDate);

  const contact = [
    {
      id: 0,
      type: 'text',
      name: 'shipping_phone',
      label: ' Cell Phone Number',
      placeholder: 'Cell Phone Number',
      columnWidth: 12,
      mask: '999-999-9999',
      autocomplete: 'tel-country-code',
    },
  ];

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  let storeLocations = useSelector(
    (state) => {
      if (!state.questions || !state.questions.data || !state.questions.data.fields) {
        return [];
      }

      const shuffleTitle = state.questions.data.fields.find(f => f.ref === 'shuffle_title');
      if (!shuffleTitle || !shuffleTitle.properties || !shuffleTitle.properties.fields) {
        return [];
      }

      const matchWithStylist = shuffleTitle.properties.fields.find(f => f.ref === 'match_with_stylist_2');
      if (!matchWithStylist || !matchWithStylist.properties || !matchWithStylist.properties.choices) {
        return [];
      }

      return matchWithStylist.properties.choices;
    }
  );

  storeLocations = storeLocations.map(location => ({
    ...location,
    label: location.label
  }));

  const storeLocationsWithIds = useSelector((state) =>
    _.orderBy(
      _.get(state, 'customer.storeLocations', []),
      (s) => s.address_state
    )
  );

  let heightQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['height_group'], f.ref))
        .flatten()
        .value()
  );

  let topSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'top_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .find((f) => f.ref === 'top_size')
        .value()
  );
  
  let braSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'top_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .filter((f) => f.ref === 'top_bra_band' || f.ref === 'top_bra_cup')
        .value()
  );

  let topFitIssuesQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'top_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .find((f) => f.ref === 'top_fit_issues')
        .value()
  );

  let bottomSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'bottom_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .find((f) => f.ref === 'bottom_size')
        .value()
  );

  let bottomFitIssuesQuestion = useSelector((state) => {
  
    const bottomTitle = state.questions.data.fields.find(f => f.ref === 'bottom_title');
    if (!bottomTitle || !bottomTitle.properties || !bottomTitle.properties.fields) {
      return null;
    }
  
    return bottomTitle.properties.fields.find(f => f.ref === 'bottom_fit') || null;
  });

  const denimRiseQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_rise') || null;
  });

  const denimDistressQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_distress') || null;
  });

  const denimQuestions = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    const brandQuestions = denimGroup.properties.fields.filter(f => f.ref.startsWith('brand'));
    const sizeQuestions = denimGroup.properties.fields.filter(f => f.ref.startsWith('size'));

    return { brandQuestions, sizeQuestions };
  });

  const denimSpendQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_spend') || null;
  });

  let shoeSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'shoe_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .find((f) => f.ref === 'shoe_size')
        .value()
  );
  
  const isBillingInfoComplete = () => {
    const isComplete = (
      billingInfo &&
      billingInfo.credit_card_number &&
      billingInfo.expiration
    );
    return isComplete;
  };

  const denimStyleQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_style') || null;
  });

  useEffect(() => {
    dispatch(fetchShippingAddress());
    dispatch(fetchStoreLocations({ customer_pick_up: true }));
    dispatch(fetchBillingInfo());
    dispatch(fetchStyleProfile());
    dispatch(fetchHolidays());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmptyResponses) {
      responses.height_feet && responses.height_inches && setHasHeight(true);
      responses.top_size && setHasTopSize(true);
      responses.top_bra_band && responses.top_bra_cup && setHasBraSize(true);
      responses.top_fit_issues && setHasTopFitIssues(true);
      responses.bottom_size && setHasBottomSize(true);
      responses.bottom_fit && setHasBottomFitIssues(true);
      responses.brand_1 && setHasDenimQuestions(true);
      responses.denim_rise && setHasDenimRise(true);
      responses.denim_distress && setHasDenimDistress(true);
      responses.denim_spend && setHasDenimSpend(true);
      responses.shoe_size && setHasShoeSize(true);
      responses.denim_style && setHasDenimStyle(true);
    }
  }, [isEmptyResponses]);

  useEffect(() => {
    !order.ship_by_date &&
      pickupDate &&
      dispatch(updateCurrentOrder({ ship_by_date: moment(pickupDate).toDate() }));

    const cleanedPhoneNumber = (customerCellNumber || '').replace(/\D/g, '');
    const isPhoneValid = cleanedPhoneNumber.length === 10;

    const isFormComplete = 
      isBillingInfoComplete() &&
      responses.preferred_store &&
      responses.preferred_store !== 'No Preference' &&
      order.ship_by_date &&
      customerCellNumber !== '' &&
      isPhoneValid &&
      responses.height_feet &&
      responses.height_inches &&
      responses.top_size &&
      responses.top_bra_band &&
      responses.top_bra_cup &&
      responses.brand_1 &&
      responses.denim_rise &&
      responses.denim_distress &&
      responses.denim_style &&
      responses.denim_spend &&
      hasBillingInfo &&
      !ccExp14DaysCompareShipByDate &&
      !ccExp14Days;

    setButton(!isFormComplete);

  }, [
    responses,
    order.ship_by_date,
    customerCellNumber,
    hasBillingInfo,
    ccExp14Days,
    ccExp14DaysCompareShipByDate,
    billingInfo,
    pickupDate
  ]);

  useEffect(() => {
    if (customerPhone && customerPhone !== '') {
      // Format the phone number
      const formattedPhone = formatPhoneNumber(customerPhone);
      setCustomerCellNumber(formattedPhone);
    }
  }, [customerPhone]);

  // Add this function to format the phone number
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  };

  // Modify this function to format the number as it's being input
  let setCustomerCellPhoneNumber = (value, ref) => {
    const formattedValue = formatPhoneNumber(value);
    setCustomerCellNumber(formattedValue);
  };

  function handleOnDemandCustomerMsgChange(on_demand_customer_msg) {
    dispatch(updateCurrentOrder({ on_demand_customer_msg }));
  }

  function handleCustomDeliveryDateChange(ship_by_date) {
    localStorage.setItem('shipByDate', ship_by_date);
    dispatch(updateCurrentOrder({ ship_by_date: ship_by_date.toDate() }));
  }

  let val = (value, ref) => {
    dispatch(updateResponse(ref, value));
  };

  let getStoreIdFromName = (value) => {
    let storeWithId = _.find(storeLocationsWithIds, (s) => {
      return s.name === value.split(' - ')[1];
    }) || {};
    return storeWithId.id;
  };

  let getStoreNameFromId = (id) => {
    let storeWithId =
      _.find(storeLocationsWithIds, (s) => {
        return s.id === id;
      }) || {};
    return `${storeWithId.address_state}, ${storeWithId.address_city} - ${storeWithId.name}`;
  };

  let setPickupStore = (value, ref) => {
    dispatch(updateResponse(ref, value));
    let assigned_location_id = getStoreIdFromName(value);
    if (assigned_location_id) {
      setPickupStoreId(assigned_location_id);
      dispatch(updateCurrentOrder({ assigned_location_id }));
    }
  };

  useEffect(() => {
    if (order && order.assigned_location_id) {
      setPickupStoreId(order.assigned_location_id);
      const storeName = getStoreNameFromId(order.assigned_location_id);
      if (storeName) {
        dispatch(updateResponse('preferred_store', storeName));
      }
    }
  }, [order, dispatch]);

  function submitForm(e) {
    
    const newOrder = {
      ...order,
      customer_pick_up: true,
      assigned_location_id: pickupStoreId,
    };

    responses && dispatch(updateStyleProfile(responses));

    dispatch(fetchStoreLocations({ customer_pick_up: true }));

    dispatch(
      order.id
        ? updateOrder(responses, order)
        : boxRequestedWithStyleProfile(responses, newOrder)
    );
    localStorage.removeItem('selectedSubscription');
    localStorage.removeItem('selectedDate');
    localStorage.removeItem('shipByDate');
  }

  return (
    <div>
      <NavBar
        text='white'
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
      />
      <div className='customOrderContainer group group--slim mt2 mb2'>
        <Header
          header={order.id ? 'Trendsend Pickup' : 'Pick up a Trendsend'}
          subheader={
            isAbandonedAccount
              ? `We can't wait to style you! Let's complete your account and create your first order.`
              : `Pick up stylist-selected pieces from your local store and take it all home for two days to try before you buy.`
          }
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <Grid columns={12} gap='16px' justifyContent='center'>
          <Cell width={12}>
            <SubHeader
              header='EVEREVE Store'
              subheader='Where would you like to pick up your Trendsend?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            {(() => {
              const Question = getQuestionSelector('dropdown');
              
              const filteredStores = storeLocations.filter(item => 
                item.label !== 'No Preference' &&
                item.label !== 'IL, Evanston - Sherman Plaza' &&
                item.label !== 'MN, Eden Prairie - Windsor Plaza' &&
                item.label !== 'MN, St. Louis Park - The Shops at West End' &&
                item.label !== 'MN, Woodbury - Woodbury Lakes' &&
                item.label !== 'OH, Mason - Deerfield Towne Center' &&
                item.label !== 'MO, Kansas City - Zona Rosa' &&
                item.label !== 'OH, Beavercreek - The Greene' &&
                item.label !== 'OH, Columbus - Easton Town Center' &&
                item.label !== 'MI, Ann Arbor - Arbor Hills' &&
                item.label !== 'CO, Lakewood - Belmar' &&
                item.label !== 'MO, Chesterfield - Chesterfield Mall' &&
                item.label !== 'CT, South Windsor - The Promenade Shops at Evergreen Walk' &&
                item.label !== 'IL, Chicago - Lincoln Park' &&
                item.label !== 'WI, Wauwatosa - Mayfair Mall' &&
                item.label !== 'KS, Overland Park - Oak Park Mall' &&
                item.label !== 'WA, Lynnwood - Alderwood Mall' &&
                item.label !== 'OH, Beachwood - Beachwood Place' &&
                item.label !== 'MI, Novi - Twelve Oaks' &&
                item.label !== 'AR, Little Rock - Chenal' &&
                item.label !== 'TX, Plano - Willow Bend Center' &&
                item.label !== 'IL, Northbrook - Northbrook Court' &&
                item.label !== 'UT, Salt Lake City - City Creek Center' &&
                item.label !== 'MN, Bloomington - Mall of America' &&
                item.label !== 'MI, Lansing - Eastwood Towne Center' &&
                item.label !== 'NJ, Bridgewater - Bridgewater Commons' &&
                item.label !== 'CT, Norwalk - The SoNo Collection' &&
                item.label !== 'AZ, Scottsdale - Kierland Commons' &&
                item.label !== 'NJ, East Rutherford - American Dream' &&
                item.label !== 'TX, Fort Worth - Shops at Clearfork' &&
                item.label !== 'WI, Brookfield - The Corners of Brookfield' &&
                item.label !== 'MO, Kansas City - Country Club Plaza' &&
                item.label !== 'AZ, Chandler - Chandler Fashion Center'
              );

              if (_.isEmpty(filteredStores) || (!isPlaceOrder && !order.id)) return null;

              const initialStoreValue = order.assigned_location_id 
                ? getStoreNameFromId(order.assigned_location_id)
                : responses.preferred_store || '';

              return (
                <Question
                  label="Select a store"
                  choices={filteredStores}
                  getValue={(value, ref) => {
                    setPickupStore(value, ref);
                  }}
                  initialValue={initialStoreValue}
                  kind="dropdown"
                  title="Select a store"
                  form={{}}
                  multiSelect={false}
                  questionRef="preferred_store"
                  questionId="preferred_store"
                />
              );
            })()}
          </Cell>
        </Grid>

        <SubHeader
          header='Pickup Date'
          subheader={
            <>
              <span>When would you like to pick up your Trendsend?</span>
              <br />
              <span>Your order must be returned two days after pickup.</span>
            </>
          }
          color={color}
          TextAlignCenter
          requiredQuestion
        />

        <DayPickerSingleDateController
          onDateChange={(d) => {
            handleCustomDeliveryDateChange(d === order.ship_by_date ? null : d);
          }}
          date={
            (order.ship_by_date && moment(order.ship_by_date)) ||
            (pickupDate && moment(pickupDate))
          }
          isDayBlocked={(d) => {
            return isDayBlockedForPickup(d, holidays);
          }}
          numberOfMonths={1}
          daySize={40}
          focused={true}
          initialVisibleMonth={() => {
            return order.ship_by_date ? moment(order.ship_by_date) : moment();
          }}
          hideKeyboardShortcutsPanel={true}
        />

        <SubHeader
          header='When in doubt…pick a theme!'
          subheader='Sometimes you just need an idea to get the style process started. Enter theme boxes. Based on what you’re up to each month…and completely customizable.'
          color={color}
          TextAlignCenter
        />

        <ThemeBoxItems order={order} themeboxes={themeboxes} />

        {(isAbandonedAccount || !hasHeight) && heightQuestion && (
          <>
            <SubHeader
              header='Tell us about yourself'
              color={color}
              TextAlignCenter
              requiredQuestion
            />
            <Grid columns={12} gap='16px' justifyContent='center'>
              {heightQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);
                        return (
                          <Question
                            key={item.id}
                            label={item.title}
                            choices={item.properties && item.properties.choices}
                            placeholder={item.properties && item.properties.description}
                            getValue={val}
                            initialValue={responses[item.ref]}
                            rotationDegree={responses.full_body_image_rotation}
                            kind={item.type}
                            title={item.title}
                            form={{}}
                            multiSelect={item.properties && item.properties.allow_multiple_selection}
                            questionRef={item.ref}
                            questionId={item.id}
                            supersized={item.properties && item.properties.supersized}
                            parentComponentName={'LongForm'}
                            match={match}
                          />
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasTopSize) && topSizeQuestion && (
          <>
            <SubHeader
              header={`What is your top size?`}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {topSizeQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(topSizeQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={topSizeQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[topSizeQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={topSizeQuestion.type}
                        title={topSizeQuestion.title}
                        form={{}}
                        multiSelect={topSizeQuestion.properties.allow_multiple_selection}
                        questionRef={topSizeQuestion.ref}
                        questionId={topSizeQuestion.id}
                        supersized={topSizeQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBraSize) && braSizeQuestion && (
          <>
            <SubHeader
              header='What is your bra size?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {braSizeQuestion.length === 2 && (
                <Fragment key="bra-size-group">
                  <Cell width={12}>
                    <h3 className='h4 ff-body pt mb0'>BAND</h3>
                  </Cell>
                  {(() => {
                    const bandQuestion = braSizeQuestion.find(q => q.ref === 'top_bra_band');
                    const Question = getQuestionSelector(bandQuestion.type);
                    return (
                      <Question
                        label={bandQuestion.title}
                        choices={bandQuestion.properties && bandQuestion.properties.choices}
                        placeholder={bandQuestion.properties && bandQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[bandQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={bandQuestion.type}
                        title={bandQuestion.title}
                        form={{}}
                        multiSelect={bandQuestion.properties && bandQuestion.properties.allow_multiple_selection}
                        questionRef={bandQuestion.ref}
                        questionId={bandQuestion.id}
                        supersized={bandQuestion.properties && bandQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    );
                  })()}
                  
                  <Cell width={12}>
                    <h3 className='h4 ff-body pt mb0'>CUP</h3>
                  </Cell>
                  {(() => {
                    const cupQuestion = braSizeQuestion.find(q => q.ref === 'top_bra_cup');
                    const Question = getQuestionSelector(cupQuestion.type);
                    return (
                      <Question
                        label={cupQuestion.title}
                        choices={cupQuestion.properties && cupQuestion.properties.choices}
                        placeholder={cupQuestion.properties && cupQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[cupQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={cupQuestion.type}
                        title={cupQuestion.title}
                        form={{}}
                        multiSelect={cupQuestion.properties && cupQuestion.properties.allow_multiple_selection}
                        questionRef={cupQuestion.ref}
                        questionId={cupQuestion.id}
                        supersized={cupQuestion.properties && cupQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    );
                  })()}
                </Fragment>
              )}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasTopFitIssues) && topFitIssuesQuestion && (
          <>
            <SubHeader
              header={topFitIssuesQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {topFitIssuesQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(topFitIssuesQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={12}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={topFitIssuesQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[topFitIssuesQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={topFitIssuesQuestion.type}
                        title={topFitIssuesQuestion.title}
                        form={{}}
                        multiSelect={topFitIssuesQuestion.properties.allow_multiple_selection}
                        questionRef={topFitIssuesQuestion.ref}
                        questionId={topFitIssuesQuestion.id}
                        supersized={topFitIssuesQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBottomSize) && bottomSizeQuestion && (
          <>
            <SubHeader
              header={`What is your denim/bottom size?`}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {bottomSizeQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(bottomSizeQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={bottomSizeQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[bottomSizeQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={bottomSizeQuestion.type}
                        title={bottomSizeQuestion.title}
                        form={{}}
                        multiSelect={bottomSizeQuestion.properties.allow_multiple_selection}
                        questionRef={bottomSizeQuestion.ref}
                        questionId={bottomSizeQuestion.id}
                        supersized={bottomSizeQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBottomFitIssues) && bottomFitIssuesQuestion && (
          <>
            <SubHeader
              header={`Common Bottom Fit Issues (select all that apply)`}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {bottomFitIssuesQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(bottomFitIssuesQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={12}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={bottomFitIssuesQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[bottomFitIssuesQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={bottomFitIssuesQuestion.type}
                        title={bottomFitIssuesQuestion.title}
                        form={{}}
                        multiSelect={bottomFitIssuesQuestion.properties.allow_multiple_selection}
                        questionRef={bottomFitIssuesQuestion.ref}
                        questionId={bottomFitIssuesQuestion.id}
                        supersized={bottomFitIssuesQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}
        

        {(isAbandonedAccount || !hasDenimQuestions) && denimQuestions && (
          <>
            <SubHeader
              header='What is your favorite pair of denim?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimQuestions.brandQuestions.slice(0, 1).map((brandQuestion, index) => {
                const sizeQuestion = denimQuestions.sizeQuestions[index];
                const BrandQuestionComponent = getQuestionSelector(brandQuestion.type);
                const SizeQuestionComponent = getQuestionSelector(sizeQuestion.type);

                return (
                  <Fragment key={brandQuestion.id}>
                    <Cell width={6}>
                      <BrandQuestionComponent
                        label={brandQuestion.title}
                        choices={brandQuestion.properties.choices}
                        placeholder={brandQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[brandQuestion.ref]}
                        kind={brandQuestion.type}
                        title={brandQuestion.title}
                        form={{}}
                        multiSelect={brandQuestion.properties.allow_multiple_selection}
                        questionRef={brandQuestion.ref}
                        questionId={brandQuestion.id}
                        supersized={brandQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                    <Cell width={2}>
                      <SizeQuestionComponent
                        label={sizeQuestion.title}
                        choices={sizeQuestion.properties.choices}
                        placeholder={sizeQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[sizeQuestion.ref]}
                        kind={sizeQuestion.type}
                        title={sizeQuestion.title}
                        form={{}}
                        multiSelect={sizeQuestion.properties.allow_multiple_selection}
                        questionRef={sizeQuestion.ref}
                        questionId={sizeQuestion.id}
                        supersized={sizeQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimRise) && denimRiseQuestion && (
          <>
            <SubHeader
              header={denimRiseQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimRiseQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimRiseQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimRiseQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimRiseQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimRiseQuestion.type}
                        title={denimRiseQuestion.title}
                        form={{}}
                        multiSelect={denimRiseQuestion.properties.allow_multiple_selection}
                        questionRef={denimRiseQuestion.ref}
                        questionId={denimRiseQuestion.id}
                        supersized={denimRiseQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimDistress) && denimDistressQuestion && (
          <>
            <SubHeader
              header={denimDistressQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimDistressQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimDistressQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimDistressQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimDistressQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimDistressQuestion.type}
                        title={denimDistressQuestion.title}
                        form={{}}
                        multiSelect={denimDistressQuestion.properties.allow_multiple_selection}
                        questionRef={denimDistressQuestion.ref}
                        questionId={denimDistressQuestion.id}
                        supersized={denimDistressQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}
        
        {(isAbandonedAccount || !hasDenimStyle) && denimStyleQuestion && (
          <>
            <SubHeader
              header={denimStyleQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimStyleQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimStyleQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimStyleQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimStyleQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimStyleQuestion.type}
                        title={denimStyleQuestion.title}
                        form={{}}
                        multiSelect={denimStyleQuestion.properties.allow_multiple_selection}
                        questionRef={denimStyleQuestion.ref}
                        questionId={denimStyleQuestion.id}
                        supersized={denimStyleQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimSpend) && denimSpendQuestion && (
          <>
            <SubHeader
              header={denimSpendQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimSpendQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimSpendQuestion.type);
                
                return (
                  <Fragment key={item.id}>
                    <Cell width={12}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimSpendQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimSpendQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimSpendQuestion.type}
                        title={''}
                        form={{}}
                        multiSelect={denimSpendQuestion.properties.allow_multiple_selection}
                        questionRef={denimSpendQuestion.ref}
                        questionId={denimSpendQuestion.id}
                        supersized={denimSpendQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasShoeSize) && shoeSizeQuestion && (
          <>
            <SubHeader
              header={shoeSizeQuestion.title}
              color={color}
              TextAlignCenter
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {shoeSizeQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(shoeSizeQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={shoeSizeQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[shoeSizeQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={shoeSizeQuestion.type}
                        title={shoeSizeQuestion.title}
                        form={{}}
                        multiSelect={shoeSizeQuestion.properties.allow_multiple_selection}
                        questionRef={shoeSizeQuestion.ref}
                        questionId={shoeSizeQuestion.id}
                        supersized={shoeSizeQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}
        
        <SubHeader
          header='Note to Stylist'
          subheader={
            <>
              <span>
                Include details about your lifestyle or an event that you’re
                shopping for.
              </span>
              <br />
              <span>
                If there is a certain piece you’d like in your order, request it
                here.
              </span>
            </>
          }
          color={color}
          TextAlignCenter
        />

        <TextArea
          getValue={handleOnDemandCustomerMsgChange}
          placeholder='Tell us more...'
          initialValue={order.on_demand_customer_msg}
        />

        <ConfirmPayment
          path={history.location.pathname}
          isAbandonedAccount={isAbandonedAccount}
          cardExpired={cardExpired}
          cardExpiringSoon={cardExpiringSoon}
        />

        <SubHeader
          header='Cell Phone Number'
          subheader='Please confirm your cell phone number. We’ll text you when your items are ready to be picked up.'
          color={color}
          TextAlignCenter
          requiredQuestion
        />

        {!isLoadingCustomer && (
          <Grid columns={12}>
            <Cell width={12}>
              {contact.map(
                ({ label, placeholder, columnWidth, type, name, mask }) => {
                  return (
                    <FormInput
                      name={name}
                      type={type}
                      label={label}
                      mask={mask}
                      placeholder={placeholder}
                      form={{}}
                      initialValue={customerCellNumber}
                      getValue={setCustomerCellPhoneNumber}
                    />
                  );
                }
              )}
            </Cell>
          </Grid>
        )}

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType='submit'
          buttonCopy={isPlaceOrder ? `Place Order` : `Save Order`}
          footerStep={submitForm}
          disableButton={button}
          requiredQuestion
          paymentButton={isPlaceOrder}
        />
      </div>
    </div>
  );
}

export default CustomizeDRTGOrder;

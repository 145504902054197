import React, { useState, useEffect } from 'react';
import style from './ColorSelection.module.scss';
import { Cell } from 'styled-css-grid';
import classNames from 'classnames';

const ColorSelection = ({ choices, questionRef, initialValue, getValue }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [colorList, setColorList] = useState(initialValue || []);
  const maxColors = 12;

  const specificColorList = ['#FEFFFE', '#EBEBEB', '#FEFB41', '#FFFBB9', '#CDE8B5'];

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const handleAddToList = () => {
    if (selectedColor && !colorList.includes(selectedColor)) {
      const updatedList = [...colorList, selectedColor];
      setColorList(updatedList);
      getValue(updatedList, questionRef);
    }
  };

  const handleRemoveColor = (color) => {
    const updatedList = colorList.filter((c) => c !== color);
    setColorList(updatedList);
    getValue(updatedList, questionRef);
  };

  return (
    <Cell width={12}>
      {/* Mobile Layout */}
      <div className={`${style.MobileLayout} ${style.ColorSelectionContainer}`}>
        <div className={style.SelectedColorSection}>
          {selectedColor && (
            <>
              <div
                className={style.SelectedColorPreview}
                style={{
                  backgroundColor: selectedColor.split(',')[0],
                }}
              />
              <div className={style.SelectedColorInfo}>
                <p>{selectedColor.split(',')[1]}</p>
                <p>({selectedColor.split(',')[0]})</p>
              </div>
            </>
          )}
        </div>
        <div className={style.ColorPalette}>
          {choices.map((choice) => {
            const [colorHex, colorName] = choice.label.split(',');
            const isInList = colorList.includes(choice.label);

            return (
              <div
                key={choice.label}
                className={`${style.ColorSwatch} ${selectedColor === choice.label ? style.ColorSelected : ''}`}
                style={{
                  backgroundColor: colorHex,
                }}
                onClick={() => handleColorClick(choice.label)}
              >
                
                {isInList && (
                  <div className={`${style.CheckmarkOverlay} ${
                    specificColorList.includes(colorHex) ? style.DarkCheckmark : style.LightCheckmark
                  }`} />
                )}
              </div>
            );
          })}
        </div>
        <button
          className={style.AddToListButton}
          onClick={handleAddToList}
          disabled={!selectedColor || colorList.length >= maxColors}
        >
          + Add to my list
        </button>
      </div>

      {/* Desktop Layout */}
      <div className={`${style.DesktopLayout} ${style.ColorSelectionContainer}`}>
        <div className={style.SelectedColorSection}>
          {selectedColor && (
            <>
              <div
                className={style.SelectedColorPreview}
                style={{
                  backgroundColor: selectedColor.split(',')[0],
                }}
              />
              <p>{selectedColor.split(',')[1]}</p>
              <p>({selectedColor.split(',')[0]})</p>
            </>
          )}
          <button
            className={classNames(style.AddToListButton, selectedColor && style.Selected)}
            onClick={handleAddToList}
            disabled={!selectedColor || colorList.length >= maxColors}
          >
            + Add to my list
          </button>
        </div>
        <div className={style.ColorPalette}>
          {choices.map((choice) => {
            const [colorHex, colorName] = choice.label.split(',');
            const isInList = colorList.includes(choice.label);

            return (
              <div
                key={choice.label}
                className={`${style.ColorSwatch} ${selectedColor === choice.label ? style.ColorSelected : ''}`}
                style={{
                  backgroundColor: colorHex,
                }}
                onClick={() => handleColorClick(choice.label)}
              >
                {isInList && (
                  <div className={`${style.CheckmarkOverlay} ${
                    specificColorList.includes(colorHex) ? style.DarkCheckmark : style.LightCheckmark
                  }`} />
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Common Section: My List */}
      <div className={style.MyColorList}>
        <h4 className={style.MyColorListTitle}>My list (Not for me)</h4>
        <div className={style.ColorList}>
          {colorList.map((color, index) => {
            const [colorHex, colorName] = color.split(',');
            return (
              <div key={index} className={style.ColorListItem}>
                <div className={style.ColorCircle} style={{ backgroundColor: colorHex }} />
                <div className={style.ColorListItemInfo}>
                  <span>{colorName}</span>
                  <span>({colorHex})</span>
                  <button className={style.RemoveButton} onClick={() => handleRemoveColor(color)}>
                    Remove
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Cell>
  );
};

export default ColorSelection;


import api from "../../api";
import { updateCustomerData, subscriptionUpdated, loadCustomerData } from "./CustomerAction";
import { serverErrors } from "./../../utils/errors";
import history from "./../../History";
import _ from "lodash";
import axios from 'axios';

export const FETCHING_STYLE_PROFILE              = 'FETCHING_STYLE_PROFILE';
export const FETCHING_STYLE_PROFILE_SUCCESS      = 'FETCHING_STYLE_PROFILE_SUCCESS';
export const FETCHING_STYLE_PROFILE_ERROR        = 'FETCHING_STYLE_PROFILE_ERROR';
export const UPDATING_STYLE_PROFILE              = 'UPDATING_STYLE_PROFILE';
export const UPDATING_STYLE_PROFILE_SUCCESS      = 'UPDATING_STYLE_PROFILE_SUCCESS';
export const FETCHING_STYLE_SHUFFLE              = 'FETCHING_STYLE_SHUFFLE';
export const FETCHING_STYLE_SHUFFLE_SUCCESS      = 'FETCHING_STYLE_SHUFFLE_SUCCESS';
export const UPDATING_STYLE_PROFILE_ERROR        = 'UPDATING_STYLE_PROFILE_ERROR';
export const SHUFFLE_COMPLETE                    = 'SHUFFLE_COMPLETE';

const SUBSCRIPTION_ENUM = {
  "Every Month": "subscription_30_day",
  "Every Other Month": "subscription_60_day",
  "Every Quarter": "subscription_90_day",
  "On Demand": "subscription_on_demand"
}


export function fetchStyleProfile(version) {
  return function (dispatch, getState) {
    dispatch({ type: FETCHING_STYLE_PROFILE });
    dispatch({ type: FETCHING_STYLE_SHUFFLE });
    api.currentStyleProfile(version).then(
      (resp) => {
        resp.data.data.responses.anything_else_notes && Object.assign(resp.data.data.responses, {
          anything_else_notes:
            resp.data.data.responses.anything_else_notes.split('DateTime')[0],
        });
        dispatch({
          type: FETCHING_STYLE_PROFILE_SUCCESS,
          data: resp.data.data,
          id: resp.data.id,
          name: resp.data.name,
        });
        dispatch({
          type: FETCHING_STYLE_SHUFFLE_SUCCESS,
          data: resp.data.data,
          id: resp.data.id,
        });
      },
      (errorResp) => {
        dispatch({
          type: FETCHING_STYLE_PROFILE_ERROR,
          errors: serverErrors(errorResp),
        });
      }
    );
  };
}

export function updateStyleProfile(responses = {}) {
  return function(dispatch, getState) {
    let questions = getState().questions;
     
    dispatch({type: UPDATING_STYLE_PROFILE});
    api.updateStyleProfile(questions.id, _.merge(questions.responses, responses))
      .then(resp => {
      Object.assign(resp.data.data.responses, {anything_else_notes: resp.data.data.responses.anything_else_notes.split('DateTime')[0]})
      dispatch({type: UPDATING_STYLE_PROFILE_SUCCESS, responses: resp.data.data.responses, stats: resp.data.data.stats});
        
      }, errorResp => {
        dispatch({type: UPDATING_STYLE_PROFILE_ERROR, errors: serverErrors(errorResp)});
      });
   };
}


export function updateResponse(ref, answer) {
   return function(dispatch, getState) {
     let questions = getState().questions;
     if (_.includes(_.get(answer, 'type'),"image")) {
        api.createImageUploadUrl(questions.id, answer.name)
          .then(resp => {
            axios
              .put(resp.data.upload_url, answer, {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
              })
              .then(uploadResp => {
                dispatch({type: UPDATING_STYLE_PROFILE_SUCCESS, responses: {[ref]: resp.data.download_url} });
              }, errorResp => {
                dispatch({type: UPDATING_STYLE_PROFILE_ERROR, errors: serverErrors(errorResp)});
              });
          }, errorResp => {
            dispatch({type: UPDATING_STYLE_PROFILE_ERROR, errors: serverErrors(errorResp)});
          });
     } else {
      switch(ref) {
        case 'preferred_store':
          let preferredStore = _.find(getState().customer.storeLocations, (location) => {
            if (answer === 'No Preference') {
              return location.name === answer;
            } else {
              let answerPart = answer.split(' - ')[1];
              return location.name === answerPart;
            }
          });
          
          if (!_.isEmpty(preferredStore)) {
           dispatch(updateCustomerData({assigned_location_id: preferredStore.id}));
          }
          break;
        case 'match_with_stylist':
          dispatch(updateCustomerData({preferred_stylist: answer}));
          break;
        case 'subscription_type':
          dispatch(subscriptionUpdated(SUBSCRIPTION_ENUM[answer]));
          break;
      };
      dispatch({type: UPDATING_STYLE_PROFILE_SUCCESS, responses: {[ref]: answer} });
    };
  };
};

export const SET_AS_PICKUP = 'SET_AS_PICKUP';
export const SET_AS_PICKUP_SUCCESS = 'SET_AS_PICKUP_SUCCESS';

export function setAsPickup(redirectTo) {
  return function (dispatch, getState) {
    dispatch({type: SET_AS_PICKUP});
    api
      .updateCustomer({ dressing_room_to_go_signup: true }, redirectTo)
      .then(() => {
        if (localStorage.getItem('drtgSignup')) {
          _.each(JSON.parse(localStorage.getItem('drtgSignup')), (val, key) =>
            dispatch(updateResponse(key, val))
          );
          localStorage.removeItem('drtgSignup');
        }
        dispatch(loadCustomerData());
        dispatch({type: SET_AS_PICKUP_SUCCESS});

        if (redirectTo) history.push(redirectTo); 
      });
  };
};

export const SET_BLOG_INTEREST = 'SET_BLOG_INTEREST';

export function setBlogInterest(blogSlug) {
  return {
    type: SET_BLOG_INTEREST,
    payload: blogSlug,
  };
}

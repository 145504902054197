import TextArea from "TextArea";
import FormInput from "FormInput";
import FileUpload from "FileUpload";
import OptionCarousel from "OptionCarousel";
import Radio from "Radio";
import Statement from "Statement";
import Dropdown from "Dropdown";
import Birthday from "Birthday";
import ColorSelection from "ColorSelection";

const questionTypes = {
  'dropdown': Dropdown,
  'short_text': FormInput,
  'number': FormInput,
  'date': Birthday,
  'long_text': TextArea,
  'picture_choice': Radio,
  'multiple_choice': Radio,
  'yes_or_no': Radio,
  'picture_choice_carousel': OptionCarousel,
  'file_upload': FileUpload,
  'statement': Statement,
  'group': Statement,
  'designs_disliked_colors': ColorSelection,
};

export const getQuestionSelector = (type) => questionTypes[type];

import React, { useState, useEffect, Fragment } from "react";
import { getQuestionSelector } from "QuestionSelector";
import style from "../Quiz/Quiz.module.scss";
import Footer from "Footer";
import Header from '../Dashboard/Header';
import { Grid, Cell } from "styled-css-grid";
import history from "../History";
import classnames from "classnames";
import _ from "lodash";
import NavBar from "NavBar";
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import logOut from "../images/icon_logout.svg";
import profile from "../images/icon_account.svg";
import { useDispatch, useSelector, connect } from "react-redux";
import { BASE_PATH } from "./../Config";

import {
  fetchStyleProfile,
  updateStyleProfile,
  updateResponse,
} from "../store/Actions/QuestionAction";

const lightColors = ['#D8D8D8', '#FFFFFF', '#FEFFFE', '#FFFBB9'];

function Longform({ match, location }) {
  const dispatch = useDispatch();
  let fields = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => {
          if (match.params.section === 'size_fit') {
            return f.section === 'size_fit' || f.section === 'shipping';
          }
          return f.section === match.params.section;
        })
        .filter((f) => f.type !== "statement")
        .value()
  );

  fields = _.chain(fields)
    .map((f) =>
      f.type === "group"
        ? [f].concat(
            _.map(f.properties.fields, (f) => _.merge(f, { child: true }))
          )
        : f
    )
    .flatten()
    .value();

  const responses = useSelector((state) => state.questions.responses);
  const isLoading = useSelector((state) => state.questions.isLoading);
  const [button, setButton] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);

  const setHeaderTitle = () => {
    let header;
    switch (location.pathname) {
      case '/members/edit-profile/about':
        return header = 'About Me';
      case '/members/edit-profile/size_fit':
        return header = 'Size & Fit';
      case '/members/edit-profile/style':
        return header = 'Style';
      case '/members/edit-profile/shipping':
        return header = 'Additional Details';
      default:
        return header = '';
    }
  }

  useEffect(() => {
    dispatch(fetchStyleProfile('master'));
    if (!isLoading) {
    }
  }, [dispatch, fields.length]);

  let handleSave = () => {
    dispatch(updateStyleProfile(responses));
    history.push(`${BASE_PATH}/styleprofile`);
  };

  let val = (value, ref) => {
    if (value && value.length > 1) {
      setButton(true);
    } else {
      setButton(false);
    }
    dispatch(updateResponse(ref, value));
  };

  useEffect(() => {
    if (responses && responses.designs_disliked_colors) {
      const selectedHexCodes = responses.designs_disliked_colors.map(color => color.split(',')[0]);
      setSelectedColors(selectedHexCodes);
    }
  }, [responses]);

  const renderColorPicker = (item) => {
    const colors = item.properties.choices.map(choice => {
      const [hex, label] = choice.label.split(',');
      return { hex, label };
    });

    const handleColorClick = (hex) => {
      let updatedColors;
      if (selectedColors.includes(hex)) {
        updatedColors = selectedColors.filter(c => c !== hex);
      } else {
        updatedColors = [...selectedColors, hex].slice(0, 12);
      }
      setSelectedColors(updatedColors);
      
      const updatedColorsWithLabels = updatedColors.map(hex => {
        const colorObj = colors.find(c => c.hex === hex);
        return `${colorObj.hex},${colorObj.label}`;
      });
      val(updatedColorsWithLabels, item.ref);
    };

    const getCheckmarkColor = (hex) => {
      return lightColors.includes(hex) ? '#003b5c' : 'white';
    };

    return (
      <div className={style.colorPickerContainer}>
        {colors.map(({ hex, label }) => (
          <div key={hex} className={style.colorOption}>
            <div
              className={`${style.colorCircle} ${selectedColors.includes(hex) ? style.selected : ''}`}
              style={{ backgroundColor: hex }}
              onClick={() => handleColorClick(hex)}
            >
              <div 
                className={style.checkmark}
                style={{ borderColor: getCheckmarkColor(hex) }}
              ></div>
            </div>
            <span className={style.colorLabel}>{label}</span>
          </div>
        ))}
      </div>
    );
  };

  const styles = {
    brandSizePair: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '-16px',
      width: '100%',
    },
    brandContainer: {
      width: '68%',
    },
    sizeContainer: {
      width: '28%',
    },
  };

  const renderBrandSizePair = (brandField, sizeField) => {
    const Question = getQuestionSelector('dropdown');
    return (
      <div style={styles.brandSizePair}>
        <div style={styles.brandContainer}>
          <Question
            label="Brand"
            choices={brandField.properties && brandField.properties.choices}
            getValue={val}
            initialValue={responses[brandField.ref]}
            kind={brandField.type}
            title={brandField.title}
            questionRef={brandField.ref}
            questionId={brandField.id}
            parentComponentName={'LongForm'}
            match={match}
            deselectable
          />
        </div>
        <div style={styles.sizeContainer}>
          <Question
            label="Size"
            choices={sizeField.properties && sizeField.properties.choices}
            getValue={val}
            initialValue={responses[sizeField.ref]}
            kind={sizeField.type}
            title={sizeField.title}
            questionRef={sizeField.ref}
            questionId={sizeField.id}
            parentComponentName={'LongForm'}
            match={match}
            deselectable
          />
        </div>
      </div>
    );
  };

  const renderTitle = (title) => {
    if (title.includes("AVOID")) {
      const parts = title.split("AVOID");
      return (
        <>
          {parts[0]}<strong style={{fontWeight: 'bold'}}>AVOID</strong>{parts[1]}
        </>
      );
    }
    return title;
  };

  const getTitleForRef = (ref, defaultTitle) => {
    switch (ref) {
      case 'top_size':
        return "What is your top size?";
      case 'bottom_size':
        return "What is your denim/bottom size?";
      case 'denim_title':
        return "What is your favorite pair of denim?";
      case 'designs_no_jewelry':
        return "Are you interested in jewelry?";
      default:
        return defaultTitle;
    }
  };

  return (
    <div className={classnames(style.Quiz)}>
      <NavBar
        text="white"
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
      />
      <div className="group group--slim mt2 mb2 longform">
        <Fragment>
          {setHeaderTitle() !== '' && (
            <Header header={setHeaderTitle()} TextAlignCenter FontSizeLarge />
          )}
          <Grid columns={12} gap="16px" justifyContent="center">
            {fields.map((item, index) => {
              if (['height_group', 'favorites_title', 'build_title', 'chit_chat_title', 
                   'top_title', 'match_with_stylist_2', 'subscription_type_group', 
                   'shuffle_style_shuffle', 'shuffle_title', 'designs_title', 
                   'visual_content_title', 'areas_title', 'subscription_options_subscription_preference', 'subscription_options_subscription_frequency', 'subscription_options_title', 'shoe_title', 'month', 'date', 'year', 'title', 'bottom_title', 'dresses_title'].includes(item.ref)) {
                return null;
              }
              
              const Question = getQuestionSelector(item.type);

              const formattedTitle = getTitleForRef(item.ref, item.title.replace(`$text`, ``));

              if (item.ref === 'brand_1' || item.ref === 'brand_2' || item.ref === 'brand_3') {
                const brandNumber = item.ref.split('_')[1];
                const sizeField = fields.find(f => f.ref === `size_${brandNumber}`);
                if (sizeField) {
                  return (
                    <Cell width={8} key={item.id}>
                      {renderBrandSizePair(item, sizeField)}
                    </Cell>
                  );
                }
              }

              if (item.ref.startsWith('size_')) {
                return null;
              }

              return (
                <Fragment key={item.id}>
                  {!item.child && item.id !== `nYZtNuKKsChw` && (
                    <Cell width={12}>
                      <label className="mt2 mb fs-18">
                        {renderTitle(formattedTitle)}
                      </label>
                      {item.properties &&
                        item.properties.description &&
                        item.properties.description !==
                          'Select as many as apply' &&
                        item.type !== `long_text` && (
                          <h3 className="tac">{renderTitle(item.properties.description)}</h3>
                        )}
                    </Cell>
                  )}

                  {item.child &&
                    item.type !== `short_text` &&
                    item.type !== `dropdown` &&
                    item.type !== `statement` && (
                      <Cell width={12}>
                        <label className="mt2 mb fs-18">
                          {renderTitle(formattedTitle)}
                        </label>
                      </Cell>
                    )}

                  {item.attachment && item.attachment.href && (
                    <Cell width={12}>
                      <img
                        className="db mla mra mt2 mb2"
                        width="300px"
                        height="auto"
                        src={item.attachment.href}
                        alt={item.title}
                      />
                    </Cell>
                  )}

                  {item.type !== `group` && item.id !== `nYZtNuKKsChw` && item.ref !== 'denim_favorite_title' && (
                    item.ref === 'designs_disliked_colors' ? (
                      <Cell width={12}>
                        {renderColorPicker(item)}
                      </Cell>
                    ) : (
                      <Question
                        label={renderTitle(formattedTitle)}
                        choices={item.properties && item.properties.choices}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={item.type}
                        title={renderTitle(formattedTitle)}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={item.properties && item.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                        doubleWidth={item.ref === 'favorites_brands'}
                        deselectable={item.type !== 'multiple_choice'}
                      />
                    )
                  )}
                </Fragment>
              );
            })}

            <Cell width={12}>
              <Footer
                buttonType="submit"
                footerStep={handleSave}
                buttonCopy={`Save Changes`}
              />
            </Cell>
          </Grid>
        </Fragment>
      </div>
    </div>
  );
}

export default Longform;

import React, { Fragment } from "react";
import style from "./Callout.module.scss";
import classnames from "classnames";
import Button from "Button";
import history from "../History";
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import _ from "lodash";

function Callout({ image, imageMobile, title, button, description = [], layout = "50-50", hasBackButton = false, welcome }) {
  const isMobile = window.innerWidth <= 768;
  const backgroundImage = isMobile && imageMobile ? imageMobile : image;
  const renderContent = () => (
    <div className={classnames(welcome ? style.CalloutWelcomeWrapper : style.CalloutWrapper)}>
      <h1 className={classnames(welcome ? style.CalloutWelcomeTitle : style.CalloutTitle)}>
        {Array.isArray(title) ? (
          isMobile ? (
            <span className={style.mt2}>{title[0]}</span>
          ) : (
            title.map((line, index) => (
              <Fragment key={index}>
                {line}
                {index < title.length - 1 && <br />}
              </Fragment>
            ))
          )
        ) : (
          title
        )}
      </h1>
      <div className={style.CalloutDescription}>
        {description.map((desc, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: desc }} />
        ))}
      </div>
      <div className="mt">
        {button && <Button
          handleClick={() => {
            if (_.includes(button.path, "http")) {
              window.location = button.path;
            } else {
              history.push(button.path);
            }
          }}
          variant="Tertiary"
          content={button.title}
          fullWidth
        />}
        {(button && hasBackButton) && (
          <Button
            handleClick={() => {
              history.goBack();
            }}
            variant="Text"
            content="BACK"
            fullWidth
          />
        )}
      </div>
    </div>
  );

  return (
    <div className={classnames(style.Callout, style[layout])}>
      <div className={style.NavBar}>
        <img
          src={EvereveTrendsendStackedLogoNavy}
          alt="Trendsend"
          className={style.logo}
        />
      </div>
      
      {layout === "full-screen" ? (
        <div
          className={style.CalloutFullScreen}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          {renderContent()}
        </div>
      ) : (
        <>
          <div
            className={style.CalloutImage}
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          {renderContent()}
        </>
      )}
    </div>
  );
}

export default Callout;

import * as yup from 'yup';

export const registerSchema = yup.object().shape({
  first_name: yup
    .string()
    .min(2, 'Min. 2 characters.')
    .matches(/[a-z]+/i, 'Please enter a valid first name.')
    .required('Required'),
  last_name: yup
    .string()
    .min(2, 'Min. 2 characters.')
    .matches(/[a-z]+/i, 'Please enter a valid last name.')
    .required('Required'),
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Required'),
  confirmEmail: yup
    .string()
    .email('Email address does not match.')
    .test('match', 'Email address does not match.', function (value) {
      return this.parent.email === value;
    })
    .required('Required'),
  registration_postal_code: yup.string()
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, 'Please enter a valid zip code. Must be 5 or 9 digits.')
    .max(10, 'ZIP code must not be more than 9 digits and an optional dash.')
    .label('ZIP Code')
    .required("Required"),
  password: yup
    .string()
    .label('Password')
    .min(8, `Min. 8 characters.`)
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])/,
      "Password must contain at least one uppercase letter, one number, and one special character.")
    .required('Required'),
});

export const registerInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  registration_postal_code: '',
  password: '',
};

import React, { useState, useEffect, Fragment, useRef } from 'react';
import './CustomOrder.Module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentOrder,
  boxRequestedWithStyleProfile,
  updateOrder,
} from './../store/Actions/OrderAction';
import {
  fetchStyleProfile,
  updateResponse,
} from '../store/Actions/QuestionAction';
import {
  fetchBillingInfo,
  fetchHolidays,
  loadCustomerDataWithoutOrder,
} from './../store/Actions/CustomerAction';
import { DayPickerSingleDateController } from 'react-dates';
import history from '../History';
import moment from 'moment';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { getQuestionSelector } from 'QuestionSelector';
import { hasCardExpired,isExpiringWithinNext14Days, isExpiringWithinNext45Days, isWithin14Days } from '../utils/cardExpirationUtils';

// components
import Header from '../Dashboard/Header';
import SubHeader from './SubHeader';
import TextArea from '../TextArea';
import NavBar from 'NavBar';
import { Grid, Cell } from 'styled-css-grid';
import Footer from 'Footer';
import ThemeBoxItems from '../ThemeBox/ThemeBoxItems';
import SubscriptionSelector from './../ManageSubscription/SubscriptionSelector';
import ConfirmShippingAddress from './../ConfirmShippingAddress';
import ConfirmPayment from './../ConfirmPayment';

// images
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';

import { isDayBlockedForShipment } from "./../utils/dayPicker";

const color = {
  color: '#003b5c',
};

function CustomizeOrder({ match, location }) {
  const dispatch = useDispatch();

  const returnToFormScroll = useRef(null);

  const isPlaceOrder = match.params.step === `order`;

  const order = useSelector((state) =>
    _.get(state, 'orders.currentOrder', {})
  );

  const themeboxes = useSelector((state) =>
    _.get(state, 'themeboxes.themeboxes', {})
  );

  let selectedThemebox = useSelector((state) =>
    _.get(state, 'themeboxes.selectedThemebox')
  );

  const celerantId = useSelector((state) =>
    _.get(state, 'customer.data.celerant_id', {})
  );

  const isAbandonedAccount = _.isEmpty(celerantId);

  const billingInfo = useSelector((state) =>
    _.get(state, 'customer.billingInfo.card_info', {})
  );

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  const isShipping = !_.isEmpty(shippingAddress);

  const isLoadingOrders = useSelector((state) =>
    _.get(state, 'orders.isLoading')
  );

  const isLoadingCustomers = useSelector((state) =>
    _.get(state, 'customer.isLoading')
  );

  const isLoadingHolidays = useSelector((state) =>
    _.get(state, 'holidays.isLoading')
  );

  const expectedDeliveryDate = useSelector((state) =>
    _.get(state, 'orders.currentOrder.expected_delivery_date')
  );

  const isOnDemand = useSelector((state) =>
    _.chain(state)
      .get('customer.data.subscription_type')
      .isEqual('subscription_on_demand')
      .value()
  );

  const responses = useSelector((state) =>
    _.get(state, 'questions.responses', {})
  );

  const isEmptyResponses = _.isEmpty(responses);

  const [button, setButton] = useState(true);
  const [hasHeight, setHasHeight] = useState(false);
  const [hasTopSize, setHasTopSize] = useState(false);
  const [hasBraSize, setHasBraSize] = useState(false);
  const [hasTopFitIssues, setHasTopFitIssues] = useState(false);
  const [hasBottomSize, setHasBottomSize] = useState(false);
  const [hasBottomFitIssues, setHasBottomFitIssues] = useState(false);
  const [hasDenimQuestions, setHasDenimQuestions] = useState(false);
  const [hasDenimRise, setHasDenimRise] = useState(false);
  const [hasDenimDistress, setHasDenimDistress] = useState(false);
  const [hasDenimSpend, setHasDenimSpend] = useState(false);
  const [date, setDate] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [hasDenimStyle, setHasDenimStyle] = useState(false);

  if (!selectedThemebox) {
    selectedThemebox = localStorage.getItem('selectedThemebox');
  }

  let selectedDate = localStorage.getItem('selectedDate');

  let selectedSubscription = localStorage.getItem('selectedSubscription');

  let heightQuestion = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['height_group'], f.ref))
        .flatten()
        .value()
  );

  let topSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'top_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .find((f) => f.ref === 'top_size')
        .value()
  );
  
  let braSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'top_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .filter((f) => f.ref === 'top_bra_band' || f.ref === 'top_bra_cup')
        .value()
  );

  let topFitIssuesQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'top_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .find((f) => f.ref === 'top_fit_issues')
        .value()
  );

  let bottomSizeQuestion = useSelector(
    (state) =>
      state.questions &&
      state.questions.data &&
      state.questions.data.fields &&
      _.chain(state.questions.data.fields)
        .filter((f) => f.ref === 'bottom_title')
        .flatMap((f) => f.properties && f.properties.fields)
        .find((f) => f.ref === 'bottom_size')
        .value()
  );

  let bottomFitIssuesQuestion = useSelector((state) => {
  
    const bottomTitle = state.questions.data.fields.find(f => f.ref === 'bottom_title');
    if (!bottomTitle || !bottomTitle.properties || !bottomTitle.properties.fields) {
      return null;
    }
  
    return bottomTitle.properties.fields.find(f => f.ref === 'bottom_fit') || null;
  });

  const denimRiseQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_rise') || null;
  });

  const denimDistressQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_distress') || null;
  });

  const holidays = useSelector((state) =>
    _.get(state, 'holidays', {})
  );

  const denimQuestions = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    const brandQuestions = denimGroup.properties.fields.filter(f => f.ref.startsWith('brand'));
    const sizeQuestions = denimGroup.properties.fields.filter(f => f.ref.startsWith('size'));

    return { brandQuestions, sizeQuestions };
  });

  const denimSpendQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_spend') || null;
  });

  const denimStyleQuestion = useSelector((state) => {
    if (!state.questions || !state.questions.data || !state.questions.data.fields) {
      return null;
    }

    const denimGroup = state.questions.data.fields.find(f => f.ref === 'denim_title');
    if (!denimGroup || !denimGroup.properties || !denimGroup.properties.fields) {
      return null;
    }

    return denimGroup.properties.fields.find(f => f.ref === 'denim_style') || null;
  });

  const scrollToArea = () => {
    returnToFormScroll.current.scrollIntoView({ behavior: 'smooth' });
  };

  const customer = useSelector(reduxState => reduxState.customer.data);

  const expDate = customer.cc_expiration || '';
  const shipByDate = new Date(order.ship_by_date);
  const formattedShipByDate = !isNaN(shipByDate) && shipByDate.toISOString().split('T')[0];
  
  const cardExpired = hasCardExpired(expDate);
  const ccExp14Days = isExpiringWithinNext14Days(expDate);
  const ccExp14DaysCompareShipByDate = formattedShipByDate && isWithin14Days(formattedShipByDate, expDate);
  const cardExpiringSoon = isExpiringWithinNext45Days(expDate);

  const isShippingAddressComplete = () => {
    const isComplete = (
      shippingAddress &&
      shippingAddress.shipping_line_1 &&
      shippingAddress.shipping_city &&
      shippingAddress.shipping_state &&
      shippingAddress.shipping_postal_code
    );
    return isComplete;
  };

  const isBillingInfoComplete = () => {
    const isComplete = (
      billingInfo &&
      billingInfo.credit_card_number &&
      billingInfo.expiration
    );
    return isComplete;
  };

  const areAllQuestionsAnswered = () => {
    const allAnswered = 
      !!responses.height_feet &&
      !!responses.height_inches &&
      !!responses.top_size &&
      !!responses.top_bra_band &&
      !!responses.top_bra_cup &&
      !!responses.bottom_size &&
      !!responses.brand_1 &&
      !!responses.denim_rise &&
      !!responses.denim_distress &&
      !!responses.denim_spend &&
      !!responses.denim_style;
      
    return allAnswered;
  };

  useEffect(() => {
    dispatch(fetchHolidays());
    dispatch(fetchBillingInfo());
    dispatch(fetchStyleProfile());
    dispatch(loadCustomerDataWithoutOrder());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmptyResponses) {
      responses.height_feet && responses.height_inches && setHasHeight(true);
      responses.top_size && setHasTopSize(true);
      responses.top_bra_band && responses.top_bra_cup && setHasBraSize(true);
      responses.top_fit_issues && setHasTopFitIssues(true);
      responses.bottom_size && setHasBottomSize(true);
      responses.bottom_fit && setHasBottomFitIssues(true);
      responses.brand_1 && setHasDenimQuestions(true);
      responses.denim_rise && setHasDenimRise(true);
      responses.denim_distress && setHasDenimDistress(true);
      responses.denim_spend && setHasDenimSpend(true);
      responses.denim_style && setHasDenimStyle(true);
    }
  }, [isEmptyResponses]);

  useEffect(() => {
    const shouldEnableButton = 
      isBillingInfoComplete() &&
      isShippingAddressComplete() &&
      areAllQuestionsAnswered() &&
      !ccExp14DaysCompareShipByDate &&
      !ccExp14Days;

    setButton(!shouldEnableButton);
  }, [
    shippingAddress,
    billingInfo,
    responses,
    ccExp14Days,
    ccExp14DaysCompareShipByDate,
  ]);

  useEffect(() => {
    location.state &&
      location.state.previousPath === '/members/change/payment' &&
      scrollToArea();

    !order.themebox &&
      selectedThemebox &&
      dispatch(updateCurrentOrder({ themebox: selectedThemebox }));

    !order.ship_by_date &&
      selectedDate &&
      dispatch(updateCurrentOrder({ ship_by_date: selectedDate }));

    if (!order.subscription_type) {
      if (selectedSubscription) {
        dispatch(updateCurrentOrder({ subscription_type: selectedSubscription }));
      } else if (isAbandonedAccount) {
        dispatch(updateCurrentOrder({ subscription_type: 'subscription_30_day' }));
      }
    }
      
  }, [dispatch, order.themebox, order.ship_by_date, order.subscription_type]);

  let val = (value, ref) => {
    dispatch(updateResponse(ref, value));
  };

  function handleOnDemandCustomerMsgChange(on_demand_customer_msg) {
    dispatch(updateCurrentOrder({ on_demand_customer_msg }));
  }

  function handleSubscriptionChange(subscription_type) {
    localStorage.setItem('selectedSubscription', subscription_type);
    dispatch(updateCurrentOrder({ subscription_type }));
  }

  function handleCustomDeliveryDateChange(selected_delivery_date) {
    setDate(moment(selected_delivery_date));
    localStorage.setItem('selectedDate', selected_delivery_date);
    dispatch(
      updateCurrentOrder({ ship_by_date: moment(selected_delivery_date) })
    );
  }

  function submitForm(e) {
    dispatch(
      updateCurrentOrder({ expected_delivery_date: order.ship_by_date })
    );

    dispatch(
      order.id
        ? updateOrder(responses, order)
        : boxRequestedWithStyleProfile(responses, order)
    );

    localStorage.removeItem('selectedThemebox');
    localStorage.removeItem('selectedSubscription');
    localStorage.removeItem('selectedDate');
  }

  return (
    <>
      <NavBar
        text='white'
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
      />

      <div className='customOrderContainer group group--slim mt2 mb2'>
        <Header
          header={
            order.id
              ? 'Customize your Trendsend Order'
              : 'Order a Trendsend Box'
          }
          subheader={
            isAbandonedAccount
              ? `We can't wait to style you! Let's complete your account and create your first order.`
              : ''
          }
          color={color}
          TextAlignCenter
          FontSizeLarge
        />

        <SubHeader
          header='Trendsend Delivery Date'
          subheader={
            !isPlaceOrder && expectedDeliveryDate && !order.customer_pick_up
              ? `Your current Trendsend's estimated delivery is ${moment(
                  expectedDeliveryDate
                ).format('MM/DD/YYYY')}`
              : `When do you want to receive your Trendsend? This is an estimate – we'll do our best!`
          }
          color={color}
          TextAlignCenter
        />

        {!isLoadingOrders && !isLoadingCustomers && !isLoadingHolidays && (
          <div style={{ marginTop: 30 }}>
            <DayPickerSingleDateController
              onDateChange={(d) => {
                handleCustomDeliveryDateChange(
                  d === order.ship_by_date ? null : d
                );
              }}
              date={
                selectedDate
                  ? moment(selectedDate)
                  : date
                  ? date
                  : order.expected_delivery_date &&
                    moment(order.expected_delivery_date)
              }
              isDayBlocked={(d) => {
                return isDayBlockedForShipment(d, holidays);
              }}
              numberOfMonths={isMobile ? 1 : 2}
              daySize={40}
              focused={true}
              initialVisibleMonth={() => {
                return order.ship_by_date
                  ? moment(order.ship_by_date)
                  : moment();
              }}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        )}

        {isOnDemand && !order.id && (
          <Fragment>
            <SubHeader
              header='Subscription Frequency'
              subheader='Select a frequency below to have Trendsend orders automatically scheduled. You can cancel anytime.'
              color={color}
              TextAlignCenter
            />
            <SubscriptionSelector
              value={order.subscription_type || 'subscription_30_day'}
              onSubscriptionSelect={handleSubscriptionChange}
              selectedSubscription={selectedSubscription}
            />
          </Fragment>
        )}

        <SubHeader
          header='When in doubt…pick a theme!'
          subheader="Sometimes you just need an idea to get the style process started. Enter theme boxes. Based on what you're up to each month…and completely customizable."
          color={color}
          TextAlignCenter
        />
        <ThemeBoxItems
          order={order}
          selectedThemebox={selectedThemebox}
          themeboxes={themeboxes}
        />

        {(isAbandonedAccount || !hasHeight) && heightQuestion && (
          <>
            <SubHeader
              header='Tell us about yourself'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {heightQuestion.map((item) => {
                return (
                  <Fragment key={item.id}>
                    {item.type == `group` &&
                      item.properties.fields.map((item) => {
                        const Question = getQuestionSelector(item.type);

                        return (
                          <Question
                            label={item.title}
                            choices={item.properties && item.properties.choices}
                            placeholder={
                              item.properties && item.properties.description
                            }
                            getValue={val}
                            initialValue={responses[item.ref]}
                            rotationDegree={responses.full_body_image_rotation}
                            kind={item.type}
                            title={item.title}
                            form={{}}
                            multiSelect={
                              item.properties &&
                              item.properties.allow_multiple_selection
                            }
                            questionRef={item.ref}
                            questionId={item.id}
                            supersized={
                              item.properties && item.properties.supersized
                            }
                            parentComponentName={'LongForm'}
                            match={match}
                          />
                        );
                      })}
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasTopSize) && topSizeQuestion && (
          <>
            <SubHeader
              header= {`What is your top size?`}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {topSizeQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(topSizeQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={topSizeQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[topSizeQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={topSizeQuestion.type}
                        title={topSizeQuestion.title}
                        form={{}}
                        multiSelect={topSizeQuestion.properties.allow_multiple_selection}
                        questionRef={topSizeQuestion.ref}
                        questionId={topSizeQuestion.id}
                        supersized={topSizeQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBraSize) && braSizeQuestion && (
          <>
            <SubHeader
              header='What is your bra size?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {braSizeQuestion.length === 2 && (
                <Fragment key="bra-size-group">
                  <Cell width={12}>
                    <h3 className='h4 ff-body pt mb0'>BAND</h3>
                  </Cell>
                  {(() => {
                    const bandQuestion = braSizeQuestion.find(q => q.ref === 'top_bra_band');
                    const Question = getQuestionSelector(bandQuestion.type);
                    return (
                      <Question
                        label={bandQuestion.title}
                        choices={bandQuestion.properties && bandQuestion.properties.choices}
                        placeholder={bandQuestion.properties && bandQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[bandQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={bandQuestion.type}
                        title={bandQuestion.title}
                        form={{}}
                        multiSelect={bandQuestion.properties && bandQuestion.properties.allow_multiple_selection}
                        questionRef={bandQuestion.ref}
                        questionId={bandQuestion.id}
                        supersized={bandQuestion.properties && bandQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    );
                  })()}
                  
                  <Cell width={12}>
                    <h3 className='h4 ff-body pt mb0'>CUP</h3>
                  </Cell>
                  {(() => {
                    const cupQuestion = braSizeQuestion.find(q => q.ref === 'top_bra_cup');
                    const Question = getQuestionSelector(cupQuestion.type);
                    return (
                      <Question
                        label={cupQuestion.title}
                        choices={cupQuestion.properties && cupQuestion.properties.choices}
                        placeholder={cupQuestion.properties && cupQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[cupQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={cupQuestion.type}
                        title={cupQuestion.title}
                        form={{}}
                        multiSelect={cupQuestion.properties && cupQuestion.properties.allow_multiple_selection}
                        questionRef={cupQuestion.ref}
                        questionId={cupQuestion.id}
                        supersized={cupQuestion.properties && cupQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    );
                  })()}
                </Fragment>
              )}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasTopFitIssues) && topFitIssuesQuestion && (
          <>
            <SubHeader
              header={'Common Top Fit Issues? (Select all that apply)'}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {topFitIssuesQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(topFitIssuesQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={12}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={topFitIssuesQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[topFitIssuesQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={topFitIssuesQuestion.type}
                        title={topFitIssuesQuestion.title}
                        form={{}}
                        multiSelect={topFitIssuesQuestion.properties.allow_multiple_selection}
                        questionRef={topFitIssuesQuestion.ref}
                        questionId={topFitIssuesQuestion.id}
                        supersized={topFitIssuesQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBottomSize) && bottomSizeQuestion && (
          <>
            <SubHeader
              header={`What is your denim/bottom size?`}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {bottomSizeQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(bottomSizeQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={bottomSizeQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[bottomSizeQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={bottomSizeQuestion.type}
                        title={bottomSizeQuestion.title}
                        form={{}}
                        multiSelect={bottomSizeQuestion.properties.allow_multiple_selection}
                        questionRef={bottomSizeQuestion.ref}
                        questionId={bottomSizeQuestion.id}
                        supersized={bottomSizeQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasBottomFitIssues) && bottomFitIssuesQuestion && (
          <>
            <SubHeader
              header={`Common Bottom Fit Issues (select all that apply)`}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {bottomFitIssuesQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(bottomFitIssuesQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={12}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={bottomFitIssuesQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[bottomFitIssuesQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={bottomFitIssuesQuestion.type}
                        title={bottomFitIssuesQuestion.title}
                        form={{}}
                        multiSelect={bottomFitIssuesQuestion.properties.allow_multiple_selection}
                        questionRef={bottomFitIssuesQuestion.ref}
                        questionId={bottomFitIssuesQuestion.id}
                        supersized={bottomFitIssuesQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}
        

        {(isAbandonedAccount || !hasDenimQuestions) && denimQuestions && (
          <>
            <SubHeader
              header='What is your favorite pair of denim?'
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {(() => {
                const brandQuestion = denimQuestions.brandQuestions[0]; // Only use the first brand question
                const sizeQuestion = denimQuestions.sizeQuestions[0]; // Only use the first size question
                const BrandQuestionComponent = getQuestionSelector(brandQuestion.type);
                const SizeQuestionComponent = getQuestionSelector(sizeQuestion.type);

                return (
                  <Fragment key={brandQuestion.id}>
                    <Cell width={6}>
                      <BrandQuestionComponent
                        label={brandQuestion.title}
                        choices={brandQuestion.properties.choices}
                        placeholder={brandQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[brandQuestion.ref]}
                        kind={brandQuestion.type}
                        title={brandQuestion.title}
                        form={{}}
                        multiSelect={brandQuestion.properties.allow_multiple_selection}
                        questionRef={brandQuestion.ref}
                        questionId={brandQuestion.id}
                        supersized={brandQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                    <Cell width={2}>
                      <SizeQuestionComponent
                        label={sizeQuestion.title}
                        choices={sizeQuestion.properties.choices}
                        placeholder={sizeQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[sizeQuestion.ref]}
                        kind={sizeQuestion.type}
                        title={sizeQuestion.title}
                        form={{}}
                        multiSelect={sizeQuestion.properties.allow_multiple_selection}
                        questionRef={sizeQuestion.ref}
                        questionId={sizeQuestion.id}
                        supersized={sizeQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })()}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimRise) && denimRiseQuestion && (
          <>
            <SubHeader
              header={denimRiseQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimRiseQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimRiseQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimRiseQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimRiseQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimRiseQuestion.type}
                        title={denimRiseQuestion.title}
                        form={{}}
                        multiSelect={denimRiseQuestion.properties.allow_multiple_selection}
                        questionRef={denimRiseQuestion.ref}
                        questionId={denimRiseQuestion.id}
                        supersized={denimRiseQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimDistress) && denimDistressQuestion && (
          <>
            <SubHeader
              header={denimDistressQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimDistressQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimDistressQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimDistressQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimDistressQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimDistressQuestion.type}
                        title={denimDistressQuestion.title}
                        form={{}}
                        multiSelect={denimDistressQuestion.properties.allow_multiple_selection}
                        questionRef={denimDistressQuestion.ref}
                        questionId={denimDistressQuestion.id}
                        supersized={denimDistressQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimStyle) && denimStyleQuestion && (
          <>
            <SubHeader
              header={denimStyleQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimStyleQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimStyleQuestion.type);

                return (
                  <Fragment key={item.id}>
                    <Cell width={4}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimStyleQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimStyleQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimStyleQuestion.type}
                        title={denimStyleQuestion.title}
                        form={{}}
                        multiSelect={denimStyleQuestion.properties.allow_multiple_selection}
                        questionRef={denimStyleQuestion.ref}
                        questionId={denimStyleQuestion.id}
                        supersized={denimStyleQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        {(isAbandonedAccount || !hasDenimSpend) && denimSpendQuestion && (
          <>
            <SubHeader
              header={denimSpendQuestion.title}
              color={color}
              TextAlignCenter
              requiredQuestion
            />

            <Grid columns={12} gap='16px' justifyContent='center'>
              {denimSpendQuestion.properties.choices.map((item) => {
                const Question = getQuestionSelector(denimSpendQuestion.type);
                
                return (
                  <Fragment key={item.id}>
                    <Cell width={12}>
                      <Question
                        label={item.label}
                        choices={[item]}
                        placeholder={denimSpendQuestion.properties.description}
                        getValue={val}
                        initialValue={responses[denimSpendQuestion.ref]}
                        rotationDegree={responses.full_body_image_rotation}
                        kind={denimSpendQuestion.type}
                        title={''}
                        form={{}}
                        multiSelect={denimSpendQuestion.properties.allow_multiple_selection}
                        questionRef={denimSpendQuestion.ref}
                        questionId={denimSpendQuestion.id}
                        supersized={denimSpendQuestion.properties.supersized}
                        parentComponentName={'LongForm'}
                        match={match}
                      />
                    </Cell>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}

        <SubHeader
          header='Note to Stylist'
          subheader={
            <>
              <span>
                Include details about your lifestyle or an event that you're
                shopping for.
              </span>
              <br />
              <span>
                If there is a certain piece you'd like in your order, request it
                here.
              </span>
            </>
          }
          color={color}
          TextAlignCenter
        />

        <TextArea
          getValue={handleOnDemandCustomerMsgChange}
          placeholder='Tell us more...'
          initialValue={order.on_demand_customer_msg}
        />

        <div ref={returnToFormScroll}></div>

        <ConfirmPayment
          path={history.location.pathname}
          isAbandonedAccount={isAbandonedAccount}
          cardExpired={cardExpired}
          cardExpiringSoon={cardExpiringSoon}
          ccExp14DaysCompareShipByDate={ccExp14DaysCompareShipByDate}
        />

        <ConfirmShippingAddress
          path={history.location.pathname}
          isAbandonedAccount={isAbandonedAccount}
          shippingAddress={shippingAddress}
          isShippingAddressComplete
        />

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType='submit'
          buttonCopy={isPlaceOrder ? `Send My Trendsend!` : `Save Changes`}
          footerStep={submitForm}
          disableButton={button}
          requiredQuestion
          paymentButton={isPlaceOrder}
        />
      </div>
    </>
  );
}

export default CustomizeOrder;

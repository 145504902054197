import React from 'react';
import style from '../Dashboard/Dashboard.module.scss';
import classnames from 'classnames';

function SubHeader(props) {
  return (
    <div
      style={props.color}
      className={classnames(
        style.DashboardSubHeader,
        props.TextAlignCenter && 'tac'
      )}
    >
      <h1>
        {props.header}
        {props.requiredQuestion && (
          <span className={style.requiredQuestion}>*</span>
        )}
      </h1>

      {props.errorMessage && <div className='c-error tac' style={{ paddingBottom: '1rem', margin: '0 auto', width: '100%', maxWidth: '31rem', lineHeight: '1.6' }}>
        * {props.errorMessage}
      </div>}

      {props.subheader && (
        <h2 className={props.fullWidth && style.fullWidth}>
          {props.subheader}
        </h2>
      )}
      {props.additionalSubheader && <div>{props.additionalSubheader}</div>}
    </div>
  );
}

export default SubHeader;

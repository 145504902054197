import React, { useState } from 'react';
import { Cell } from 'styled-css-grid';
import style from './Dropdown.module.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';

function Dropdown({
  getValue,
  initialValue,
  questionRef,
  questionTitle,
  choices,
  placeholder,
  title,
  columnWidth,
  align = 'horizontal'
}) {
  const [showInput, setShowInput] = useState(false);
  const customChoice = initialValue && !choices.some((choice) => choice.label === initialValue);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  function updateOption(e) {
    const selectedValue = e.target.value;
    getValue && getValue(selectedValue, questionRef);
  }

  const isDenimSection = questionRef.includes('jeans_best_fit');

  const determineWidth = () => {
    if (questionTitle === "Size") return isMobile ? 4 : 2;
    if (questionTitle === "Brand") return isMobile ? 8 : 6;

    if (isDenimSection) {
      return questionRef.includes('size') ? isMobile ? 4 : 3 : isMobile ? 8 : 9;
    }

    if (align === 'horizontal') {
      if (questionRef.includes('height')) {
        return isMobile ? 3 : 2;
      }
      if (questionRef.includes('birthday')) {
        return isMobile ? 4 : 2;
      }
      return columnWidth / 2;
    }

    return questionRef.includes('height') || questionRef.includes('birthday') ? 2 : columnWidth;
  };

  return (
    <Cell width={determineWidth()}>
      <div className={`${style.DropdownWrapper} ${isDenimSection ? style.DenimSection : ''}`}>
        <select
          className={classnames(style.Dropdown, { [style.DenimDropdown]: questionRef.includes('size_') || questionRef.includes('brand_') || questionRef.includes('birthday_'), [style.HeightDropdown]: questionRef.includes('height') })}
          onChange={updateOption}
          defaultValue={initialValue || ''}
          required
        >
          <option value='' disabled hidden></option>
          {choices.map((item, index) => (
            <option
              key={index}
              value={_.isEmpty(item.value) ? item.label : item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
        {title && <label className={classnames(style.DropdownLabel, { [style.HeightDropdownLabel]: questionRef.includes('height') }, !title && style.NoTitle)}>{title}</label>}
      </div>
      <br />
      {(showInput || customChoice) && (
        <input
          className={style.Dropdown}
          type='text'
          placeholder='Please type your favorite denim brand here'
          defaultValue={(customChoice && initialValue) || ''}
          onChange={(e) => getValue && getValue(e.target.value, questionRef)}
        />
      )}
    </Cell>
  );
}

export default Dropdown;

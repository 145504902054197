import React, { useEffect, useState, useRef } from 'react';
import style from '../Quiz/Quiz.module.scss';
import Footer from 'Footer';
import SectionHeader from 'SectionHeader';
import FormInput from 'FormInput';
import { Grid, Cell } from 'styled-css-grid';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import classnames from 'classnames';
import { US_STATES } from './../utils/us-states';
import dropdownStyle from './../Dropdown/Dropdown.module.scss';
import {
  combinedSchema,
  combinedInitialValues,
} from './schema';
import {
  createBillingInfo,
  CREATE_PAYMENT_DATA_REQUESTED,
  CREATE_PAYMENT_DATA_ERROR
} from './../store/Actions/CustomerAction';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_PATH } from './../Config.js';
import _ from 'lodash';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import kountSDK from "@kount/kount-web-client-sdk";
import Checkbox from '../Checkbox';
import Button from 'Button';
import * as tracking from './../utils/gt-tracking';

const formFieldStyle = {
  marginBottom: '1rem'
};

const sectionStyle = {
  marginBottom: '2rem'
};

function Payment() {
  const dispatch = useDispatch();
  const blogInterest = useSelector((state) =>
    _.get(state, 'questions.blogInterest')
  );
  const isLoading = useSelector(state => state.customer.isLoading);

  const USStates = _.map(US_STATES, (s) => (
    <option key={`states-${s.value}`} value={s.value}>
      {s.label}
    </option>
  ));

  const [autocompleteAddress, setAutocompleteAddress] = useState('');
  const formikRef = useRef();
  const [textOptIn, setTextOptIn] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [hasAttemptedToLeave, setHasAttemptedToLeave] = useState(false);
  const inactivityTimerRef = useRef(null);
  const modalRef = useRef(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const formik = formikRef.current;

    let streetNumber = '';
    let streetName = '';

    results[0].address_components.forEach((component) => {
      const types = component.types;

      if (types.includes('street_number')) {
        streetNumber = component.long_name;
      } else if (types.includes('route')) {
        streetName = component.long_name;
      } else if (types.includes('locality')) {
        formik.setFieldValue('shipping_city', component.long_name);
      } else if (types.includes('administrative_area_level_1')) {
        formik.setFieldValue('shipping_state', component.short_name);
      } else if (types.includes('postal_code')) {
        formik.setFieldValue('shipping_postal_code', component.long_name);
      }
    });

    // Set the full address
    if (streetNumber || streetName) {
      const fullAddress = [streetNumber, streetName]
        .filter(Boolean)
        .join(' ');
      formik.setFieldValue('shipping_line_1', fullAddress);
      setAutocompleteAddress(fullAddress);
    } else {
      // Fallback: use the first line of the selected address
      formik.setFieldValue('shipping_line_1', value.split(',')[0]);
      setAutocompleteAddress(value.split(',')[0]);
    }
  };

  const kountSessionId = useSelector((state) => _.get(state, 'customer.data.kount_session_id'));

  useEffect(() => {
    const kountConfig = {
        clientID: process.env.REACT_APP_KOUNT_MERCHANT_ID,
        environment: process.env.REACT_APP_ENV == "production" ? "PROD" : "TEST",
        isSinglePageApp: true
    };
    console.log(
      "initialied Kount SDK:",
      {
        clientID: process.env.REACT_APP_KOUNT_MERCHANT_ID ? `${process.env.REACT_APP_KOUNT_MERCHANT_ID[0]}********` : null,
        environment: process.env.REACT_APP_ENV == "production" ? "PROD" : "TEST",
        isSinglePageApp: true
      },
      { sessionId: kountSessionId ? `${kountSessionId[0]}**********` : null }
    )
    kountSDK(kountConfig, kountSessionId);
  }, [kountSessionId]);

  // Get these values from Redux state
  const isDressingRoomToGo = useSelector((state) =>
    _.get(state, 'customer.data.dressing_room_to_go_signup')
  );
  const isThemeboxSignUp = useSelector((state) =>
    _.chain(state).get('customer.data.themebox_signup').value()
  );
  const themeboxTheme = useSelector((state) =>
    _.chain(state).get('questions.responses.themebox').value()
  );
  const themeboxDescription = useSelector((state) =>
    _.chain(state).get('questions.responses.themebox_description').value()
  );
  const shipByDate = useSelector((state) =>
    _.get(state, 'questions.responses.ship_by_date')
  );

  const handleZipFormat = (e) => {
    const { name, value } = e.target;
    const formik = formikRef.current;
    let formattedValue = value.replace(/\D/g, '');
    if (formattedValue.length > 5) {
      formattedValue = `${formattedValue.slice(0, 5)}-${formattedValue.slice(5, 9)}`;
    }
    formik.setFieldValue(name, formattedValue);
  };

  const submitForm = async (values) => {
    const shippingData = _.reduce(
      _.pickBy(values, (val, key) => _.includes(key, 'shipping_')),
        (current, val, key) => {
          current['is_primary'] = true;
          current[key.replace('shipping_', '')] = val;
          return current;
        },
        {}
      );
      
    const billingData = _.reduce(
      _.pickBy(values, (val, key) => _.includes(key, 'billing_') && key !== 'billing_same_as_shipping'),
      (current, val, key) => {
        current[key.replace('billing_', '')] = val;
        return current;
      },
      {}
    );

    if (values.billing_same_as_shipping) {
      billingData.street_address1 = shippingData.line_1;
      billingData.street_address2 = shippingData.line_2;
      billingData.city = shippingData.city;
      billingData.state = shippingData.state;
      billingData.zip_code = shippingData.postal_code;
      billingData.phone = shippingData.phone;
    }

    billingData.expiration = billingData.expiration.replace('/', '');

    const options = {
      blog_interest: blogInterest || null,
      coupon: values.coupon || 'HELLO25',
      isDressingRoomToGo,
      isThemeboxSignUp,
      themeboxTheme,
      themeboxDescription,
      ship_by_date: shipByDate
    };

    try {
      dispatch({ type: CREATE_PAYMENT_DATA_REQUESTED });
      
      await dispatch(createBillingInfo(
        billingData,
        `${BASE_PATH}/account/confirmation`,
        options
      ));

    } catch (error) {
      console.error('Error in form submission:', error);
      dispatch({ type: CREATE_PAYMENT_DATA_ERROR });
    }
  };

  const handleCloseModal = () => {
    setShowExitModal(false);
    tracking.push({
      eventCategory: 'onboarding',
      eventAction: 'promo_modal_close',
      eventLabel: 'completed'
    });
  };

  const resetInactivityTimer = () => {
    if (!isMobile) return;
    
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    inactivityTimerRef.current = setTimeout(() => {
      if (!hasAttemptedToLeave) {
        setShowExitModal(true);
        setHasAttemptedToLeave(true);
      }
    }, 5000);
  };

  const handleMouseLeave = (e) => {
    if (!isMobile && e.clientY <= 0 && !hasAttemptedToLeave) {
      setShowExitModal(true);
      setHasAttemptedToLeave(true);
    }
  };

  useEffect(() => {
    if (isMobile) {
      // Start initial timer
      resetInactivityTimer();

      // Add form interaction listeners
      const form = document.querySelector('form');
      if (form) {
        const handleFormInteraction = () => resetInactivityTimer();
        form.addEventListener('input', handleFormInteraction);
        form.addEventListener('touchstart', handleFormInteraction);

        return () => {
          clearTimeout(inactivityTimerRef.current);
          form.removeEventListener('input', handleFormInteraction);
          form.removeEventListener('touchstart', handleFormInteraction);
        };
      }
    } else {
      document.addEventListener('mouseleave', handleMouseLeave);
      return () => document.removeEventListener('mouseleave', handleMouseLeave);
    }
  }, [hasAttemptedToLeave]);

  useEffect(() => {
    if (showExitModal) {
      tracking.push({
        eventCategory: 'onboarding',
        eventAction: 'promo_modal_open',
        eventLabel: 'completed'
      });
    }
  }, [showExitModal]);

  return (
    <div className={classnames(style.Quiz)}>
      <div className='group group--slim mt2 mb2'>
        <SectionHeader
          title="Shipping & Payment Details"
          description={
                <div className='mb'>
              Please enter your shipping address and payment information.
              We do not accept debit cards.
                </div>
          }
        />

        <Formik
          isInitialValid={false}
          validateOnMount={true}
          validateOnChange={true}
          initialValues={combinedInitialValues}
          validationSchema={combinedSchema}
          innerRef={formikRef}
          onSubmit={submitForm}
        >
          {({ isValid, setValues, values }) => (
            <Form>
              <Grid>
                <Cell width={12} style={sectionStyle}>
                  <h3 style={{ marginBottom: '1rem' }}>Shipping Address</h3>
                  {/* Shipping Address Fields */}
                  <div style={formFieldStyle}>
                              <label style={{
                                fontFamily: "proxima-nova, sans-serif",
                                color: "#003B5C",
                                fontSize: "18px",
                                display: "block",
                                marginBottom: "5px"
                              }}>
                                Address line 1*
                              </label>
                              <PlacesAutocomplete
                      value={values.shipping_line_1 || autocompleteAddress}
                      onChange={(value) => {
                        setAutocompleteAddress(value);
                        formikRef.current.setFieldValue('shipping_line_1', value);
                      }}
                                onSelect={handleSelect}
                                searchOptions={{
                                  componentRestrictions: { country: ['us'] },
                                }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <Field
                                      className={dropdownStyle.Dropdown}
                                      {...getInputProps({
                              placeholder: 'Enter your address',
                              name: 'shipping_line_1',
                              type: 'text',
                            })}
                                    />
                                    <div>
                                      {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => (
                                          <div {...getSuggestionItemProps(suggestion)}>
                                            {suggestion.description}
                                          </div>
                            ))}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                    <ErrorMessage
                      name="shipping_line_1"
                      component="div"
                      className={style.ErrorMessage}
                    />
                  </div>

                  <div style={formFieldStyle}>
                    <Field
                      name="shipping_line_2"
                      type="text"
                      label="Address line 2"
                      placeholder="Apt, Suite, etc. (optional)"
                      component={FormInput}
                    />
                    <ErrorMessage
                      name="shipping_line_2"
                      component="div"
                      className={style.ErrorMessage}
                    />
                  </div>

                  <div style={formFieldStyle}>
                    <Field
                      name="shipping_city"
                      type="text"
                      label="City*"
                      placeholder="City"
                      component={FormInput}
                    />
                    <ErrorMessage
                      name="shipping_city"
                      component="div"
                      className={style.ErrorMessage}
                    />
                  </div>

                  <div style={formFieldStyle}>
                    <label style={{
                      fontFamily: "proxima-nova, sans-serif",
                      color: "#003B5C",
                      fontSize: "18px"
                    }}>State*</label>
                    <Field
                      className={dropdownStyle.Dropdown}
                      as="select"
                      name="shipping_state"
                    >
                      <option>Select Your State</option>
                      {USStates}
                    </Field>
                    <ErrorMessage
                      name="shipping_state"
                      component="div"
                      className={style.ErrorMessage}
                    />
                            </div>

                  <div style={formFieldStyle}>
                            <Field
                      name="shipping_postal_code"
                      type="text"
                      label="ZIP Code*"
                      placeholder="ZIP Code"
                      maxLength={10}
                      onChange={handleZipFormat}
                              component={FormInput}
                            />
                          <ErrorMessage
                      name="shipping_postal_code"
                      component="div"
                            className={style.ErrorMessage}
                          />
                  </div>

                  <div style={formFieldStyle}>
                    <Field
                      name="shipping_phone"
                      type="text"
                      label="Phone Number*"
                      placeholder="Phone Number"
                      mask="(999) 999-9999"
                      maskChar={null}
                      component={FormInput}
                    />
                    <ErrorMessage
                      name="shipping_phone"
                      component="div"
                      className={style.ErrorMessage}
                    />
                  </div>

                  <div style={formFieldStyle}>
                    <Checkbox
                      id="textOptIn"
                      checked={textOptIn}
                      onChange={() => setTextOptIn(!textOptIn)}
                      label="Sign up for text updates"
                    />
                  </div>
                </Cell>

                <Cell width={12} style={sectionStyle}>
                  <h3 style={{ marginBottom: '1rem' }}>Payment Information</h3>
                  {/* Payment Fields */}
                  <div style={formFieldStyle}>
                    <Field
                      name="billing_name"
                      type="text"
                      label="Cardholder Name*"
                      placeholder="Cardholder Name"
                      component={FormInput}
                    />
                    <ErrorMessage
                      name="billing_name"
                      component="div"
                      className={style.ErrorMessage}
                    />
                  </div>

                  <div style={formFieldStyle}>
                    <Field
                      name="billing_credit_card_number"
                      type="text"
                      label="Card Number*"
                      placeholder="Card Number"
                      mask="9999-9999-9999-9999"
                      component={FormInput}
                    />
                    <ErrorMessage
                      name="billing_credit_card_number"
                      component="div"
                      className={style.ErrorMessage}
                    />
                  </div>

                  <Grid style={formFieldStyle}>
                    <Cell width={6}>
                      <Field
                        name="billing_expiration"
                        type="text"
                        label="Expiration Date*"
                        placeholder="MM/YY"
                        mask="99/99"
                        component={FormInput}
                      />
                      <ErrorMessage
                        name="billing_expiration"
                        component="div"
                        className={style.ErrorMessage}
                      />
                    </Cell> 
                    <Cell width={6}>
                      <Field
                        name="billing_cvv"
                        type="text"
                        label="CVV*"
                        placeholder="CVV"
                        mask="9999"
                        component={FormInput}
                      />
                      <ErrorMessage
                        name="billing_cvv"
                        component="div"
                        className={style.ErrorMessage}
                    />
                  </Cell>
                  </Grid>

                  <div style={formFieldStyle}>
                    <Field>
                      {({ field, form }) => (
                        <Checkbox
                          id="billing_same_as_shipping"
                          name="billing_same_as_shipping"
                          checked={values.billing_same_as_shipping}
                          onChange={() => {
                            const newValue = !values.billing_same_as_shipping;
                            setValues({
                              ...values,
                              billing_same_as_shipping: newValue,
                              ...(newValue && {
                                billing_street_address1: values.shipping_line_1,
                                billing_street_address2: values.shipping_line_2,
                                billing_city: values.shipping_city,
                                billing_state: values.shipping_state,
                                billing_zip_code: values.shipping_postal_code,
                                billing_phone: values.shipping_phone,
                              })
                            });
                          }}
                          label="Billing address same as shipping"
                        />
                      )}
                    </Field>
                  </div>

                  {!values.billing_same_as_shipping && (
                    <>
                      <h4 style={{ marginBottom: '1rem' }}>Billing Address</h4>
                      <div style={formFieldStyle}>
                        <Field
                          name="billing_street_address1"
                          type="text"
                          label="Billing Address Line 1*"
                          placeholder="Billing Address Line 1"
                          component={FormInput}
                        />
                        <ErrorMessage
                          name="billing_street_address1"
                          component="div"
                          className={style.ErrorMessage}
                        />
                      </div>

                      <div style={formFieldStyle}>
                        <Field
                          name="billing_street_address2"
                          type="text"
                          label="Billing Address Line 2"
                          placeholder="Billing Address Line 2 (Optional)"
                          component={FormInput}
                        />
                        <ErrorMessage
                          name="billing_street_address2"
                          component="div"
                          className={style.ErrorMessage}
                        />
                      </div>

                      <div style={formFieldStyle}>
                        <Field
                          name="billing_city"
                          type="text"
                          label="City*"
                          placeholder="City"
                          component={FormInput}
                        />
                        <ErrorMessage
                          name="billing_city"
                          component="div"
                          className={style.ErrorMessage}
                        />
                      </div>

                      <div style={formFieldStyle}>
                        <label style={{
                          fontFamily: "proxima-nova, sans-serif",
                          color: "#003B5C",
                          fontSize: "18px"
                        }}>State*</label>
                        <Field
                          className={dropdownStyle.Dropdown}
                          as="select"
                          name="billing_state"
                        >
                          <option>Select Your State</option>
                          {USStates}
                        </Field>
                        <ErrorMessage
                          name="billing_state"
                          component="div"
                          className={style.ErrorMessage}
                        />
                      </div>

                      <div style={formFieldStyle}>
                        <Field
                          name="billing_zip_code"
                          type="text"
                          label="ZIP Code*"
                          placeholder="ZIP Code"
                          maxLength={10}
                          onChange={handleZipFormat}
                          component={FormInput}
                        />
                        <ErrorMessage
                          name="billing_zip_code"
                          component="div"
                          className={style.ErrorMessage}
                        />
                      </div>

                      <div style={formFieldStyle}>
                        <Field
                          name="billing_phone"
                          type="text"
                          label="Phone Number*"
                          placeholder="Phone Number"
                          mask="(999) 999-9999"
                          maskChar={null}
                          component={FormInput}
                        />
                        <ErrorMessage
                          name="billing_phone"
                          component="div"
                          className={style.ErrorMessage}
                        />
                      </div>
                    </>
                  )}

                  <div style={formFieldStyle}>
                    <Field
                      name="coupon"
                      type="text"
                      label="Discount Code"
                      placeholder="Discount Code To Be Redeemed At Checkout"
                      component={FormInput}
                    />
                  </div>
                </Cell>
              </Grid>

              <Footer
                bgColor={[`#CE0058`, `#E67FAB`]}
                disableButton={!isValid || isLoading}
                buttonType='submit'
                buttonCopy={isLoading ? "Processing Order..." : "Complete Order"}
                paymentButton={true}
              />
            </Form>
          )}
        </Formik>
      </div>

      {showExitModal && (
        <div 
          className="exitModal"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999999
          }}
          onClick={handleCloseModal}
        >
          <div 
            style={{
              backgroundColor: 'white',
              padding: '40px',
              borderRadius: '8px',
              maxWidth: '90%',
              width: '400px',
              textAlign: 'center',
              position: 'relative',
              zIndex: 1000000
            }}
            onClick={e => e.stopPropagation()}
          >
            {/* Close button */}
            <button
              style={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                background: 'none',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
                color: '#333'
              }}
              onClick={handleCloseModal}
            >
              ×
            </button>

            <h2 style={{
              fontSize: '32px',
              marginBottom: '16px',
              color: '#003B5C',
              fontFamily: '"proxima-nova", sans-serif',
              fontWeight: '600',
              lineHeight: '1.2'
            }}>
              Don't forget...
            </h2>

            <p style={{
              fontSize: '16px',
              marginBottom: '24px',
              color: '#333',
              fontFamily: '"proxima-nova", sans-serif',
              lineHeight: '1.5'
            }}>
              Your first <span style={{ fontWeight: 600 }}>styling fee is $1</span> and you get <span style={{ fontWeight: 600 }}>$25 off</span> anything you keep if you order today!
            </p>

            <Button
              variant="Tertiary"
              content="CONTINUE"
              handleClick={() => setShowExitModal(false)}
              fullWidth
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Payment;
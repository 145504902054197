import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import style from "./NavBar.module.scss";
import { signOut } from "./../store/Actions/AuthAction";
import history from "../History";
import { BASE_PATH, HOME_URL } from "./../Config";

function NavBar({
  logo,
  profile,
  logOut,
  background,
  text,
  dashboard,
  hasLogOut,
  centerLogo
}) {
  const dispatch = useDispatch();
  const customer = useSelector((reduxState) => reduxState.customer.data);

  function handleSignOut() {
    dispatch(signOut());
  }

  function handleMyProfile() {
    history.push(`${BASE_PATH}/dashboard`);
  }

  return (
    <div className={style.NavBar} style={{ background: background }}>
      <a
        href={`${HOME_URL}`
        }
      >
        <img
          src={logo}
          alt="Trendsend"
          className={classnames(
            style.logo,
            centerLogo && style.CenterLogo
          )}
        />
      </a>
      <ul className={style.navLinks}>
        {!dashboard && <li onClick={handleMyProfile}>DASHBOARD</li>}
        {!hasLogOut && <li onClick={handleSignOut}>LOG OUT</li>}
      </ul>
      <ul className={style.navIcons}>
        {!dashboard && (
          <li onClick={handleMyProfile}>
            <img src={profile} alt="my profile" className={style.myProfile} />
          </li>
        )}
        {!hasLogOut && (
          <li onClick={handleSignOut}>
            <img src={logOut} alt="log out" className={style.logOut} />
          </li>
        )}
      </ul>
    </div>
  );
}

export default NavBar;

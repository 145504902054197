import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// components
import NavBar from "NavBar";
import Header from "../Dashboard/Header.jsx";
import Footer from "Footer";
import ThemeBoxItems from "./ThemeBoxItems";
import history from "../History";
import { fetchShippingAddress } from "./../store/Actions/CustomerAction";
import { boxRequested } from './../store/Actions/OrderAction.js';
import ConfirmShippingAddress from "../ConfirmShippingAddress";
// images 
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from "../images/icon_account.svg";
import logOut from "../images/icon_logout.svg";
import { BASE_PATH } from "./../Config";

const color = {
    color: '#003b5c'
};


function ThemeBox() {

    const dispatch = useDispatch();

    const currentOrder = useSelector(state => _.get(state, 'orders.currentOrder'));
    
    let themebox = _.get(currentOrder, 'themebox');

    useEffect(() => {
        dispatch(fetchShippingAddress());
    }, [dispatch]);
  
    const shippingAddress = useSelector(state => _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({is_primary: true})
      .reduce( (current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current
      }, {})
      .value());

    function submitForm(e) {
        dispatch(boxRequested({themebox}));
        history.push(`${BASE_PATH}/dashboard`);
    }

    return (
      <div>
        <NavBar
          text='white'
          logo={EvereveTrendsendStackedLogoNavy}
          profile={profile}
          logOut={logOut}
        />
        <div className='group group--slim mt2 mb2'>
          <Header
            header='Would you like your stylist to curate a Theme Box for you?'
            subheader='Sometimes you just need an idea to get the style process started. Enter theme boxes. Based on what you’re up to each month…and completely customizable.'
            color={color}
          />
          <ThemeBoxItems />
          <ConfirmShippingAddress
            path={history.location.pathname}
            shippingAddress={shippingAddress}
          />
          <Footer
            bgColor={[`#CE0058`, `#E67FAB`]}
            disableButton={!themebox}
            buttonType='submit'
            buttonCopy={`ORDER THIS BOX`}
            footerStep={submitForm}
          />
        </div>
      </div>
    );
}

export default ThemeBox;
import React, {Fragment} from "react";
import { Cell } from "styled-css-grid";

function Statement({ title }) {
  return (
    <Fragment>
      <Cell width={12}>
        <div style={{ marginTop: '1.8rem', marginBottom: '-1.2rem' }}>
          <h2 className="fs-18" >{title}</h2>
        </div>
      </Cell>
    </Fragment>
  );
}

export default Statement;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Dropdown/Dropdown.module.scss';
import './AccountManagement.Module.scss';
import dropdownStyle from './../Dropdown/Dropdown.module.scss';
import Footer from 'Footer';
import SectionHeader from 'SectionHeader';
import FormInput from 'FormInput';
import { Grid, Cell } from 'styled-css-grid';
import { Formik, Form, Field } from 'formik';
import Header from '../Dashboard/Header.jsx';
import contact from './account_contact.json';
import shipping from './account_shipping.json';
import name from './account_name.json';
import {
  fetchBillingInfo,
  updateAccountInfoData,
  fetchStoreLocations,
  createMfaRequest,
  createMfaResubmission,
  resetMfaRequired,
  loadCustomerDataWithoutOrder,
} from './../store/Actions/CustomerAction';
import _ from 'lodash';
import NavBar from 'NavBar';
import Modal from '../MultiFactorModal';
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';
import { NavLink } from 'react-router-dom';
import cardImage from 'images/card.svg';
import { BASE_PATH } from './../Config';
import check from '../images/Tile/StyleProfile/Check.svg';
import { schema } from './schema';
import { US_STATES } from "./../utils/us-states";

const color = {
  color: '#003b5c',
};

const USStates = _.map(US_STATES, s => <option key={`states-${s.value}`} value={s.value}>{s.label}</option>);

function AccountManagement({ location }) {
  const dispatch = useDispatch();
  const mfaRequired = useSelector(state => state.customer.mfaRequired);
  const [formValues, setFormValues] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (mfaRequired === false && Object.keys(formValues).length > 0) {
      performUpdate();
    }
  }, [mfaRequired]);

  useEffect(() => {
    setShowModal(mfaRequired);
  }, [mfaRequired]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const redirectPath = location.navProps?.prevPath ? `${BASE_PATH}${location.navProps?.prevPath}` : `${BASE_PATH}/dashboard`;

  function submitForm(values, { setSubmitting, validateForm }) {
    validateForm().then(errors => {
      const isValid = Object.keys(errors).length === 0;

      if (isValid) {
        setFormValues(values);
        if (mfaRequired) {
          setShowModal(true);
        } else {
          dispatch(updateAccountInfoData(values, redirectPath));
        }
      } else {
        console.log("Form is not valid:", errors);
      }

      setSubmitting(false);
    });
  }

  function handleSubmitWithMfaCode(mfaCode, mfaType) {
    dispatch(createMfaResubmission(mfaType, mfaCode, redirectPath));
    setFormValues(false);
  }

  function performUpdate() {
    if (!mfaRequired) {
      dispatch(updateAccountInfoData(formValues, redirectPath));
      setFormValues({});
    }
  }

  function handleMfaRequest(method) {
    dispatch(createMfaRequest(method));
  };

  const customer = useSelector((state) => state.customer.data);
  const currentOrder = useSelector((state) => state.orders.currentOrder);

  const shippingAddress = useSelector((state) =>
    _.chain(state)
      .get('customer.data.shipping_addresses')
      .find({ is_primary: true })
      .reduce((current, value, key) => {
        current[`shipping_${key}`] = _.isString(value) ? value : '';
        return current;
      }, {})
      .value()
  );

  const ccNumber = useSelector((state) =>
    _.chain(state)
      .get('customer.billingInfo.card_info.credit_card_number', '')
      .toString()
      .value()
      .slice(-4)
  );

  const storeLocations = useSelector((state) => state.customer.storeLocations);

  const isSubmitting = useSelector((state) => state.customer.isLoading);

  useEffect(() => {
    if (_.isEmpty(shippingAddress)) {
      dispatch(loadCustomerDataWithoutOrder());
    }
    dispatch(fetchBillingInfo());
    dispatch(fetchStoreLocations());
    dispatch(resetMfaRequired());
  }, [dispatch,
    shippingAddress.length
  ]);

  const sortedLocations = _.orderBy(storeLocations, ['address_state'], ['asc']);

  if (_.isEmpty(customer) || _.isEmpty(customer)) return '';

  return (
    <div>
      <NavBar
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
        centerLogo
      />
      <div className='group group--slim mt2 mb2'>
        <Header
          header='Purchase History'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />
        <Grid>
          <Cell width={12}>
            <div>
              <NavLink
                className='editField Border'
                to={`${BASE_PATH}/account/purchase-history`}
              >
                View Your Purchase History
              </NavLink>
            </div>
          </Cell>
        </Grid>
        <Header
          header='Stylist Notes'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />
        <Grid>
          <Cell width={12}>
            <div>
              <NavLink
                className='editField Border'
                to={`${BASE_PATH}/account/stylist-notes`}
              >
                View Your Outfits and Stylist Notes
              </NavLink>
            </div>
          </Cell>
        </Grid>
        <Header
          header='Account Management'
          subheader='Edit your billing and shipping details, your contact information, or EVEREVE Store.'
          color={color}
          TextAlignCenter
          FontSizeLarge
        />
        <Formik
          validationSchema={schema}
          initialValues={{ ...shippingAddress, ...customer }}
          onSubmit={submitForm}
        >
          {formikProps => {
            return (<Form className='accountManagementForm'>
              <SectionHeader title='Name' description='' />
              <Grid>
                {name.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Cell width={columnWidth} key={name} className='mb2'>
                        <Field
                          name={name}
                          type={type}
                          label={label}
                          mask={mask}
                          placeholder={placeholder}
                          component={FormInput}
                        />
                      </Cell>
                    );
                  }
                )}
              </Grid>
              <SectionHeader title='Shipping Address' description='' />
              <Grid>
                <Cell width={12}>
                  <h3 className='fs-18'>Current Shipping Address</h3>
                  <Cell width={12}>
                    {shippingAddress['shipping_line_1'] ? (
                      <>
                        <div>{shippingAddress['shipping_line_1']}</div>
                        <div>{shippingAddress['shipping_line_2']}</div>
                        <div className='mb'>{shippingAddress['shipping_city']}, {shippingAddress['shipping_state']} {shippingAddress['shipping_postal_code']}</div>
                      </>
                    ) : 'No current shipping address'}
                    <div className='mt2 fs-18'>New Address</div>
                  </Cell>
                </Cell>
                {
                  shipping.map(({ label, placeholder, columnWidth, type, name }) => (
                    <Cell width={columnWidth} key={name} className='mb2'>
                      {name === 'shipping_state' ? (
                        <Field
                        className={dropdownStyle.Dropdown}
                          as='select'
                          name={name}
                        >
                          <option>Select Your State</option>
                          {USStates}
                        </Field>
                      ) : (
                        <Field
                          name={name}
                          type={type}
                          label={label}
                          placeholder={placeholder}
                          component={FormInput}
                        />
                      )}
                    </Cell>
                  ))}
              </Grid>
              <SectionHeader title='Contact Details' description='' />
              <Grid>
                {contact.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Cell width={columnWidth} key={name} className='mb2'>
                        <Field
                          name={name}
                          type={type}
                          label={label}
                          mask={mask}
                          placeholder={placeholder}
                          component={FormInput}
                        />
                      </Cell>
                    );
                  }
                )}
              </Grid>

              <SectionHeader title='Payment Method' description='' />
              <Grid>
                <Cell width={12}>
                  <div className='paymentMethod mb4'>
                    <img
                      className='dib vam mr-'
                      width='25px'
                      src={cardImage}
                      alt='Card'
                    />
                    <span className='cardInfo'>•••• •••• •••• {ccNumber}</span>
                    <img
                      className='check dib vam mr-'
                      width='25px'
                      src={check}
                      alt='Card'
                    />
                  </div>
                  <div>
                    {
                      (customer.first_timer && currentOrder.id) ? <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Contact 1 (877)290-6262 to Update Payment Information</p> :
                        (
                          <NavLink
                            className='editField Border'
                            to={`${BASE_PATH}/change/payment`}
                          >
                            Change Payment Method
                          </NavLink>
                        )
                    }

                  </div>
                </Cell>
              </Grid>

              {/* <SectionHeader title='Update Password' description='' />
              <Grid>
                {password.map(
                  ({ label, placeholder, columnWidth, type, name, mask }) => {
                    return (
                      <Cell width={columnWidth} key={name} className='mb2'>
                        <Field
                          name={name}
                          type={type}
                          label={label}
                          mask={mask}
                          placeholder={placeholder}
                          component={FormInput}
                        />
                      </Cell>
                    );
                  }
                )}
              </Grid> */}

              <SectionHeader title='EVEREVE Store' />
              <Grid>
                <Cell width={12}>
                  <Field
                    className={styles.Dropdown}
                    name='assigned_location_id'
                    component='select'
                    placeholder='EVEREVE Store'
                  >
                    <option value="">Select a store...</option>
                    {sortedLocations.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.address_state}, {item.address_city} -{' '}
                          {item.name}
                        </option>
                      );
                    })}
                  </Field>
                </Cell>
                <Cell width={12} className='mb2'>
                  <Field
                    name='preferred_stylist'
                    type='text'
                    label='Preferred Stylist'
                    placeholder='Preferred Stylist'
                    component={FormInput}
                  />
                </Cell>
              </Grid>
              <div>
              </div>
              {!formikProps.isValid && Object.keys(formikProps.touched).length > 0 && (
                <div className="formErrorMessages">
                  {Object.keys(formikProps.errors).map(error => (
                    <div className="errorMessage" key={error}>{formikProps.errors[error]}</div>
                  ))}
                </div>
              )}
              <Footer
                variant='Tertiary'
                bgColor={[`#CE0058`, `#E67FAB`]}
                buttonType='submit'
                buttonCopy={`Save Changes`}
                disabled={isSubmitting}
                redirectPath={location.navProps?.prevPath || ""}
              />
            </Form>
            );
          }}
        </Formik>

        <Modal
          show={showModal}
          handleClose={handleCloseModal}
          handleMfaRequest={handleMfaRequest}
          handleSubmitWithMfaCode={handleSubmitWithMfaCode}
          saveChanges={performUpdate}
          saveButtonText="Confirm Changes"
          customer={customer}
          shippingAddress={shippingAddress}
        >
          <p>You have unsaved changes. Are you sure you want to save these changes?</p>
        </Modal>
      </div>
    </div>
  );
}

export default AccountManagement;

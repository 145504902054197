import React from 'react';
import Footer from 'Footer';
import style from '../Quiz/Quiz.module.scss';
import classnames from 'classnames';
import ProgressBar from 'ProgressBar';
import SectionHeader from 'SectionHeader';
import history from '../History';
import { BASE_PATH } from './../Config';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Image1 from 'images/payment-info/StylingFee.svg';
import Image2 from 'images/payment-info/Shipping.svg';
import Image3 from 'images/payment-info/TryOn.svg';
import Image4 from 'images/payment-info/Discount.svg';

import ImageDrtg1 from 'images/payment-info/PlaceOrder.svg';
import ImageDrtg2 from 'images/payment-info/DRTGTryOn.svg';
import ImageDrtg3 from 'images/payment-info/LoveOrReturnIt.svg';

const trendsendItems = [
  {
    id: 0,
    title: `A $20 styling fee is charged when we style you and<span class="fw-semibold"> applied to the items<br class="lineBreak-lg"/> you purchase.</span> The styling fee on your <span class="fw-semibold">first box</span> will be only $1.`,
    image: Image1,
  },
  {
    id: 1,
    title: `Shipping is <span class="fw-semibold">free</span> both ways.`,
    image: Image2,
  },
  {
    id: 2,
    title: `You'll have <span class="fw-semibold">5 days</span> to try everything on before sending back returns. You can also return items to any EVEREVE store.`,
    image: Image3,
  },
  {
    id: 3,
    title: `Keep 4 or more items and <span class="fw-semibold">save 10%</span> on your entire purchase.`,
    image: Image4,
  },
];

const dressigRoomToGoItems = [
  {
    id: 0,
    title: `A $20 styling fee is charged when we style you and<span class="fw-semibold"> applied to the items<br class="lineBreak-lg"/> you purchase.</span> The styling fee on your <span class="fw-semibold">first Trendsend</span> will be only $1.`,
    image: Image1,
  },
  {
    id: 1,
    title: `You'll have <span class="fw-semibold">2 days</span> to try everything on at home.`,
    image: ImageDrtg2,
  },
  {
    id: 2,
    title: `Decide what to keep, <span class="fw-semibold">check out online</span> and return anything that doesn’t work to your EVEREVE store.`,
    image: ImageDrtg3,
  },
  {
    id: 3,
    title: `Keep 4 or more items and <span class="fw-semibold">save 10%</span> on your entire purchase.`,
    image: Image4,
  },
];

function PaymentInfo() {
  const isDressingRoomToGo = useSelector((state) =>
    _.chain(state).get('customer.data.dressing_room_to_go_signup').value()
  );
  const items = isDressingRoomToGo ? dressigRoomToGoItems : trendsendItems;

  return (
    <div className={classnames(style.Quiz)}>
      <div className="group group--slim mt2 mb2">

        <SectionHeader
          style={{ marginTop: '70px', textAlign: 'center' }}
          title={"We're almost ready to style your first Trendsend!"}
        />

        <ul className="pt">
          {items.map(({ id, image, title, header }) => {
            return (
              <li className="df aic mb2" key={id}>
                <img
                  src={image}
                  alt={title}
                  className={classnames(
                    'mr2',
                    isDressingRoomToGo ? style.DrtgPaymentInfo : ''
                  )}
                  style={{ width: '6rem' }}
                />
                <div>
                  <div
                    className="fw-semibold"
                    dangerouslySetInnerHTML={{ __html: header }}
                  ></div>
                  <div dangerouslySetInnerHTML={{ __html: title }}></div>
                </div>
              </li>
            );
          })}
        </ul>

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType="submit"
          buttonCopy={
            isDressingRoomToGo
              ? `Continue to Payment`
              : `Enter Shipping Details`
          }
          footerStep={
            isDressingRoomToGo
              ? () => history.push(`${BASE_PATH}/payment/billing`)
              : () => history.push(`${BASE_PATH}/payment/shipping`)
          }
        />
      </div>
    </div>
  );
}

export default PaymentInfo;

import React from "react";

const TextInputField = ({
  name,
  type,
  placeholder,
  initialValue,
  field,
  inputClasses,
  handleChange,
  onSubmit,
  validateField,
  isReadOnly,
  min,
  max,
  maxLength,
  questionRef
}) => (
  <input
    className={inputClasses}
    onBlur={handleChange}
    onKeyDown={(e) => e.keyCode === 13 && onSubmit && onSubmit(e.target.value, questionRef)} // Ensure questionRef is passed here
    name={name}
    type={type}
    defaultValue={initialValue == null ? '' : initialValue}
    placeholder={placeholder}
    min={min}
    max={max}
    maxLength={maxLength}
    data-hj-whitelist=""
    {...field}
    onKeyUp={validateField}
    readOnly={isReadOnly}
  />
);

export default TextInputField;

import React from "react";
import { useFormikContext } from "formik";
import style from "./FormInput.module.scss";
import { Cell } from "styled-css-grid";
import MaskedInputField from "./MaskedInputField";
import PasswordInputField from "./PasswordInputField";
import TextInputField from "./TextInputField";
import classnames from "classnames";
import { formatInputValue } from './utils';

function FormInput({
  getValue,
  label,
  placeholder,
  type,
  questionRef,
  initialValue,
  name = '',
  form: { touched = {}, errors = {} } = {},
  field = {},
  mask,
  min,
  max,
  onSubmit,
  maxLength,
  validateField,
  isReadOnly,
  noBorderBottom,
  kind,
  maskChar
}) {
  const formik = useFormikContext();
  const inputClasses = classnames(
    style.FormInputField,
    noBorderBottom && 'nb',
    isReadOnly && style.isReadOnly,
    { [style.FormInputErrorField]: errors[field.name] && touched[field.name] }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Handle ZIP code fields specifically
    if (name === 'shipping_postal_code' || name === 'billing_zip_code') {
      formattedValue = value.replace(/\D/g, '');
      if (formattedValue.length > 5) {
        formattedValue = `${formattedValue.slice(0, 5)}-${formattedValue.slice(5, 9)}`;
      }
      if (formik && formik.setFieldValue) {
        formik.setFieldValue(name, formattedValue);
      }
      return;
    }

    if (getValue) {
      getValue(formattedValue, questionRef);
    } else if (formik && formik.setFieldValue) {
      formik.setFieldValue(name, formattedValue);
    }
  };

  // Return the appropriate input field
  const renderInput = () => {
    // For ZIP code fields
    if (field.name === 'shipping_postal_code' || field.name === 'billing_zip_code') {
      return (
        <input
          {...field}
          type="text"
          className={inputClasses}
          placeholder={placeholder}
          maxLength={10}
          onChange={handleChange}
        />
      );
    }

    // For all other masked fields (including phone)
    if (mask) {
      return (
        <MaskedInputField
          name={name}
          type={type}
          placeholder={placeholder}
          initialValue={initialValue}
          field={field}
          mask={mask}
          maskChar={maskChar}
          inputClasses={inputClasses}
          handleChange={handleChange}
        />
      );
    }

    // For password fields
    if (type === 'password') {
      return (
        <PasswordInputField
          name={name}
          placeholder={placeholder}
          initialValue={initialValue}
          field={field}
          inputClasses={inputClasses}
          handleChange={handleChange}
          onSubmit={onSubmit}
          questionRef={questionRef}
        />
      );
    }

    // For all other fields
    return (
      <TextInputField
        name={name}
        type={type}
        placeholder={placeholder}
        initialValue={initialValue}
        field={field}
        inputClasses={inputClasses}
        handleChange={handleChange}
        onSubmit={onSubmit}
        validateField={validateField}
        isReadOnly={isReadOnly}
        min={min}
        max={max}
        maxLength={maxLength}
        questionRef={questionRef}
      />
    );
  };

  return (
    <Cell width={12}>
      <div className={style.FormInput}>
        <label className={classnames(style.FormInputLabel, kind === 'short_text' && 'mb')}>
          {label}
        </label>
        {renderInput()}
      </div>

      {touched[field.name] && errors[field.name] && (
        <span className={style.FormInputErrorMessage}>{errors[field.name]}</span>
      )}
    </Cell>
  );
}

export default FormInput;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "../CustomizeOrder/SubHeader";
import { Grid, Cell } from "styled-css-grid";
import { NavLink } from "react-router-dom";
import { BASE_PATH } from "../Config";
import style from './ConfirmPayment.module.scss';
import classnames from 'classnames';
import { fetchBillingInfo } from "./../store/Actions/CustomerAction";
import _ from "lodash";

// Images
import cardImage from "images/card.svg";
import check from "../images/Tile/StyleProfile/Check.svg";

function ConfirmPayment({ path, isAbandonedAccount, cardExpired, cardExpiringSoon, ccExp14DaysCompareShipByDate }) {

  const dispatch = useDispatch();
  const ccNumber = useSelector((state) =>
    _.chain(state)
      .get("customer.billingInfo.card_info.credit_card_number", "")
      .toString()
      .value()
      .slice(-4)
  );

  const customer = useSelector((state) => state.customer.data);
  const currentOrder = useSelector((state) => state.orders.currentOrder);

  const hasCcNumber = !_.isEmpty(ccNumber)
  
  const expMessage = cardExpired ? 
    `Your credit card has expired. Please update your payment method by clicking "change payment" below` : 
    `Your credit card is expiring soon. Please update your payment method by clicking "change payment" below`;

  const selectedExpMessage = ccExp14DaysCompareShipByDate ? `Your credit card will expire before your next shipment. Please update your payment method by clicking "change payment" below` : expMessage;

  useEffect(() => {
    hasCcNumber && dispatch(fetchBillingInfo());
  }, [dispatch]);

  return (
    <>
      <Grid>
        <Cell width={12}>
          <SubHeader
            header={isAbandonedAccount ? `Payment` : `Confirm Payment`}
            subheader="Your styling fee and purchase will be charged to your card on file."
            errorMessage={(cardExpiringSoon || cardExpired || ccExp14DaysCompareShipByDate) && selectedExpMessage}
            TextAlignCenter
          />
        </Cell>
        <Cell width={12}>
          {hasCcNumber && (
            <div className={classnames(style.PaymentMethod, "mb4")}>
              <img
                className="dib vam mr-"
                width="25px"
                src={cardImage}
                alt="Card"
              />
              <span className="cardInfo">•••• •••• •••• {ccNumber}</span>
              <img
                className="check dib vam mr-"
                width="25px"
                src={check}
                alt="Card"
              />
            </div>
          )}
          <div>
            {
              (customer.first_timer && currentOrder.id) ? <p style={{textAlign: 'center', fontStyle: 'italic'}}>Contact 1 (877)290-6262 to Update Payment Information</p> : (
                <NavLink
                  className="editField Border"
                  to={{
                    pathname: `${BASE_PATH}/change/payment`,
                    navProps: {
                      prevPath: path.substring(path.lastIndexOf("/members/") + 8),
                    },
                  }}
                >
                  {isAbandonedAccount ? `Add Payment` : `Change Payment`}
                </NavLink>
              )
            }
          </div>
        </Cell>
      </Grid>
    </>
  );
}

export default ConfirmPayment;

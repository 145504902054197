import * as yup from 'yup';

export const schema = yup.object().shape({
  review: yup.string()
  .min(2, "Min. 2 characters.")
  .matches(/[a-z]+/i, "Please enter a valid name.")
  .required(' '),
});

export const reviewData = [
  {
    id: 0,
    name: 'review',
    label: 'Tell us more...',
    type: 'textarea',
    placeholder: '',
    columnWidth: 12,
  },
];

export const initialValues = 
  {
    review: ''
  };

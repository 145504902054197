import React from 'react';
import Header from '../Dashboard/Header';
import Footer from 'Footer';
import { BASE_PATH } from './../Config';
import history from '../History';
import styles from './StylistNotes.module.scss';
import { useSelector } from 'react-redux';
import { Grid, Cell } from 'styled-css-grid';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

function Review() {
  const getOrders = useSelector((state) => _.get(state, 'orders', {}));

  const flattenedOrders = _.flatten(
    _.map(getOrders, (order) => {
      return order;
    })
  );

  const completedOrders = _.filter(flattenedOrders, ['status', 'completed']);

  const ordersSortedByDate = _.sortBy(completedOrders, 'delivery_date').reverse();

  const orders = _.uniqBy(ordersSortedByDate, function (e) {
    return e.id;
  });

  return (
    <>
      <NavBar
        text='white'
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
      />
      <div className='group group--slim' style={{ paddingBottom: `30vh` }}>
        <Grid columns={1} className={styles.StylistNote}>
          <Header
            header='Your Outfits and Stylist Notes'
            TextAlignCenter
            FontSizeLarge
            SansSerif
          />

          {orders.map((order, index, row) => {
            let itemCount = 0;
            let buildLineItems = [];
            let lineItemCollection = [];

            const outfits = _.flatten(
              _.map(order.outfits, (outfit) => {
                return outfit;
              })
            );

            _.each(order.outfits, (outfit, i) => {
              outfit.title = `OUTFIT ${i + 1}`;
              buildLineItems.push(outfit.line_items);
            });

            buildLineItems.map((item, index) => {
              item.reduce((o, v) => {
                v.choices = order.customer_pick_up
                  ? [
                      { id: `return-${v.id}`, label: 'Return' },
                      { id: `keep-${v.id}`, label: 'Keep' },
                    ]
                  : [
                      { id: `return-${v.id}`, label: 'Return' },
                      { id: `exchange-${v.id}`, label: 'Exchange' },
                      { id: `keep-${v.id}`, label: 'Keep' },
                    ];
                return lineItemCollection.push({
                  ...v,
                  label: '',
                  discounted: false,
                });
              }, {});
              return (itemCount = lineItemCollection.length);
            });

            return (
              <>
                {order.line_items.length > 0 && (
                  <>
                    {index > 0 && <div className={styles.hr}></div>}
                    <div key={order.id}>
                      <span>
                        {moment(order.delivery_date).format('MMMM DD, YYYY')}
                      </span>{' '}
                      | <span>Order #{order.code}</span>
                    </div>
                    {order.stylist_note && (
                      <Header
                        header={
                          !_.isEmpty(order.stylist_note) && order.stylist_note
                        }
                        TextAlignCenter
                        SansSerif
                      />
                    )}
                    {outfits.map((outfit, index) => {
                      return (
                        <>
                          {outfit.line_items.length > 0 && (
                            <>
                              <div className={styles.OutfitTitle}>
                                {outfit.title}
                              </div>
                              {outfit.stylist_note && (
                                <div>{outfit.stylist_note}</div>
                              )}
                              <Grid columns={4}>
                                {outfit.line_items.map((lineItem, index) => {
                                  return (
                                    <Cell key={index}>
                                      <img
                                        width='100%'
                                        height='auto'
                                        src={lineItem.image_url}
                                      />
                                      <p className={styles.LineItemDescription}>
                                        {lineItem.description}
                                      </p>
                                      <p className='pb'>{lineItem.brand}</p>
                                    </Cell>
                                  );
                                })}
                              </Grid>
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </>
            );
          })}
        </Grid>

        <Footer
          bgColor={[`#CE0058`, `#E67FAB`]}
          buttonType='submit'
          buttonCopy={`Account Management`}
          footerStep={() => history.push(`${BASE_PATH}/account/management`)}
        />
      </div>
    </>
  );
}

export default Review;

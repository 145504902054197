import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';

// components
import SubHeader from '../CustomizeOrder/SubHeader';
import NavBar from 'NavBar';
import Footer from 'Footer';
import ThemeBoxItems from './ThemeBoxItems';
import history from '../History';

// images
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import profile from '../images/icon_account.svg';
import logOut from '../images/icon_logout.svg';

const color = {
  color: '#003b5c',
};

function OrderThemebox({ match }) {
  const dispatch = useDispatch();
  const isPlaceOrder = match.params.step === `order`;
  const order = useSelector((state) => _.get(state, 'orders.currentOrder', {}));
  const isLoadingOrders = useSelector((state) =>
    _.get(state, 'orders.isLoading')
  );
  const expectedDeliveryDate = useSelector((state) =>
    _.get(state, 'orders.currentOrder.expected_delivery_date')
  );
  const isOnDemand = useSelector((state) =>
    _.chain(state)
      .get('customer.data.subscription_type')
      .isEqual('subscription_on_demand')
      .value()
  );
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const themeboxes = useSelector((state) =>
    _.get(state, 'themeboxes.themeboxes', {})
  );

  function submitForm(e) {
    history.push('/members/register-themebox');
  }

  return (
    <div>
      <NavBar
        text='white'
        logo={EvereveTrendsendStackedLogoNavy}
        profile={profile}
        logOut={logOut}
        dashboard
        hasLogOut
      />
      <div className='customOrderContainer group group--slim mt2 mb7'>
        <SubHeader
          header='Select a Theme Box'
          subheader='Theme boxes are the easiest way to get a Trendsend you’ll love! Select a theme box to let your stylist know what kinds of outfits you’re looking for, and she’ll use your style profile information to curate the perfect Trendsend for you.'
          color={color}
          TextAlignCenter
        />

        <ThemeBoxItems order={order} themeboxes={themeboxes} />
      </div>

      <Footer
        bgColor={[`#CE0058`, `#E67FAB`]}
        buttonType='submit'
        buttonCopy={`Continue`}
        footerStep={submitForm}
        disableButton={_.isEmpty(order.themebox)}
        sticky
      />
    </div>
  );
}

export default OrderThemebox;

import React, { useState, useEffect, Fragment } from 'react';
import { getQuestionSelector } from 'QuestionSelector';
import NavBar from 'NavBar';
import EvereveTrendsendStackedLogoNavy from '../images/EVEREVE-TrensendStackedLogoNavy.png';
import Footer from 'Footer';
import SectionHeader from 'SectionHeader';
import { Grid, Cell } from 'styled-css-grid';
import _ from 'lodash';
import { DayPickerSingleDateController } from 'react-dates';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_PATH } from './../Config';
import moment from 'moment';
import {
  setInStoreSignup,
  setDressingRoomToGoSignup,
  fetchHolidays,
  fetchStoreLocations,
} from './../store/Actions/CustomerAction';

import {
  fetchStyleProfile,
  updateResponse,
  setAsPickup,
} from '../store/Actions/QuestionAction';

import { isDayBlockedForPickup, isDayBlockedForShipment } from "./../utils/dayPicker";

const onboardingSections = {
  physical: [`#FFC600`, `#FFE27F`],
  style: [`#F9423A`, `#FCA09C`],
  lifestyle: [`#FA86C8`, `#FDC3E4`],
  preferences: [`#CE0058`, `#E67FAB`],
};

function SchedulePickup({ match }) {
  const dispatch = useDispatch();
  let storeLocations = useSelector(
    (state) =>
      state.questions &&
      _.chain(state.questions.data.fields)
        .filter((f) => _.includes(['match_with_stylist_2'], f.ref))
        .flatten()
        .value()
  );

  storeLocations = _.chain(storeLocations)
    .map((f) => f.properties.fields[0])
    .value();

  const responses = useSelector((state) => state.questions.responses, {});
  const drtgSignup = useSelector((state) => state.customer.drtgSignup);
  const [button, setButton] = useState(true);
  const [storeLocation, setStorelocation] = useState('');

  const path = match.path.split(`${BASE_PATH}/`)[1];

  // it appears that we have a legacy path 'schedule-pickup-in-store' and current path 'schedule-pickup'
  // FIXME: deprecate the legacy path?
  const inStore = path === 'schedule-pickup-in-store' || path === 'schedule-pickup';

  const currentHour = new Date().getHours();

  const isThemeboxSignUp = useSelector((state) =>
    _.chain(state).get('questions.responses.themebox').value()
  );
  const isInStore = useSelector((state) =>
    _.chain(state).get('customer.data.registered_in_store').value()
  );

  const holidays = useSelector((state) =>
    _.get(state, 'holidays', {})
  );

  const fastFlow = isThemeboxSignUp || isInStore;

  let footerColor = '';

  if (!drtgSignup) {
    dispatch(setDressingRoomToGoSignup());
  }

  useEffect(() => {

    if (_.isEmpty(storeLocations)) {
      dispatch(fetchStoreLocations());
    }

    if (
      responses.preferred_store &&
      responses.preferred_store !== 'No Preference' &&
      responses.ship_by_date
    ) {
      setButton(false);
    } else {
      setButton(true);
    }

    dispatch(fetchStyleProfile());
    dispatch(fetchHolidays());

    if (match.path && match.path.includes('schedule-pickup-in-store')) {
      dispatch(setInStoreSignup());
    }
  }, [
    dispatch,
    storeLocations.length,
    responses.preferred_store,
    responses.ship_by_date,
  ]);

  let handleSave = () => {
    dispatch(setAsPickup(`${BASE_PATH}/payment/info`));
  };

  let handleCustomDeliveryDateChange = (ship_by_date) => {
    dispatch(updateResponse('ship_by_date', ship_by_date.toDate()));
  };

  let val = (value, ref) => {
    setStorelocation(value);

    dispatch(updateResponse(ref, value));
  };

  return (
    <div>
      <NavBar
        text='white'
        logo={EvereveTrendsendStackedLogoNavy}
        dashboard
        hasLogOut
        centerLogo
        isDressingRoomToGo
      />
      <div className='group group--slim mt2 mb2 longform'>
        <Fragment>
          <Grid columns={12} gap='16px' justifyContent='center'>
            {storeLocations.map((item) => {
              const Question = getQuestionSelector(item.type);
              const section = item.section;
              footerColor = onboardingSections[section];

              let stores = item.properties.choices.filter((choice) => {
                return (
                  choice.label !== 'No Preference' &&
                  choice.label !== 'IL, Evanston - Sherman Plaza' &&
                  choice.label !== 'MN, Eden Prairie - Windsor Plaza' &&
                  choice.label !== 'MN, St. Louis Park - The Shops at West End' &&
                  choice.label !== 'MN, Woodbury - Woodbury Lakes' &&
                  choice.label !== 'OH, Mason - Deerfield Towne Center' &&
                  choice.label !== 'MO, Kansas City - Zona Rosa' &&
                  choice.label !== 'OH, Beavercreek - The Greene' &&
                  choice.label !== 'OH, Columbus - Easton Town Center' &&
                  choice.label !== 'MI, Ann Arbor - Arbor Hills' &&
                  choice.label !== 'CO, Lakewood - Belmar' &&
                  choice.label !== 'MO, Chesterfield - Chesterfield Mall' &&
                  choice.label !== 'CT, South Windsor - The Promenade Shops at Evergreen Walk' &&
                  choice.label !== 'IL, Chicago - Lincoln Park' &&
                  choice.label !== 'WI, Wauwatosa - Mayfair Mall' &&
                  choice.label !== 'KS, Overland Park - Oak Park Mall' &&
                  choice.label !== 'WA, Lynnwood - Alderwood Mall' &&
                  choice.label !== 'OH, Beachwood - Beachwood Place' &&
                  choice.label !== 'MI, Novi - Twelve Oaks' &&
                  choice.label !== 'AR, Little Rock - Chenal' &&
                  choice.label !== 'TX, Plano - Willow Bend Center' &&
                  choice.label !== 'IL, Northbrook - Northbrook Court' &&
                  choice.label !== 'UT, Salt Lake City - City Creek Center' &&
                  choice.label !== 'MN, Bloomington - Mall of America' &&
                  choice.label !== 'MI, Lansing - Eastwood Towne Center' &&
                  choice.label !== 'NJ, Bridgewater - Bridgewater Commons' &&
                  choice.label !== 'CT, Norwalk - The SoNo Collection' &&
                  choice.label !== 'AZ, Scottsdale - Kierland Commons' &&
                  choice.label !== 'NJ, East Rutherford - American Dream' &&
                  choice.label !== 'TX, Fort Worth - Shops at Clearfork' &&
                  choice.label !== 'WI, Brookfield - The Corners of Brookfield' &&
                  choice.label !== 'MO, Kansas City - Country Club Plaza' &&
                  choice.label !== 'AZ, Chandler - Chandler Fashion Center'
                );
              });

              return (
                <Fragment key={item.id}>
                  <Cell width={12}>
                    <SectionHeader
                      style={{ textAlign: 'center' }}
                      title='Select a store for your order pickup.'
                    />

                    {item.type == `dropdown` && (
                      <Question
                        label={item.title}
                        choices={stores}
                        placeholder={
                          item.properties && item.properties.description
                        }
                        getValue={val}
                        initialValue={responses[item.ref]}
                        kind={item.type}
                        title={item.title}
                        form={{}}
                        multiSelect={
                          item.properties &&
                          item.properties.allow_multiple_selection
                        }
                        questionRef={item.ref}
                        questionId={item.id}
                        supersized={
                          item.properties && item.properties.supersized
                        }
                      />
                    )}
                  </Cell>

                  <Cell width={12}>
                    <SectionHeader
                      style={{ textAlign: 'center' }}
                      title='Select a pickup date.'
                    />
                    <DayPickerSingleDateController
                      onDateChange={(d) => {
                        handleCustomDeliveryDateChange(d);
                      }}
                      isDayBlocked={(d) => {
                        return inStore ? isDayBlockedForPickup(d, holidays) : isDayBlockedForShipment(d, holidays)
                      }}
                      date={
                        responses.ship_by_date
                          ? moment(responses.ship_by_date)
                          : ''
                      }
                      numberOfMonths={1}
                      daySize={40}
                      focused={true}
                      initialVisibleMonth={() => {
                        return moment();
                      }}
                      hideKeyboardShortcutsPanel={true}
                    />
                  </Cell>
                </Fragment>
              );
            })}

            <Cell width={12}>
              <Footer
                bgColor={footerColor}
                buttonType='submit'
                footerStep={handleSave}
                buttonCopy={`Next`}
                disableButton={button}
              />
            </Cell>
          </Grid>
        </Fragment>
      </div>
    </div>
  );
}

export default SchedulePickup;

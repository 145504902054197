import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import style from '../Button/Button.module.scss';
import themeStyle from '../ThemeBox/ThemeBox.module.scss';
import { Cell } from 'styled-css-grid';
import { useMediaQuery } from 'react-responsive';
import { fetchSuggestedItemClick } from '../store/Actions/OrderAction';
import _ from 'lodash';
import ReactSlider from 'react-slider';

const Radio = ({
  choices,
  title,
  width,
  getValue,
  initialValue,
  kind,
  questionId,
  questionRef,
  multiSelect,
  supersized,
  deselectable,
  hasThemeboxes,
  match,
  isSuggestedItem,
  isExchangeModal,
  doubleWidth,
  subscriptionSelector,
}) => {
  let deselect = (event) => {
    let val = event.target.value;
    let checked = event.target.checked;
    if (checked && deselectable) {
      getValue('', questionRef);
    }
  };
  questionId = questionRef || _.uniqueId('radio_');

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.stopPropagation();

    let val = event.target.value;
    let checked = event.target.checked;
    let newVal = val;
    if (multiSelect) {
      newVal = _.isArray(initialValue) ? initialValue : [val];
      if (checked) {
        newVal = _.includes(newVal, val) ? newVal : _.concat(newVal, val);
      } else {
        newVal = _.without(newVal, val);
      }
    }
    getValue(newVal, questionRef);
  };

  const suggestedItemClick = (website_url, id) => {
    const itemUrl = website_url.replace('/p/', '/');
    window.open(`${itemUrl}-c`);
    id && dispatch(fetchSuggestedItemClick(id));
  };

  const isMobile = useMediaQuery({ query: '(max-width: 512px)' });

  const hasImage = _.get(choices, '0.attachment.href');
  const brandRef = questionRef && questionRef.includes('favorites_brands');

  const aspectRatio = hasThemeboxes ? { '--aspect-width': '1', '--aspect-height': '1' } : doubleWidth
    ? { '--aspect-width': '3', '--aspect-height': '1' }
    : kind === `picture_choice` || questionRef === 'colors_to_avoid'
      ? !supersized
        ? questionRef === 'designs_disliked_patterns' && isMobile
          ? { '--aspect-width': '4.5', '--aspect-height': '4' }
          : { '--aspect-width': '4', '--aspect-height': '5' }
        : { '--aspect-width': '2', '--aspect-height': '3' }
      : null;

  const styles = classNames([
    [style.Button],
    [style.ButtonRadio],
    subscriptionSelector && style.SubscriptionSelector,
    match &&
    match.path === '/members/edit-profile/:section' &&
    questionRef.includes(`common_fit_issues`) &&
    !isMobile,
    {
      [style.AspectRatio]: aspectRatio,
    },
    {
      [brandRef ? style.BrandPreviewImage : style.ButtonPreviewImage]: hasImage,
    },
  ]);

  const previewTitle = classNames({
    [style.ButtonPreviewTitle]: hasImage,
  });

  const setColumnWidth = () => {
    const deviceWidth = window.outerWidth;
    const baseColumns = 4;

    const multipleChoiceColumns = [
      'jeans_size', 'bra_size', 'dress_size', 'skirt_bottoms_size',
      '_style_frequency', '_styled_looks_frequency', 'prefer_tops_to_fit',
      'prefer_to_conceal', 'prefer_to_highlight', 'proportions_torso', 'proportions_shoulders',
      'proportions_arms', 'proportions_hips', 'proportions_legs', 'proportions_butt',
      'typical_spend_amount_denim', 'typical_spend_amount_tops', 'typical_spend_amount_sweaters',
      'typical_spend_amount_dresses', 'typical_spend_amount_bottoms', 'typical_spend_amount_jackets',
      'jeans_best_fit_brand', 'birthday_month'
    ];

    const multipleChoiceColumns2 = ['bottom_size', 'bottoms_waist'];

    const multipleChoiceColumnsSmall = [
      'top_size', 'bra_band', 'bra_cup', 'dresses_size'
    ];

    const multipleChoiceColumnsMedium = [
      'areas_show_off', 'areas_down_play', 'shoe_size'
    ];

    const multipleChoiceColumnsMiscellaneous = [
      'top_fit_issues', 'bottom_fit', 'dresses_fit'
    ];

    const pictureChoiceColumns = ['theme_boxes', 'proportions_waist'];
    const pictureChoiceSmall = ['favorites_brands'];
    const pictureChoiceMedium = ['designs_disliked_patterns'];
    const pictureChoiceLarge = ['colors_to_avoid', 'build_occasions', 'types_of_tops', 'bottom_types', 'denim_rise', 'denim_distress', 'denim_style', 'dresses_length', 'accessories_preferred_metal', 'accessories_style', 'designs_disliked_patterns', 'top_types', 'top_styles', 'accessories_avoid_metal', 'shoe_types'];
    const checkboxChoice = ['favorite_stores_where', 'build_most_interested_items', 'types_of_tops', 'chit_chat_why_interested_in_ts', 'accessories_not_interested', 'designs_no_jewelry'];

    const columnRules = {
      multiple_choice: () => {
        if (multipleChoiceColumns2.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 3 : 2;
        }
        if (multipleChoiceColumns.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 4 : 4;
        }
        if (multipleChoiceColumnsSmall.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 3 : 2;
        }
        if (multipleChoiceColumnsMedium.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 4 : 3;
        }
        if (multipleChoiceColumnsMiscellaneous.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 6 : 4;
        }
        if (checkboxChoice.some(ref => questionRef.includes(ref))) {
          return 12;
        }
      },
      picture_choice: () => {
        if (pictureChoiceColumns.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 4 : 4;
        }
        if (pictureChoiceSmall.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 6 : 3;
        }
        if (questionRef.includes('theme_boxes')) {
          if (deviceWidth >= 1024) return 6;
          if (deviceWidth >= 768) return 4;
          return 12;
        }
        if (pictureChoiceMedium.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 4 : 3;
        }
        if (pictureChoiceLarge.some(ref => questionRef.includes(ref))) {
          return deviceWidth < 512 ? 6 : 4;
        }
        return 6;
      },
    };

    return [columnRules[kind] ? columnRules[kind]() || baseColumns : baseColumns];
  };

  const sortedThemeboxes =
    hasThemeboxes &&
    choices.sort((a, b) => (a.position_number > b.position_number ? 1 : -1));

  const publishedThemeboxes = hasThemeboxes === true
    ? sortedThemeboxes.filter((themebox) => themebox.publish === true)
    : choices;

  const [sliderValues, setSliderValues] = useState([90, 300]);

  useEffect(() => {
    if (questionRef === 'visual_content_how_adventurous' && !initialValue) {
      const defaultChoice = choices.find(choice => choice.label === "A few push pieces");
      if (defaultChoice) {
        getValue(defaultChoice.label, questionRef);
      }
    }
    if (questionRef === 'denim_spend') {
      if (initialValue) {
        const values = initialValue.split(' - ').map(v => parseInt(v.replace('$', ''), 10));
        if (values.length === 2 && !isNaN(values[0]) && !isNaN(values[1])) {
          setSliderValues(values);
        }
      } else {
        // Set default value if denim_spend is not answered
        getValue("$90 - $300", questionRef);
        setSliderValues([90, 300]);
      }
    }
  }, [initialValue, questionRef, choices, getValue]);

  const renderSlider = () => (
    <Cell width={12}>
      <div className={style.SliderContainer}>
        <input
          type="range"
          min="0"
          max={choices.length - 1}
          value={choices.findIndex(choice => choice.label === initialValue) || 0}
          onChange={(e) => {
            const selectedChoice = choices[parseInt(e.target.value, 10)];
            getValue(selectedChoice.label, questionRef);
          }}
          className={style.Slider}
        />
        <div className={style.SliderLabels}>
          {choices.map((choice, index) => (
            <div key={index} className={style.SliderLabel}>
              {choice.label}
            </div>
          ))}
        </div>
      </div>
    </Cell>
  );

  const renderRangeSlider = () => {
    const min = 90;
    const max = 300;
    const step = 5;

    const handleSliderChange = (newValues) => {
      setSliderValues(newValues);
      getValue(`$${newValues[0]} - $${newValues[1]}`, questionRef);
    };

    return (
      <Cell width={12}>
        <div className={style.SliderContainer}>
          <ReactSlider
            className={style.RangeSlider}
            thumbClassName={style.RangeSliderThumb}
            trackClassName={style.RangeSliderTrack}
            renderTrack={(props, state) => (
              <div
                {...props}
                className={classNames(style.RangeSliderTrack, {
                  [style.RangeSliderTrackSelected]: state.index === 1,
                })}
              />
            )}
            min={min}
            max={max}
            step={step}
            value={sliderValues}
            onChange={handleSliderChange}
            pearling
            minDistance={10}
          />
          <div className={style.SliderLabels}>
            <span>{`$${min}`}</span>
            <span>{`$${max}`}</span>
          </div>
          <div className={style.SliderValueDisplay}>
            {`$${sliderValues[0]} - $${sliderValues[1]}`}
          </div>
        </div>
      </Cell>
    );
  };

  return (
    <Fragment>
      {questionRef === 'visual_content_how_adventurous' ? (
        renderSlider()
      ) : questionRef === 'denim_spend' ? (
        renderRangeSlider()
      ) : (
        publishedThemeboxes.map(
          ({
            id,
            label,
            attachment,
            value,
            theme,
            description,
            website_url,
          }) => {
            const getPreviewImage = {
              backgroundImage: `url(${(hasThemeboxes && attachment) || (attachment && attachment.href)})`,
            };

            let displayLabel = label;

            const editedLabel = isExchangeModal ? `${label},${value}` : value || label;

            label = value || label;

            const labelStyles = { ...getPreviewImage, ...aspectRatio };
            const setId = !_.isUndefined(id) ? id : _.uniqueId('input_id_');
            let sizesClass;
            let CheckboxClass;

            if (
              questionId === `top_size` ||
              questionId === `jeans_size` ||
              questionId === `bra_size_number` ||
              questionId === `bra_size_letter`
            ) {
              sizesClass = style.Sizes;
            }

            if (questionId === `favorite_stores_where` || questionId === `build_most_interested_items` || questionId === `a13b33126351` || questionId === `chit_chat_why_interested_in_ts` || questionId === `accessories_not_interested` || questionId === `designs_no_jewelry`) {
              CheckboxClass = style.CustomCheckbox;
            }

            return (
              <Cell key={setId} width={width ? width : setColumnWidth()}>
                {multiSelect ? (
                  !CheckboxClass ? (
                    <input
                      type="checkbox"
                      name={questionId}
                      id={setId}
                      className={style.ButtonInput}
                      onChange={handleChange}
                      value={label}
                      checked={
                        multiSelect
                          ? _.includes(initialValue, label)
                          : initialValue === label
                      }
                    />
                  ) : (
                    ''
                  )
                ) : (
                  <input
                    type="radio"
                    name={questionId}
                    id={setId}
                    className={classNames(
                      hasImage
                        ? (style.CheckMarkButton, style.ButtonInput)
                        : style.ButtonInput
                    )}
                    onChange={
                      isSuggestedItem
                        ? () => suggestedItemClick(website_url, id)
                        : handleChange
                    }
                    value={editedLabel}
                    checked={
                      multiSelect
                        ? _.includes(initialValue, editedLabel)
                        : initialValue === editedLabel
                    }
                    onClick={deselect}
                  />
                )}

                {(hasThemeboxes && attachment) ||
                  (attachment && attachment.href) ? (
                  <div
                    className={classNames(
                      style.ButtonPreviewImage, hasThemeboxes && style.ThemeBoxPreviewImage,
                      questionId !== `favorites_brands` && style.BrandPreviewImage,
                      questionId === `designs_disliked_patterns`
                        ? style.ColorPreviewImage
                        : ''
                    )}
                  >
                    <label
                      htmlFor={setId}
                      className={classNames(styles, 'nb nbr')}
                      style={labelStyles}
                    ></label>
                    {!questionId === `colors_to_avoid` && (
                      <span className={classNames(style.CheckMark)}></span>
                    )}
                    {(!theme && questionId !== `favorites_brands`) && (
                      <span className={previewTitle}>{displayLabel}</span>
                    )}
                  </div>
                ) : (
                  <label
                    htmlFor={setId}
                    className={classNames(
                      CheckboxClass ? CheckboxClass : styles,
                      sizesClass,
                      CheckboxClass,
                      isSuggestedItem && style.SuggestedItem
                    )}
                    style={labelStyles}
                  >
                    {CheckboxClass ? (
                      <Fragment>
                        <input
                          type="checkbox"
                          name={questionId}
                          id={setId}
                          className={classNames(style.ButtonInput)}
                          onChange={handleChange}
                          value={label}
                          checked={
                            multiSelect
                              ? _.includes(initialValue, label)
                              : initialValue === label
                          }
                        />
                        <span className={classNames(style.CheckMark)}></span>
                      </Fragment>
                    ) : (
                      ''
                    )}
                    <span className={previewTitle}>
                      {!theme ? displayLabel : ''}
                    </span>
                  </label>
                )}
                {description && !theme && (
                  <p className="h4 mt tac">{description}</p>
                )}
                {theme && (
                  <div>
                    <p className={themeStyle.ThemeTitle}>{theme}</p>
                    <p className={themeStyle.ThemeDescription}>{description}</p>
                  </div>
                )}
              </Cell>
            );
          }
        )
      )}
    </Fragment>
  );
};

export default Radio;
